import {AxiosResponse} from "axios";

export class ApiCache {
    private static instance: ApiCache;

    constructor() {
        if (!ApiCache.instance) {
            return ApiCache.instance;
        }
        ApiCache.instance = this;
        return this;
    }

    public set = (path: string, response: string): void => {
        if (!this.recordExists(path)) {
            localStorage.setItem(path, response)
        }
    }

    public get = (path: string): AxiosResponse => {
        return JSON.parse(localStorage.getItem(path) || '')
    }

    public recordExists = (path: string): boolean => {
        return !!localStorage.getItem(path);
    }
}