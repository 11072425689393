export const LOGIN_USER = 'LOGIN_USER';
export const GET_USER = 'GET_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_SCOPES = "SET_SCOPES";
export const UPDATE_USER = "UPDATE_USER";

export interface IAuthState {
    user: IUser;
    loading: boolean;
    scopes: string[];
}

export interface IUser {
    email: string;
    first_name: string;
    groups: Array<IGroup>;
    id: number;
    last_name: string;
    phone_number: string;
    verified: boolean;
}

export interface IGroup {
    id: number;
    name: string;
}