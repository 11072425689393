import React, {useState, useEffect, useRef} from 'react';
import {Dialog, DialogContent, IconButton, makeStyles} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import RefreshIcon from '@material-ui/icons/Refresh';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from 'react-responsive-carousel';

const useStyles = makeStyles(() => ({
    closeIconWrapper: {
        height: "68px",
        position: 'relative'
    },
    closeIcon: {
        position: 'absolute',
        cursor: 'pointer',
        top: 0,
        right: 0,
        zIndex: 2,
    },
    dialogContent: {
        padding: 0,
        overflow: 'hidden',
        cursor: 'grab',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    image: {
        transformOrigin: 'center',
        transition: 'transform 0.3s ease',
        width: '100%',
        height: '100%',
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    },
    controls: {
        textAlign: 'center',
    },
    iconButtonFocus: {
        '&:focus': {
            outline: 'none',
            boxShadow: 'none',
        },
    },
}));

interface Props {
    images: any[];
    open: boolean;
    activeIndex: number;
    setActiveIndex: (index: number) => void;
    handleClose: () => void;
}

const ImageViewer = ({open, images, activeIndex, setActiveIndex, handleClose}: Props) => {
    const classes = useStyles();
    const [zoom, setZoom] = useState(1);
    const [position, setPosition] = useState({x: 0, y: 0});
    const [rotation, setRotation] = useState(0);

    const containerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        if (open) resetImagePosition();
    }, [open]);

    const resetImagePosition = () => {
        setPosition({x: 0, y: 0});
        setRotation(0);
        setZoom(1);
    };

    const handleResetPosition = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        resetImagePosition();
    };

    const handleZoomIn = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setZoom((prevZoom) => Math.min(prevZoom * 2, 4));
    };

    const handleRotate = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setRotation((prevRotation) => prevRotation + 90);
    };

    const handleMouseDown = (event: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
        const startX = event.clientX - position.x;
        const startY = event.clientY - position.y;

        const handleMouseMove = (moveEvent: MouseEvent) => {
            const newX = moveEvent.clientX - startX;
            const newY = moveEvent.clientY - startY;
            updatePosition(newX, newY);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        event.preventDefault();
    };

    const updatePosition = (newX: number, newY: number) => {
        if (!imageRef.current || !containerRef.current) return;

        const containerWidth = containerRef.current.clientWidth;
        const containerHeight = containerRef.current.clientHeight;
        const imageWidth = imageRef.current.clientWidth * zoom;
        const imageHeight = imageRef.current.clientHeight * zoom;

        const maxX = Math.max(0, (imageWidth - containerWidth) / 2);
        const maxY = Math.max(0, (imageHeight - containerHeight) / 2);
        const minX = -maxX;
        const minY = -maxY;

        const boundedX = Math.max(minX, Math.min(newX, maxX));
        const boundedY = Math.max(minY, Math.min(newY, maxY));

        setPosition({x: boundedX, y: boundedY});
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <div className={classes.closeIconWrapper}>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    className={classes.closeIcon}
                >
                    <CloseIcon/>
                </IconButton>
            </div>
            <DialogContent ref={containerRef} className={classes.dialogContent}>
                <Carousel
                    useKeyboardArrows
                    autoPlay={false}
                    showThumbs={false}
                    selectedItem={activeIndex}
                    onChange={(index) => {
                        resetImagePosition();
                        setActiveIndex(index)
                    }}
                    emulateTouch
                    infiniteLoop={false}
                    swipeable={false}
                >
                    {images.map((item, index) => {
                        const isActive = index === activeIndex;
                        return (
                            <div
                                key={index}
                                style={{
                                    overflow: isActive ? 'hidden' : 'visible',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                <img
                                    ref={isActive ? imageRef : null}
                                    src={item.src}
                                    alt=""
                                    onMouseDown={isActive ? handleMouseDown : undefined}
                                    className={classes.image}
                                    style={{
                                        transform: isActive
                                            ? `translate(${position.x}px, ${position.y}px) scale(${zoom}) rotate(${rotation}deg)`
                                            : 'none',
                                        transformOrigin: 'center',
                                        pointerEvents: isActive ? 'auto' : 'none',
                                    }}
                                />
                            </div>
                        );
                    })}
                </Carousel>
            </DialogContent>
            <div className={classes.controls}>
                <IconButton onClick={handleZoomIn} disabled={zoom >= 4} className={classes.iconButtonFocus}>
                    <ZoomInIcon/>
                </IconButton>
                <IconButton onClick={handleResetPosition} className={classes.iconButtonFocus}>
                    <ZoomOutIcon/>
                </IconButton>
                <IconButton onClick={handleRotate} aria-label="rotate image" className={classes.iconButtonFocus}>
                    <RefreshIcon/>
                </IconButton>
            </div>
        </Dialog>
    );
};

export default ImageViewer;
