import {Currency} from "../finance/financeTypes";

export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_CHANNELS = 'SET_CHANNELS';
export const UPDATE_CHANNEL = 'UPDATE_CHANNEL';

export interface IChannelState {
    selected?: ChannelData;
    available: Array<ChannelData>;
}

export interface ChannelData {
    company: ICompany;
    country: string;
    currency: Currency;
    id: number;
    merchant: string;
    properties: IChannelProperties;
    name: string;
    type: string;
}

export interface ICompany {
    id: number;
    name: string;
    email: string;
}

export enum PickupType {
    Address = 'Address',
    Hub = 'Hub'
}

export interface GeneralFormInterface {
    page: { fixedHeaderDirection: boolean, fixedFooterDirection: boolean, navigation: boolean },
    location: { default: PickupType, display: boolean },
    searchBar: boolean,
    user: { default: UserType, display: boolean },
    phoneNumberRequired: boolean,
    payment: { blacklist: string[], whitelist: string[] },
    language?: Partial<ILanguage>,
    whitelabel?: Partial<WhiteLabel>

    [key: string]: any
}

export interface IChannelTranslationsState {
    "address:city": string;
    "address:country": string;
    "address:postalcode": string;
    "address:street:name": string;
    "address:street:number": string;
    "address:street:addition": string;
    "button:cancel": string;
    "button:confirm": string;
    "button:edit": string;
    "button:next": string;
    "flight:heading": string;
    "flight:select:heading": string;
    "flight:select:subHeading:airport": string;
    "flight:select:subHeading:city": string;
    "flight:completed_heading": string;
    "flight:number:heading": string;
    "flight:number:label_departure-date": string;
    "flight:number:label_flight-number": string;
    "flight:number:label_pnr": string;
    "flight:number:label_pnr:info": string;
    "flight:search:heading": string;
    "flight:search:label_airline": string;
    "flight:search:label_from": string;
    "flight:search:label_to": string;
    "flight:switch_number": string;
    "flight:switch_search": string;
    "header:progress_completed": string;
    "header:subtitle": string;
    "header:slogan": string;
    "header:title": string;
    "header:menu:home": string,
    "header:menu:airport_delivery": string,
    "header:menu:city_delivery": string,
    "header:menu:become_partner": string,
    "location:address_placeholder": string;
    "location:bagpoint_placeholder": string;
    "location:completed_heading": string;
    "location:confirmation_address": string;
    "location:confirmation_date": string;
    "location:confirmation_time": string;
    "location:first_policy_checkbox": string;
    "location:heading": string;
    "location:heading:city": string;
    "location:heading:title": string;
    "location:heading:info:title": string;
    "location:label_date": string;
    "location:label_luggage": string;
    "location:label_luggage:city": string;
    "location:label_time": string;
    "location:second_policy_checkbox": string;
    "location:switch_address": string;
    "location:switch_bagpoint": string;
    "location:zone_error": string;
    "login:checkbox": string;
    "login:invalid_grant": string;
    "login:email": string;
    "login:password": string;
    "login:forgot_password": string;
    "password_reset:back_to_login": string;
    "password_reset:request": string;
    "payment:heading": string;
    "payment:label_create": string;
    "payment:label_email": string;
    "payment:label_first_name": string;
    "payment:label_last_name": string;
    "payment:label_password": string;
    "payment:label_passport": string;
    "payment:label_passport:info": string;
    "payment:label_phone": string;
    "payment:label_repeat_password": string;
    "error:general_error": string;
    "payment:result:refused": string;
    "payment:result:success": string;
    "payment:result:pending": string;
    "payment:result_message:button_failure": string;
    "payment:result_message:button_redirect": string;
    "payment:result_message:button_success": string;
    "payment:security": string;
    "payment:switch_login": string;
    "payment:switch_registration": string;
    "payment:switch_new": string;
    "portal:account:email": string;
    "portal:account:name": string;
    "portal:account:firstName": string;
    "portal:account:lastName": string;
    "portal:account:phone": string;
    "portal:account:title": string;
    "portal:booking:code": string;
    "portal:booking:pickup": string;
    "portal:booking:dropoff": string;
    "portal:booking:loadMore": string;
    "portal:booking:luggage": string;
    "portal:booking:title": string;
    "portal:booking:partner:tenant": string;
    "portal:booking:partner:title": string;
    "portal:booking:partner:email": string;
    "portal:booking:payment:net": string,
    "portal:booking:payment:tax": string,
    "portal:booking:payment:total": string,
    "portal:booking:payment:title": string;
    "portal:location:street": string;
    "portal:location:number": string;
    "portal:location:zip": string;
    "portal:location:city": string;
    "portal:location:title": string;
    "portal:location:loadMore": string;
    "price_overview:luggage": string;
    "price_overview:luggage_multiple": string;
    "price_overview:no-address": string;
    "validation:flight:date_departed": string;
    "validation:flight:date_offset_invalid": string;
    "validation:flight:flight_number_invalid": string;
    "validation:flight:flight_number_required": string;
    "validation:flight:pnr_invalid": string;
    "validation:flight:pnr_required": string;
    "validation:flight:pnr_too_long": string;
    "validation:flight:pnr_no_special_char": string;
    "validation:flight:wrong_airline": string;
    "validation:flight:wrong_departure": string;
    "validation:flight:wrong_arrival": string;
    "validation:input:email_invalid": string;
    "validation:input:email_required": string;
    "validation:input:firstname_invalid": string;
    "validation:input:firstname_required": string;
    "validation:input:lastname_invalid": string;
    "validation:input:lastname_required": string;
    "validation:input:password_no_digit": string;
    "validation:input:password_no_letters": string;
    "validation:input:password_no_match": string;
    "validation:input:password_required": string;
    "validation:input:password_short": string;
    "validation:input:passport_required": string;
    "validation:input:passport_invalid": string;
    "validation:input:phone_invalid": string;
    "validation:input:phone_required": string;
    "validation:input:street_too_long": string;
    "validation:input:number_too_long": string;
    "validation:input:addition_too_long": string;
    "validation:input:postalCode_too_long": string;
    "validation:input:city_too_long": string;
    "validation:input:city_required": string;
    "validation:input:country_too_long": string;
    "validation:input:country_required": string;
    "validation:internal_error": string;
    "validation:location:empty_address": string;
    "validation:location:empty_checkboxes": string;
    "validation:location:empty_timeslot": string;
    "validation:location:timeslot_placeholder": string;
    "validation:location:unavailable_timeslot": string;
    "thank_you:pickup:warning": string,
    "thank_you:pickup:title": string,
    "thank_you:pickup:tenant": string,
    "thank_you:dropoff:title": string,
    "thank_you:dropoff:subtitle": string,
    "thank_you:flight:title": string,
    "thank_you:message:title": string,
    "thank_you:message:subtitle": string,
    "thank_you:message:contact": string,
    "thank_you:message:myBookings": string,
    "thank_you:message:bookAgain": string,
    "thank_you:restrictions:prohibited:title": string,
    "thank_you:restrictions:prohibited:dangerousGoods": string,
    "thank_you:restrictions:prohibited:batteries": string,
    "thank_you:restrictions:prohibited:magnets": string,
    "thank_you:restrictions:prohibited:flammableItems": string,
    "thank_you:restrictions:cabin:title": string,
    "thank_you:restrictions:cabin:electronicDevices": string,
    "thank_you:restrictions:cabin:liquidsLowVolume": string,
    "thank_you:restrictions:checkedIn:title": string,
    "thank_you:restrictions:checkedIn:sharpObjects": string,
    "thank_you:restrictions:checkedIn:firearm": string,
    "thank_you:restrictions:checkedIn:liquidsHighVolume": string,
    "thank_you:restrictions:onPerson:title": string,
    "thank_you:restrictions:onPerson:lighter": string,
    "thank_you:restrictions:onPerson:esmoke": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_001": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_002": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_003": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_004": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_005": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_006": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_008": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_011": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_012": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_013": string,
    "error:flights:E_FLIGHT_IMPORT_ERROR_014": string,
    "error:flights:no_available_timeslot": string;
    "footer:trademark": string;
    "footer:copyright": string;
    "footer:about": string;
    "footer:contact": string;
    "footer:company": string;
    "footer:slogan": string;
    "footer:terms:title": string;
    "footer:terms:link": string;
    "footer:privacy:title": string;
    "footer:privacy:link": string;
    "footer:faq": string;
    "footer:powered_by": string;
    "account_selector:account": string;
    "account_selector:logout": string;
    "service:airport": string;
    "service:city": string;
    "price_overview:total": string;
    "geo_form:description": string;
    "membership:heading": string;
    "membership:field": string;
    "membership:error": string;
    "membership:completed_heading": string;
    "map:info_window:explanation": string;
    "faq:city_delivery:title": string;
    "faq:city_delivery:length": string;
    "faq:city_delivery:question:1": string;
    "faq:city_delivery:answer:1": string;
    "faq:city_delivery:question:2": string;
    "faq:city_delivery:answer:2": string;
    "faq:city_delivery:question:3": string;
    "faq:city_delivery:answer:3": string;
    "faq:city_delivery:question:4": string;
    "faq:city_delivery:answer:4": string;
    "faq:city_delivery:question:5": string;
    "faq:city_delivery:answer:5": string;
    "faq:airport_delivery:title": string;
    "faq:airport_delivery:length": string;
    "faq:airport_delivery:question:1": string;
    "faq:airport_delivery:answer:1": string;
    "faq:airport_delivery:question:2": string;
    "faq:airport_delivery:answer:2": string;
    "faq:airport_delivery:question:3": string;
    "faq:airport_delivery:answer:3": string;
    "faq:airport_delivery:question:4": string;
    "faq:airport_delivery:answer:4": string;
    "faq:airport_delivery:question:5": string;
    "faq:airport_delivery:answer:5": string;
    "faq:on_the_day:title": string;
    "faq:on_the_day:length": string;
    "faq:on_the_day:question:1": string;
    "faq:on_the_day:answer:1": string;
    "faq:on_the_day:question:2": string;
    "faq:on_the_day:answer:2": string;
    "faq:on_the_day:question:3": string;
    "faq:on_the_day:answer:3": string;
    "faq:on_the_day:question:4": string;
    "faq:on_the_day:answer:4": string;
    "faq:on_the_day:question:5": string;
    "faq:on_the_day:answer:5": string;
    "faq:our_services:title": string;
    "faq:our_services:length": string;
    "faq:our_services:question:1": string;
    "faq:our_services:answer:1": string;
    "faq:our_services:question:2": string;
    "faq:our_services:answer:2": string;
    "faq:our_services:question:3": string;
    "faq:our_services:answer:3": string;
    "faq:our_services:question:4": string;
    "faq:our_services:answer:4": string;
    "faq:our_services:question:5": string;
    "faq:our_services:answer:5": string;
    "faq:our_services:question:6": string;
    "faq:our_services:answer:6": string;
    "faq:our_services:question:7": string;
    "faq:our_services:answer:7": string;
    "faq:our_services:question:8": string;
    "faq:our_services:answer:8": string;
    "faq:our_services:question:9": string;
    "faq:our_services:answer:9": string;
    "faq:luggage_allowance:title": string;
    "faq:luggage_allowance:length": string;
    "faq:luggage_allowance:question:1": string;
    "faq:luggage_allowance:answer:1": string;
    "faq:luggage_allowance:question:2": string;
    "faq:luggage_allowance:answer:2": string;
    "faq:luggage_allowance:question:3": string;
    "faq:luggage_allowance:answer:3": string;
    "faq:luggage_allowance:question:4": string;
    "faq:luggage_allowance:answer:4": string;
    "faq:luggage_allowance:question:5": string;
    "faq:luggage_allowance:answer:5": string;
    "faq:security:title": string;
    "faq:security:length": string;
    "faq:security:question:1": string;
    "faq:security:answer:1": string;
    "faq:security:question:2": string;
    "faq:security:answer:2": string;
    "faq:security:question:3": string;
    "faq:security:answer:3": string;
    "faq:security:question:4": string;
    "faq:security:answer:4": string;
    "faq:troubleshooting:title": string;
    "faq:troubleshooting:length": string;
    "faq:troubleshooting:question:1": string;
    "faq:troubleshooting:answer:1": string;
    "faq:troubleshooting:question:2": string;
    "faq:troubleshooting:answer:2": string;
    "faq:troubleshooting:question:3": string;
    "faq:troubleshooting:answer:3": string;
    "cookies:title": string;
    "cookies:modal:title": string;
    "cookies:necessary:title": string;
    "cookies:necessary:content": string;
    "cookies:statistic:title": string;
    "cookies:statistic:content": string;
    "cookies:button:back": string;
    "cookies:button:rejectAll": string;
    "cookies:button:openSettings": string;
    "cookies:button:acceptAll": string;
    "cookies:button:saveSetting": string;
    "flow:input:label": string;
    "flow:heading": string;
    "flow:completed_heading": string;
    "flow:subtitle:city": string;
    "flow:subtitle:airport": string;

    [key: string]: string
}

export interface IChannelColorsState {
    "action": string;
    "background": string;
    "dark": string;
    "footer:background": string;
    "footer:logo": string;
    "footer:text": string;
    "header:background": string;
    "header:button": string;
    "header:logo:dark": string;
    "header:logo:light": string;
    "header:navigation:scrolling": string;
    "header:navigation:top": string;
    "header:overlay": string;
    "header:text:dark": string;
    "header:text:light": string;
    "input:background": string;
    "input:text:dark": string;
    "input:text:light": string;
    "primary": string;
    "secondary": string;
    "text:dark": string;
    "text:input:dark": string;
    "text:input:light": string;
    "text:light": string;

    [key: string]: string
}

export interface IChannelVariablesState {
    [key: string]: string;
}

export interface IChannelProperties {
    language: ILanguage;
    payments: IPayments;
    whitelabel: WhiteLabel;
    switches: Switches;
    logistics: LogisticsData;
}

export interface TimeFrame {
    from: {
        [key: string]: number;
    },
    to: {
        [key: string]: number;
    }

    [key: string]: {
        [key: string]: number;
    }
}

export interface LogisticsData {
    boundaries: BoundariesData,
    offsets: OffsetsData,
    windows: WindowsData,

    [key: string]: BoundariesData | OffsetsData | WindowsData
}

export interface OffsetsData {
    city: OffsetOptions,
    airport: OffsetOptions,

    [key: string]: OffsetOptions
}

export interface OffsetOptions {
    delivery: { minutes: number },
    pickup: { minutes: number },
}

export interface WindowsData {
    city: TimeFrame[],
    airport: TimeFrame[],

    [key: string]: TimeFrame[]
}

export interface BoundariesData {
    city: BoundaryOptions,
    airport: BoundaryOptions,

    [key: string]: BoundaryOptions
}

export interface BoundaryOptions {
    flight: TimeFrame,
    today: TimeFrame
}

export interface Translation {
    [key: string]: IChannelTranslationsState
}

export interface ILanguage {
    meta: Partial<ILanguageMeta>,
    translations: Translation
}

export interface ILanguageMeta {
    default: string,
    available: string[],
    repr: { [key: string]: string }
    adyen: { [key: string]: string }
    ietf: { [key: string]: string }
}

export interface Switches {
    flight: {
        default: string;
        display: boolean;
    };
    location: {
        default: PickupType;
        display: boolean;
    };
    user: {
        default: UserType;
        display: boolean;
    };
    stepCount: number;
    addressStyle: AddressStyle;
    phoneNumberRequired: boolean;
    validation: string;
    fixedHeaderDirection: boolean;
    fixedFooterDirection: boolean;
    infoWindowTimer: number;
    searchBar: boolean;
    displayProvider: boolean;
    navigation: boolean;
}

export enum UserType {
    Login = 'Login',
    Anonymous = 'Anonymous'
}

export interface IPayments {
    config: { [key: string]: string };
    methods: Methods
}

export interface Methods {
    whitelist: Array<string>;
    blacklist: Array<string>;
}

export interface WhiteLabel {
    variables: any;
    company: CompanyDetails,
    images: WhitelabelImages;
    colors: IChannelColorsState;
    navigationAndLogin: boolean;
}

export interface CompanyDetails {
    url: string;
    name: string;
    legalName: string;
    slogan: string;
    footerSlogan: string;
    email: string;
    phone: string;
    address: string;
    images: {
        logo: string;
    }
}

export interface WhitelabelImages {
    [key: string]: string;

    mobileLogo: string;
    desktopLogo: string;
    tabletLogo: string;
}

export enum AddressStyle {
    DEFAULT = "Default",
    MIDDLE_EAST = "Middle-East"
}

export enum Service {
    CITY = 'city',
    AIRPORT = 'airport'
}

export interface Permission {
    read: boolean,
    write: boolean
}

export interface Image {
    display: string,
    file?: File
}

export const defaultLogistics = {
    "boundaries": {
        "city": {
            "flight": {
                "from": {
                    "hours": 2
                },
                "to": {
                    "days": 2
                }
            },
            "today": {
                "from": {
                    "hour": 4
                },
                "to": {
                    "month": 1
                }
            }
        },
        "airport": {
            "flight": {
                "from": {
                    "days": -2
                },
                "to": {
                    "hours": -4
                }
            },
            "today": {
                "from": {
                    "hour": 4
                },
                "to": {
                    "days": 30
                }
            }
        }
    },
    "offsets": {
        "city": {
            "pickup": {
                "minutes": 30
            },
            "delivery": {
                "minutes": 0
            }
        },
        "airport": {
            "pickup": {
                "minutes": 0
            },
            "delivery": {
                "minutes": -120
            }
        }
    },
    "windows": {
        "city": [
            {
                "from": {
                    "hour": 8
                },
                "to": {
                    "hour": 10
                }
            },
            {
                "from": {
                    "hour": 10
                },
                "to": {
                    "hour": 12
                }
            },
            {
                "from": {
                    "hour": 12
                },
                "to": {
                    "hour": 14
                }
            },
            {
                "from": {
                    "hour": 14
                },
                "to": {
                    "hour": 16
                }
            },
            {
                "from": {
                    "hour": 16
                },
                "to": {
                    "hour": 18
                }
            }
        ],
        "airport": [
            {
                "from": {
                    "hour": 8
                },
                "to": {
                    "hour": 10
                }
            },
            {
                "from": {
                    "hour": 10
                },
                "to": {
                    "hour": 12
                }
            },
            {
                "from": {
                    "hour": 12
                },
                "to": {
                    "hour": 14
                }
            },
            {
                "from": {
                    "hour": 14
                },
                "to": {
                    "hour": 16
                }
            },
            {
                "from": {
                    "hour": 16
                },
                "to": {
                    "hour": 18
                }
            },
            {
                "from": {
                    "hour": 18
                },
                "to": {
                    "hour": 20
                }
            }
        ]
    }
}

