import React, {Component, Fragment} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Scrollbars from "react-custom-scrollbars-2";
import {collapsedSidebarAction} from '../../Store/app/AppActions';
import UserBlock from './UserBlock';
import SidebarContent from './SidebarContent';

class Sidebar extends Component {

    UNSAFE_componentWillMount() {
        this.updateDimensions();
    }

    shouldComponentUpdate(nextProps) {
        const {isDarkSidenav, locale} = this.props;
        return isDarkSidenav !== nextProps.isDarkSidenav || locale;
    }

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {windowWidth} = this.state;
        if (nextProps.location !== this.props.location) {
            if (windowWidth <= 1199) {
                this.props.collapsedSidebarAction(false);
            }
        }
    }

    updateDimensions = () => {
        this.setState({windowWidth: window.innerWidth, windowHeight: window.innerHeight});
    }

    render() {
        return (
            <Fragment>
                <div
                    className={"rct-sidebar"}
                    style={{backgroundImage: 'none'}}
                >
                    <div className={"rct-sidebar-content sidebar-overlay-dark"}>
                        <div className="site-logo">
                            <Link to="/" className="logo-mini">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/img/bagpoint.svg`} className="mr-15" alt="site logo" width="100%" height="100%" />
                            </Link>
                        </div>
                        <div className="rct-sidebar-wrap">
                            <Scrollbars
                                className="rct-scroll"
                                autoHide
                                autoHideDuration={100}
                                style={{height: 'calc(100vh - 60px)'}}
                            >
                                <UserBlock/>
                                <SidebarContent/>
                            </Scrollbars>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// map state to props
const mapStateToProps = ({app}) => {
    const {collapsedSidebar, isDarkSidenav, locale} = app;
    return {collapsedSidebar, isDarkSidenav, locale};
};

export default withRouter(connect(mapStateToProps, {
    collapsedSidebarAction,
})(Sidebar));
