import ClientOAuth2, {Token} from "client-oauth2";
import ENV from "../Util/environments";
import {PURGE} from "redux-persist";
import {store} from "../Store/store";
import {SET_SCOPES} from "../Store/auth/authTypes";

interface StoredToken {
    data: ClientOAuth2.Data;
    expires: Date;
}

//eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Client {
}

abstract class BaseClient implements Client {
    abstract client: ClientOAuth2;
    public token?: Token;
    protected clientId: string;
    protected storageKey: string;

    protected constructor(key: string, clientId?: string) {
        if (clientId === undefined) throw new Error(`No ${key} client id defined`);
        this.storageKey = key;
        this.clientId = clientId;
    }

    public get storage(): StoredToken {
        let storage = JSON.parse(localStorage.getItem(this.storageKey) || "{}");
        if (this.clientId in storage) {
            return storage[this.clientId];
        }
        storage = {...storage, [this.clientId]: {data: {}}};
        localStorage.setItem(this.storageKey, JSON.stringify(storage));
        return storage[this.clientId];
    }

    abstract refresh(): Promise<Token>;

    abstract generate(): Promise<Token>;

    public isAuthenticated(): boolean {
        /**
         * Validates the availability of an accessToken in the local storage
         */
        const storage: StoredToken = this.storage;
        return 'access_token' in storage.data;
    }

    public async auth(): Promise<Token> {
        /**
         * Restores a token. The method first looks at the class object to avoid restoring the object from storage
         * every time. If the object is null, it will attempt to restore it from local storage.
         */

        let token: Token;
        if (this.token === undefined && this.isAuthenticated()) {
            // Handle stored authentication details
            const storage: StoredToken = this.storage;
            token = this.client.createToken(storage.data);
            token.expiresIn(new Date(storage.expires));
        } else if (this.token !== undefined) {
            // Retrieve the instanced authentication token
            token = this.token;
        } else {
            // Generate a new authentication token
            token = await this.generate();
        }

        // Validate token and refresh if necessary
        if (token.expired() && this instanceof AuthenticatedClient) {
            token = await this.refresh();
        } else if (token.expired()) {
            token = await this.generate();
            this.purgeStore()
        }
        this.token = token;
        return this.token;
    }

    public async refreshToken(): Promise<Token> {
        let token: Token;
        if (this.token !== undefined) {
            // Retrieve the instanced authentication token
            token = this.token;
        } else {
            // Generate a new authentication token
            token = await this.generate();
        }

        // Refresh token
        if (token && this instanceof AuthenticatedClient) {
            token = await this.refresh();
        } else {
            token = await this.generate();
            this.purgeStore();
        }
        this.token = token;
        return this.token;
    }

    public clear() {
        localStorage.removeItem(this.storageKey);
    }

    public purgeStore = () => {
        store.dispatch({
            type: PURGE,
            key: "root",
            result: () => {
                console.log('Store purged!')
            },
        });
    }
}

class AuthenticatedClient extends BaseClient {
    public client: ClientOAuth2;
    private username?: string;
    private password?: string;

    constructor(username?: string, password?: string) {
        super('authenticated', ENV.CLIENT_CUSTOMER_ID);
        this.username = username;
        this.password = password;

        this.client = new ClientOAuth2({
            clientId: this.clientId,
            clientSecret: ENV.CLIENT_CUSTOMER_SECRET,
            accessTokenUri: `${ENV.API_URL}/o/token/`,
            authorizationUri: `${ENV.API_URL}/o/authorize/`,
        });
    }

    public async login(username: string, password: string): Promise<Token> {
        this.username = username;
        this.password = password;
        return this.generate()
    }

    public async refresh(): Promise<Token> {
        if (this.token === undefined) throw Error('Invalid token');

        return this.token.refresh().then((token) => {
            store.dispatch({
                type: SET_SCOPES,
                payload: token.data?.scope.split(" ") || []
            });
            localStorage.setItem(
                this.storageKey,
                JSON.stringify({
                    [this.clientId]: {
                        data: token.data,
                        expires: token.expiresIn(parseInt(token.data.expires_in))
                    }
                })
            );

            return token;
        });
    }

    public async generate(): Promise<Token> {
        /**
         * Generate a new token for use with the given application and store the resulting information into
         * our local storage.
         */

        if (this.username === undefined || this.password === undefined) {
            this.clear();
            throw Error(
                "Authenticated Client may only generate a token if both a username and password are provided. " +
                "Try refreshing the token instead."
            );
        }

        return this.client.owner
            .getToken(this.username, this.password, {
                body: {
                    grant_type: "password",
                },
            })
            .then((token: Token) => {
                this.token = token;
                store.dispatch({
                    type: SET_SCOPES,
                    payload: token.data?.scope.split(" ") || []
                });
                localStorage.setItem(
                    this.storageKey,
                    JSON.stringify({
                        [this.clientId]: {
                            data: token.data,
                            expires: token.expiresIn(parseInt(token.data.expires_in))
                        }
                    })
                );
                return this.token;
            });
    }
}

class PasswordRecoverClient extends BaseClient {
    public client: ClientOAuth2;

    public constructor() {
        super('passwordRecover', ENV.CLIENT_PASSWORD_RECOVER_ID);
        this.client = new ClientOAuth2({
            clientId: this.clientId,
            clientSecret: ENV.CLIENT_PASSWORD_RECOVER_SECRET,
            accessTokenUri: `${ENV.API_URL}/o/token/`,
        });
    }

    async refresh(): Promise<Token> {
        throw Error('Password recover token cannot be refreshed');
    }

    async generate(): Promise<Token> {
        /**
         * Generate a new token for use with the given application and store the resulting information into
         * our local storage.
         */

        return this.client.credentials.getToken().then((token: Token) => {
            store.dispatch({
                type: SET_SCOPES,
                payload: token.data?.scope.split(" ") || []
            });
            localStorage.setItem(
                this.storageKey,
                JSON.stringify({
                    [this.clientId]: {
                        data: token.data,
                        expires: token.expiresIn(parseInt(token.data.expires_in))
                    }
                })
            );

            return token;
        });
    }
}

class Authentication {
    private static clients: { [name: string]: BaseClient } = {
        authenticated: new AuthenticatedClient(),
        passwordRecover: new PasswordRecoverClient(),
    }

    private static get client(): BaseClient {
        return Authentication.clients.authenticated;
    }

    public static isLoggedIn(): boolean {
        return Authentication.clients.authenticated.isAuthenticated();
    }

    public static isAuthenticated(client: "authenticated"): boolean {
        return Authentication.clients[client].isAuthenticated();
    }

    public static async login(username: string, password: string): Promise<Token> {
        const client = Authentication.clients.authenticated
        if (!(client instanceof AuthenticatedClient)) throw Error("Invalid client type");

        return await client.login(username, password);
    }

    public static logout(): Promise<Token> {
        /**
         * Removes the authenticated clients and creates a new token
         */
        Authentication.clients.authenticated.clear();
        Authentication.clients.authenticated.purgeStore()
        return Authentication.client.auth();
    }

    public static refreshToken(): Promise<Token> {
        return Authentication.client.refreshToken();
    }


    public static async auth(username: string, password: string): Promise<Token>;
    public static async auth(client: "authenticated" | "passwordRecover"): Promise<Token>;
    public static async auth(): Promise<Token>;
    public static async auth(
        ...rest: string[]
    ): Promise<Token> {
        /**
         * Retrieve the current authenticated token.
         *
         * If a username and password is passed, the application will attempt to login using the AuthenticatedClient.
         *
         * If a client is passed, the application will return the requested client
         */
        if (rest.length === 2) {
            const username: string = rest[0];
            const password: string = rest[1];
            return Authentication.login(username, password);
        }

        const client = rest.length ? Authentication.clients[rest[0]] : Authentication.client;

        return client.auth().catch(_ => {
            console.log('There was an error whilst trying to authenticate the existing user.')
            return Authentication.logout();
        });
    }
}


export default Authentication;
