import {makeStyles} from '@material-ui/core/styles';
import {IChannelVariablesState} from 'Store/channel/channelTypes'
import City from './city.svg'
import Checkbox from './Checkbox.svg'
import React from 'react'
import Svg from 'Components/Svg';

interface Props {
    active: boolean;
    variables: IChannelVariablesState;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        position: "relative",
        boxShadow: "0 0 0.5rem 0 rgba(0, 0, 0, 0.05)",
        maxWidth: "60%",
        maxHeight: "200px",
        height: "100%",
        padding: "20px",
        borderRadius: "20px",
        fontSize: "15px",
        fontWeight: 300
    },
    icon: {
        alignSelf: "center",
        marginRight: "15px",
        "& svg": {
            width: "40px",
        }
    },
    content: {},
    title: {
        fontSize: "1.5em"
    },
    checkboxContainer: {
        position: "absolute",
        right: "10px",
        top: "10px",
        borderRadius: "100%",
        border: "1px solid black",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    checkbox: {
        height: "20px",
        "& svg": {
            width: "20px"
        }
    }
}));

const FlowSelector: React.FC<Props> = ({variables, active}) => {
    const styles = useStyles();

    return (
        <div
            style={{
                border: active ? variables['flow:service:selector[active]::border'] : variables['flow:service:selector[inactive]::border'],
                color: active ? variables['flow:service:selector[active]::text:color'] : variables['flow:service:selector[inactive]::text:color']
            }}
            className={styles.wrapper}
        >
            <Svg
                style={{fill: active ? variables['flow:service:selector[active]:icon::background'] : variables['flow:service:selector[inactive]:icon::background']}}
                className={styles.icon} url={City}/>
            <div className={styles.content}>
                <div className={styles.title}>
                    City Delivery
                </div>
                We collect and deliver your bags from the airport to your doorstep.
                <div>
                </div>
            </div>

            <div
                style={{borderColor: active ? variables['flow:service:selector[active]:icon::background'] : variables['flow:service:selector[inactive]:icon::background']}}
                className={styles.checkboxContainer}>
                <Svg
                    style={{fill: active ? variables['flow:service:selector[active]:icon::background'] : variables['flow:service:selector[inactive]:icon::background']}}
                    url={Checkbox} className={styles.checkbox}/>
            </div>
        </div>
    )
}

export default FlowSelector