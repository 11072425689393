import moment from "moment";
import {Currency} from "../finance/financeTypes";

export const CLEAR_BOOKINGS = "CLEAR_BOOKINGS";
export const SET_BOOKINGS = 'SET_BOOKINGS';
export const SET_BOOKING_SEARCH = 'SET_BOOKING_SEARCH';
export const GET_TENANT = 'GET_TENANT';
export const GET_BOOKING_DETAIL = 'GET_BOOKING_DETAIL';
export const SET_FILTERS = 'SET_FILTERS';
export const SET_BOOKING_CHANNEL = 'SET_BOOKING_CHANNEL';
export const SET_BOOKING_STATUS = 'SET_BOOKING_STATUS';

export const filterInitialState = {
	date: 'order',
	from_datetime: moment().startOf('month').utcOffset(0).startOf('day').toISOString(),
	to_datetime: moment().endOf('month').utcOffset(0).endOf('day').toISOString()
}

export interface BookingFilters {
	date: string;
	pickup_from_datetime?: string;
	from_datetime: string;
	pickup_to_datetime?: string;
	to_datetime: string;
	status?: string[];
	service?: ServiceType;
}

export interface BookingState {
    bookings: SimpleBooking[];
    count: number;
    search: string;
    offset: number;
    previous?: null | number;
    tenants: Tenant[];
    detail?: DetailedBooking;
		filters: BookingFilters;
    bookingChannel: number|null;
}

export interface Booking {
    code: string;
    commission: Commission;
    contact: Contact;
    created: string;
    status: string;
    passengers: PassengersData[];
    meta: BookingMeta;
}

export interface Commission {
	total: {
		[key: string]: {
			role: string
			claimant: {
				id: number
				name: string
				email: string
			}
			currency: Currency
			claim_type: string
			commission: number
			claim_share: number
		}
	}
}

export interface BookingMeta {
    flightNumber: string;
    timeSlot: TimeSlot;
    window: Window;
    membership?: string;
}

export interface TimeSlot {
    time: string;
    formatted: string;
    timeLabel: string;
}

export interface Window {
    end: string;
    label: string;
    start: string;
}

export interface PassengersData {
    first_name: string;
    last_name: string;
    items: string;
}

export interface SimpleBooking extends Booking {
    assignments: Assignment[];
}

export interface DetailedBooking extends Booking {
    assignments: DetailedAssignment[];
}

export interface Contact {
    email: string;
    first_name: string;
    language: string;
    last_name: string;
    phone_number: string;
    user: null | number;
}

export interface Tenant {
    id: number;
    name: string;
}

export interface Assignment {
    from_datetime: string;
    from_location: number;
    id: number;
    luggage_count: number;
    service: ServiceType,
    tenant: number;
    tenant_assignment: number;
    to_datetime: string;
    to_location: number;
    zone: number;
}

export interface DetailedAssignment {
    from_datetime: string;
    from_datetime_local: string;
    from_location: Location;
    from_timezone: string;
    id: number;
    luggage_count: number;
    service: ServiceType,
    tenant: number;
    tenant_assignment: number;
    to_datetime: string;
    to_datetime_local: string;
    to_location: Location;
    to_timezone: string;
    zone: number;
    status: string;
    bridge_booking: string
    bridge_assignment: number
    luggage: Luggage[]
}

export interface Luggage {
    identifier: {
        uuid: string
    }
    flight_reference: string
    weight: number
    images: Image[]
}

export interface Image {
    type: string;
    image: string;
}

export interface Location {
    id: number,
    name: string,
    type: LocationType,
    coordinates: Coordinates,
    address: Address,
    phone_number: string
}

export interface Address {
    street: string;
    number: string;
    addition: string;
    postal_code: string;
    city: string;
    region: string;
    country: string;
    coordinates: Coordinates;
    description?: string;
}


export interface Coordinates {
    longitude: number;
    latitude: number;
}

export enum LocationType {
    bagpoint = 'bagpoint',
    hotel = 'hotel',
    airport = 'airport',
    private = 'private',
    business = 'business'
}

export enum ServiceType {
    "injection" = "injection",
    "delivery" = "delivery",
    "storage" = "storage",
}

export interface CancelBookingParams {
	bookingCode: string;
	refund: boolean;
	notify: boolean;
	reason: string;
	message: string;
}