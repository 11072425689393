import React from 'react';

interface IRctCardHeadingProps {
    title: string|JSX.Element;
    customClasses: string;
}
const RctCardHeading = ({ title, customClasses }: IRctCardHeadingProps) => (
    <div className={`rct-block-title ${customClasses ? customClasses : ''}`}>
        <h4>{title}</h4>
    </div>
);

export { RctCardHeading };