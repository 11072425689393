import React from 'react';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import {Link} from 'react-router-dom';
import IntlMessages from 'Util/IntlMessages';

const getDisplayString = (sub: string) => {
    const arr = sub.split("-");
    if (arr.length > 1) {
        return <IntlMessages
            id={`sidebar.${arr[0].charAt(0) + arr[0].slice(1) + arr[1].charAt(0).toUpperCase() + arr[1].slice(1)}`}/>
    } else {
        return <IntlMessages id={`sidebar.${sub.charAt(0) + sub.slice(1)}`}/>
    }

};

const getUrlString = (path: string, sub: string, index: number) => {
    if (index === 0) {
        return '/';
    } else {
        return '/' + path.split(sub)[0] + sub;
    }
};

interface IPageTitleBarProps {
    title: string|JSX.Element;
    icon?: string;
    match: {
        isExact: boolean;
        params: {[key:string]: string};
        path: string;
        url: string;
    },
    id?: string;
    enableBreadCrumb?: boolean;
}

const PageTitleBar = ({title, icon, match, id, enableBreadCrumb = true}: IPageTitleBarProps) => {

    const path = match?.path.substr(1) || '';
    const subPath = id ? path?.split('/').concat(id) : path?.split('/');

    return (
        <div className="page-title d-flex justify-content-between align-items-center">
            {title &&
            <div className="page-title-wrap">
                <i className={icon}/>
                <h2 className="">{!id ? title : <span>Booking #{id}</span>}</h2>
            </div>
            }
            {enableBreadCrumb &&
            <Breadcrumb className="mb-0 tour-step-7" tag="nav">
                {subPath?.map((sub: string, index: number) => {
                    return <BreadcrumbItem
                        active={subPath?.length === index+1}
                        tag={subPath.length === index + 1 ? "span" : Link} key={index}
                        to={getUrlString(path, sub, index)}
                    >
                        {sub === id ? id : getDisplayString(sub)}
                    </BreadcrumbItem>
                    }
                )}
            </Breadcrumb>
            }
        </div>
    )
};

export default PageTitleBar;
