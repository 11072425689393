import React, {useLayoutEffect, useState} from 'react';
import ChannelPropertyToggle, {ChannelToggleState} from "../Components/ChannelPropertyToggle";
import {Typography} from "@material-ui/core";
import {
    GeneralFormInterface,
    ChannelData,
    IPayments,
    Permission,
    PickupType,
    Switches,
    UserType
} from "../../../Store/channel/channelTypes";
import {convertEnumToOptions, getSelectedItem} from "../../../Util/utils";
import Select from "../../Select/Select";
import Button from "@material-ui/core/Button";
import {useDispatch, useSelector} from "react-redux";
import {updateChannelDetails} from "../../../Store/channel/ChannelActions";
import IntlMessages from "../../../Util/IntlMessages";
import {makeStyles} from "@material-ui/core/styles";
import RootState from "../../../Store/RootState";

interface IGeneralProps {
    showPayments: Permission,
    showSwitches: Permission
}

const useStyles = makeStyles((theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("xs")]: {
            flexWrap: 'wrap'
        },
    },
    column: {
        width: "50%",
        display: 'flex',
        flexFlow: "column",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            alignSelf: 'center'
        }
    },
    paymentList: {
        padding: '10px 0'
    }
}));

const General = ({showPayments, showSwitches}: IGeneralProps) => {

    const dispatch = useDispatch();
    const classes = useStyles()
    const channel = useSelector<RootState, Required<ChannelData>>(state => state.channel.selected as Required<ChannelData>);
    const {switches, payments}: {switches: Required<Switches>, payments: Required<IPayments>} = channel.properties;

    const [data, setData] = useState<GeneralFormInterface>({
        page: {
            fixedHeaderDirection: switches.fixedHeaderDirection || false,
            fixedFooterDirection: switches.fixedFooterDirection || false,
            navigation: switches.navigation || true
        },
        location: {default: switches.location.default, display: switches.location.display},
        searchBar: switches.searchBar || true,
        user: {default: switches.user.default, display: switches.user.display},
        phoneNumberRequired: switches.phoneNumberRequired,
        payment: {
            blacklist: payments.methods.blacklist,
            whitelist: payments.methods.whitelist
        }
    });
    const [locationOptions, setLocationOptions] = useState(convertEnumToOptions(PickupType));
    const [userOptions, setUserOptions] = useState(convertEnumToOptions(UserType));

    const updateInput = (value: ChannelToggleState, key: string) => {
        switch (key) {
            case 'user':
            case 'location':
                setData((prev) => ({...prev, [key]: {...prev[key], display: !prev[key].display}}))
                break;
            case 'searchBar':
            case 'phoneNumberRequired':
                setData((prev) => ({...prev, [key]: !prev[key]}))
                break;
            default:
                setData((prev) => ({...prev, [key]: {...prev[key], ...value}}))
        }
    };

    function onSelect<T>(event: React.ChangeEvent<HTMLSelectElement>, type: T, key: string) {
        const selected = Object.keys(type)[parseInt(event.target.value)];
        setData((prev) => ({...prev, [key]: {...prev[key], default: selected}}))
    }

    const updateProperties = () => {
        dispatch(updateChannelDetails(channel, {
            ...data.payment && {
                payments: {
                    methods: {
                        whitelist: data.payment.whitelist,
                        blacklist: data.payment.blacklist
                    }
                }
            },
            switches: {
                ...data.page,
                ...data.location && {location: data.location},
                ...data.user && {user: data.user},
                ...data.phoneNumberRequired && {phoneNumberRequired: data.phoneNumberRequired},
                ...data.searchBar && {searchBar: data.searchBar}
            }
        }))
    }

    const updatePaymentList = (item: ChannelToggleState, name: string) => {
        if (item[name]) {
            setData((prev) => ({
                ...prev,
                payment: {
                    whitelist: data.payment.whitelist.concat(name),
                    blacklist: data.payment.blacklist.filter((item: string) => item !== name)
                }
            }))
        } else {
            setData((prev) => ({
                ...prev,
                payment: {
                    whitelist: data.payment.whitelist.filter((item: string) => item !== name),
                    blacklist: data.payment.blacklist.concat(name)}
                }
            ))
        }
    }

    useLayoutEffect(() => {
        if (data.location.display === false) {
            setLocationOptions(() => locationOptions.filter((item) => item.name === 'Hub'));
            setData((prev) => ({...prev, location: {...prev.location, default: PickupType.Hub}}))
        } else setLocationOptions(convertEnumToOptions(PickupType))

        if (data.user.display === false) {
            setUserOptions(() => userOptions.filter((item) => item.name === 'Anonymous'));
            setData((prev) => ({...prev, user: {...prev.user, default: UserType.Anonymous}}))
        } else setUserOptions(convertEnumToOptions(UserType))
    }, [data.location.display, data.user.display])

    useLayoutEffect(() => {
        setData((prev) => ({
            ...prev,
            page: {
                fixedHeaderDirection: switches.fixedHeaderDirection || false,
                fixedFooterDirection: switches.fixedFooterDirection || false,
                navigation: switches.navigation || true
            },
            location: {default: switches.location.default, display: switches.location.display},
            searchBar: switches.searchBar || true,
            user: {default: switches.user.default, display: switches.user.display},
            phoneNumberRequired: switches.phoneNumberRequired,
            payment: {
                blacklist: payments.methods.blacklist,
                whitelist: payments.methods.whitelist
            }
        }));
    }, [switches, payments])

    return (
        <div className={classes.page}>
            <div className={classes.row}>
                <div className={classes.column}>
                    {showSwitches.read && <React.Fragment>
                        <div className="p-3">
                            <Typography variant={'h5'}><IntlMessages id={"channel:general:page_section"}/></Typography>
                            <ChannelPropertyToggle
                                key={`fixedHeaderDirection-${data.page.fixedHeaderDirection}`}
                                name={'fixedHeaderDirection'}
                                title={<IntlMessages id={"channel:general:fixed_header"}/>}
                                checked={data.page.fixedHeaderDirection}
                                update={(value: ChannelToggleState) => updateInput(value, 'page')}
                                disabled={!showSwitches.write}/>
                            <ChannelPropertyToggle
                                key={`fixedFooterDirection-${data.page.fixedFooterDirection}`}
                                name={'fixedFooterDirection'}
                                title={<IntlMessages id={"channel:general:fixed_footer"}/>}
                                checked={data.page.fixedFooterDirection}
                                update={(value: ChannelToggleState) => updateInput(value, 'page')}
                                disabled={!showSwitches.write}/>
                            <ChannelPropertyToggle
                                key={`navigation-${data.page.navigation}`}
                                name={'navigation'}
                                title={<IntlMessages id={"channel:general:navigation"}/>}
                                checked={data.page.navigation}
                                update={(value: ChannelToggleState) => updateInput(value, 'page')}
                                disabled={!showSwitches.write}/>
                        </div>
                        <div className="p-3">
                            <Typography variant={'h5'}><IntlMessages id={"channel:general:user_section"}/></Typography>
                            <Typography component="div" className="row d-flex align-items-center">
                                <div className="col-8"><IntlMessages id={"channel:general:default_user"}/></div>
                                <div className="col-4"><Select
                                    selected={getSelectedItem(data.user.default, UserType)}
                                    items={userOptions}
                                    handleChange={(event) => onSelect(event, UserType, 'user')}
                                    disabled={!showSwitches.write}/>
                                </div>
                            </Typography>
                            <ChannelPropertyToggle
                                key={`user-${data.user.display}`}
                                name={'Enable login'} title={<IntlMessages id={"channel:general:login"}/>}
                                checked={data.user.display}
                                update={(value: ChannelToggleState) => updateInput(value, 'user')}
                                disabled={!showSwitches.write}/>
                            <ChannelPropertyToggle
                                key={`phoneNumberRequired-${data.phoneNumberRequired}`}
                                name={'phoneNumberRequired'}
                                title={<IntlMessages id={"channel:general:phone"}/>}
                                checked={data.phoneNumberRequired}
                                update={(value: ChannelToggleState) => updateInput(value, 'phoneNumberRequired')}
                                disabled={!showSwitches.write}/>
                        </div>
                        <div className="p-3">
                            <Typography variant={'h5'}><IntlMessages id={"channel:general:location_section"}/></Typography>
                            <Typography component="div" className="row d-flex align-items-center">
                                <div className="col-8"><IntlMessages id={"channel:general:default_location"}/></div>
                                <div className="col-4"><Select
                                    selected={getSelectedItem(data.location.default, PickupType)}
                                    items={locationOptions}
                                    handleChange={(event) => onSelect(event, PickupType, 'location')}
                                    disabled={!showSwitches.write}/>
                                </div>
                            </Typography>
                            <ChannelPropertyToggle
                                key={`location-${data.location.display}`}
                                name={'Enable private address'}
                                title={<IntlMessages id={"channel:general:private_address"}/>}
                                checked={data.location.display}
                                update={(value: ChannelToggleState) => updateInput(value, 'location')}
                                disabled={!showSwitches.write}/>
                            <ChannelPropertyToggle
                                key={`searchBar-${data.searchBar}`}
                                name={'searchBar'}
                                title={<IntlMessages id={"channel:general:address_search"}/>}
                                checked={data.searchBar}
                                update={(value: ChannelToggleState) => updateInput(value, 'searchBar')}
                                disabled={!showSwitches.write}/>
                        </div>
                    </React.Fragment>}
                </div>
                <div className={`${classes.column} justify-space-between`}>
                    {showPayments.read && <div className=" p-3">
                        <Typography variant={'h5'}><IntlMessages id={"channel:general:payment_section"}/></Typography>
                        <div className={classes.paymentList}>
                        <Typography variant={'h6'}><IntlMessages id={"channel:general:payment_section:enabled"}/></Typography>
                        {data.payment.whitelist.map((item) => (
                            <ChannelPropertyToggle
                                key={`payment-${item}`}
                                name={item}
                                title={item}
                                checked={true}
                                update={(value: ChannelToggleState) => updatePaymentList(value, item)}/>
                            ))}
                        </div>
                        <div className={classes.paymentList}>
                            <Typography variant={'h6'}><IntlMessages id={"channel:general:payment_section:disabled"}/></Typography>
                            {data.payment.blacklist.map((item) => (
                                <ChannelPropertyToggle
                                    key={`payment-${item}`}
                                    name={item}
                                    title={item}
                                    checked={false}
                                    update={(value: ChannelToggleState) => updatePaymentList(value, item)}/>
                            ))}
                        </div>
                    </div>
                    }
                </div>
            </div>

            {(showSwitches.write || showPayments.write) && <div className={classes.row}>
                <Button variant="contained" color={'primary'} className="m-5" onClick={updateProperties}><IntlMessages
                    id={"channel:general:update"}/></Button>
            </div>}
        </div>
    )
}

export default General