import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Service, TimeFrame} from "../../../Store/channel/channelTypes";
import {DateTime, Interval} from "luxon";
import IntlMessages from "../../../Util/IntlMessages";

interface WindowsProps {
    service: Service,
    windows: TimeFrame[],
    disabled: boolean;
    onChange: (windows: TimeFrame[]) => void;
}

const useStyles = makeStyles(() => ({
    inputField: {
        border: '1px solid #ccc',
        padding: '0 6px',
        margin: '0 3px 0 6px',
        color: '#5D92F4'
    },
    selectField: {
        border: '1px solid #ccc',
        margin: '0 6px 0 3px',
        color: '#5D92F4'
    },
    inputError: {
        display: 'none',
        '&::invalid': {
            background: 'red',
            display: 'block'
        }
    }
}));

const Windows = ({service, windows, disabled, onChange}: WindowsProps): JSX.Element => {
    /**
     * Generating the pickup and delivery windows.
     */

    const classes = useStyles();
    const timeOptions = ['minute', 'hour'];
    const [timeFrame, setTimeFrame] = useState<string>(Object.keys(windows[0].from)[0]);
    const [timeDifference, setTimeDifference] = useState<number>(windows[0].to[timeFrame] - windows[0].from[timeFrame]);
    const [min, setMin] = useState<number>(windows[0].from.hour);
    const [max, setMax] = useState<number>(windows[windows.length - 1].to.hour);
    const timeInputWidth = useMemo(() => (windows[0].to[timeFrame] - windows[0].from[timeFrame]).toString().length + 4, [windows, timeFrame]);
    const minInputWidth = useMemo(() => min.toString().length + 4, [min]);
    const maxInputWidth = useMemo(() => max.toString().length + 4, [max]);

    const minBoundaryGuard = useMemo(() => {
            /**
             * The min value of `to` should not exceed `from` value
             */
            if (timeFrame === 'hour') {
                return min + timeDifference;
            } else if (timeFrame === 'minute') {
                return min + 1;
            } else {
                return 0
            }
        }, [min, timeFrame, timeDifference]
    )

    const maxBoundaryGuard = useMemo(() => {
            /**
             * The max value of `from` should not exceed `to` value
             */
            if (timeFrame === 'hour') {
                return max - timeDifference;
            } else if (timeFrame === 'minute') {
                return max - 1;
            } else {
                return 24;
            }
        }, [min, timeFrame, timeDifference]
    )

    const changePickupHandler = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const value = e.target.value;
        if (!value) return;
        switch (type) {
            case 'time':
                setTimeDifference(parseInt(value, 10));
                break;
            case 'min':
                setMin(parseInt(value, 10))
                break;
            case 'max':
                setMax(parseInt(value, 10))
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        /**
         * Generating 'from' and 'to' windows and passing it to parent component
         */
        const start = DateTime.now().set({hour: min, minute: 0, second: 0, millisecond: 0});
        const end = DateTime.now().set({hour: max, minute: 0, second: 0, millisecond: 0});
        const dates = Interval.fromDateTimes(start, end).splitBy({[timeFrame]: timeDifference});

        const generatedWindows = dates.map((date) => {
            return {
                from: {
                    hour: date.start.get('hour'),
                    minute: date.start.get('minute')
                },
                to: {
                    hour: date.end.get('hour'),
                    minute: date.end.get('minute')
                }
            }
        })

        onChange(generatedWindows)
    }, [min, max, timeFrame, timeDifference])

    return (
        <p>
            <IntlMessages id={`channel:logistics:windows:${service}`}/>
            <input disabled={disabled} min="1" className={classes.inputField} style={{width: timeInputWidth + 'ch'}}
                   defaultValue={timeDifference} type="number" autoFocus
                   onChange={(e) => changePickupHandler(e, 'time')}/> :
            <select disabled={disabled} id="timeframe" className={classes.selectField} defaultValue={timeFrame}
                    onChange={(opt) => setTimeFrame(opt.target.value)}>{
                timeOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
            <IntlMessages id={"channel:logistics:windows:from"}/>
            <input disabled={disabled} min={0} max={maxBoundaryGuard} className={classes.inputField}
                   style={{width: minInputWidth + 'ch'}} defaultValue={min} type="number" autoFocus
                   onChange={(e) => changePickupHandler(e, 'min')}/>
            <IntlMessages disabled={disabled} id={"channel:logistics:windows:to"}/>
            <input disabled={disabled} min={minBoundaryGuard} max={24} className={classes.inputField}
                   style={{width: maxInputWidth + 'ch'}} defaultValue={max} type="number" autoFocus
                   onChange={(e) => changePickupHandler(e, 'max')}/>
        </p>
    )
}

export default Windows