import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppBar, Button, Toolbar} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import {Form, FormGroup, Input} from 'reactstrap';
import AppConfig from 'Constants/AppConfig';
import {useValidation} from "../hooks";
import IntlMessages from "../Util/IntlMessages";
import {resetPassword} from "../Store/auth/AuthActions";
import {toast} from "react-toastify";

function PasswordReset() {

    const {validatePassword} = useValidation()
    const [password, setPassword] = useState<string>('');
    const [passwordRepeat, setPasswordRepeat] = useState<string>('');
    const [params, setParams] = useState<{ token: string, email: string }>({token: '', email: ''});
    const history = useHistory();
    const dispatch = useDispatch();

    const onSubmit = () => {
        const passwordError = validatePassword(password, passwordRepeat) || ''
        if (passwordError) {
            passwordError && toast.error(<IntlMessages id={passwordError}/>)
            return;
        }

        dispatch(resetPassword(password, params.token, params.email, history));
    }

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const encodedToken = urlParams.get('token');

        if (encodedToken) {
            let decodedToken = '';
            try {
                decodedToken = atob(encodedToken);
            } catch {
                toast.error(<IntlMessages id={"validation:general_error"}/>);
                history.push('/login');
                return;
            }

            const tokenRegex = decodedToken.match(/token=(.*?),/);
            const emailRegex = decodedToken.match(/email=(.*?)$/);

            if (!tokenRegex || !emailRegex) return;
            const email = emailRegex[1];
            const token = tokenRegex[1];
            if (!email || !token) return;

            setParams({token, email});
        }
    }, [])

    return (
        <div className="rct-session-wrapper">
            <AppBar position="static" className="session-header">
                <Toolbar>
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="session-logo">
                                <Link to="/">
                                    <img src={AppConfig.appLogo} alt="session-logo" className="img-fluid"
                                         width="110" height="35"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="session-inner-wrapper">
                <div className="container">
                    <div className="row row-eq-height">
                        <div className="col-sm-7 col-md-6 col-lg-6 mx-auto">
                            <div className="session-body text-center">
                                <div className="session-head mb-30">
                                    <h2 className="font-weight-bold"><IntlMessages id={"password_change:title"}/>
                                    </h2>
                                </div>
                                <Form name="login-form">
                                    <FormGroup className="has-wrapper">
                                        <Input
                                            value={password}
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="has-input input-lg"
                                            placeholder="New password"
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                        <span className="has-icon"><i className="ti-lock"></i></span>
                                    </FormGroup>
                                    <FormGroup className="has-wrapper">
                                        <Input
                                            value={passwordRepeat}
                                            type="password"
                                            name="password"
                                            id="passwordRepeat"
                                            className="has-input input-lg"
                                            placeholder="Repeat password"
                                            onChange={(event) => setPasswordRepeat(event.target.value)}
                                        />
                                        <a className="d-flex justify-content-end" href={'/login'}><IntlMessages
                                            id={"password_change:back_to_login"}/></a>
                                        <span className="has-icon"><i className="ti-lock"></i></span>
                                    </FormGroup>
                                    <FormGroup className="mb-15">
                                        <Button
                                            color="primary"
                                            className="btn-block text-white w-100"
                                            variant="contained"
                                            size="large"
                                            onClick={onSubmit}
                                        >
                                            <IntlMessages id={"password_change:button"}/>
                                        </Button>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
