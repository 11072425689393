import {Form, FormGroup} from "reactstrap";
import SelectComponent from "../Select/Select";
import {Button} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import IntlMessages from "../../Util/IntlMessages";
import RootState from "../../Store/RootState";
import {ChannelData} from "../../Store/channel/channelTypes";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {makeStyles} from "@material-ui/core/styles";

interface IChannelSelectionProps {
    channels: Array<ChannelData>;
    onChannelSelect: (item: ChannelData) => void,
}

const useStyles = makeStyles((theme) => ({
    channelSelect: {
        width: '100%'
    }
}))

function ChannelSelection({channels, onChannelSelect}: IChannelSelectionProps) {

    const classes = useStyles();

    const selected = useSelector<RootState, ChannelData | undefined>(state => state.channel.selected);
    const [selectedChannel, setSelectedChannel] = useState<ChannelData | undefined>(selected);

    useEffect(() => {
        setSelectedChannel(() => channels[0]);
    }, [channels])

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const channel = channels.find(item => item.id === parseInt(event.target.value))
        if (!channel) return;
        setSelectedChannel(() => channel);
    }
    const selectChannel = () => {
        if (!selectedChannel) return;
        onChannelSelect(selectedChannel);
    }

    return (
        <React.Fragment>
            {!selectedChannel ? <CustomSpinner/> : <Form name="channel-form">
                <FormGroup className="mb-0">
                    <SelectComponent className={classes.channelSelect} items={channels} handleChange={handleChange} selected={selectedChannel}/>
                    <Button
                        color="primary"
                        className="btn-block text-white w-100"
                        variant="contained"
                        size="large"
                        onClick={selectChannel}
                    >
                        <IntlMessages id={"channel_selection:continue"}/>
                    </Button>
                </FormGroup>
            </Form>}
        </React.Fragment>
    )
}

export default ChannelSelection;