import moment from "moment";
import {IChartData, IInvoice} from "../Store/finance/financeTypes";
import {RGBColor} from "react-color";
import {Color} from "../Components/ChannelTabs/Tabs/Styling";
import { BookingFilters } from "Store/bookings/bookingsTypes";

export const getStatus = (key: string) => {
    const statuses: { [key: string]: string } = {
        "STATUS.PROGRESS.000": "PROCESSING",
        "STATUS.PROGRESS.001": "CREATED",
        "STATUS.PROGRESS.002": "READY",
        "STATUS.PROGRESS.003": "AWAITING_PICKUP",
        "STATUS.PROGRESS.005": "IN_PROGRESS",
        "STATUS.PROGRESS.006": "DROPPED_OFF",
        "STATUS.PROGRESS.007": "COMPLETED",
        "STATUS.PROGRESS.008": "PICKED_UP",
        "STATUS.PROGRESS.009": "AWAITING_PAYMENT",
        "STATUS.PROGRESS.010": "PAID",
        "STATUS.EVENT.001": "LABEL_REQUESTED",
        "STATUS.EVENT.002": "LABEL_ISSUED",
        "STATUS.EVENT.003": "CHECKED_IN",
        "STATUS.EVENT.004": "UPDATED",
        "STATUS.EVENT.005": "ASSIGNED",
        "STATUS.EVENT.006": "SUBMITTED",
        "STATUS.EVENT.007": "VALIDATED",
        "STATUS.EVENT.008": "VERIFIED",
        "STATUS.EVENT.009": "BANNED",
        "STATUS.ERROR.001": "CANCELLED",
        "STATUS.ERROR.002": "DELAYED",
        "STATUS.ERROR.003": "LOST",
        "STATUS.ERROR.004": "LABEL_ERROR",
        "STATUS.ERROR.005": "INVALID",
        "STATUS.ERROR.006": "FAILED",
        "STATUS.REGISTERED.001": "REGISTERED",
    }

    const status = statuses[key];
    if (!status) return 'Error'
    else return status;
}

export const getBadgeClass = (status: string) => {
    if (status === "STATUS.PROGRESS.002") return "warning";
    else if (status === "STATUS.PROGRESS.005") return "primary";
    else if (status === "STATUS.ERROR.001") return "danger";
    else if (status === "STATUS.PROGRESS.007") return "success";
    else return "";
};

export const getChartData = (invoices: Array<IInvoice>): IChartData => {
    return invoices.reduce((acc: any, invoice: any) => {
        // get week number
        const week = `${moment(invoice.timestamp).week()}`;
        // add week number as key if doesn't exist
        if (!acc[week]) acc[week] = {values: [], total: 0};
        // push gross amounts and total to the week calculated before
        acc[week].values.push(invoice.gross);
        acc[week].total = acc[week].values.reduce((prev: any, curr: any) => prev + curr)
        return acc;
    }, {});
}

export const price = (currency: string, price: number, exponent: number): string => {
    if (!currency || !price || !exponent) return "0";

    return new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: currency,
    }).format(calculatePrice(price, exponent))
}

export const calculatePrice = (price: number, exponent: number): number => {
	const factor = Math.pow(10, exponent);
	return price / factor;
};

export function getDates() {
    const today = {
        type: 'today',
        filters: {'from_datetime': moment().utcOffset(0).startOf('date').toISOString()}
    };
    const yesterday = {
        type: 'yesterday',
        filters: {
            'from_datetime': moment().subtract(1, 'day').utcOffset(0).startOf('day').toISOString(),
            'to_datetime': moment().utcOffset(0).endOf('day').toISOString()
        }
    };
    const week = {
        type: 'week',
        filters: {'from_datetime': moment().subtract(7, 'day').startOf('day').toISOString()},
    };
    const month = {
        type: 'month',
        filters: {'from_datetime': moment().subtract(30, 'day').startOf('day').toISOString()},
    };
    const quarter = {
        type: 'quarter',
        filters: {'from_datetime': moment().subtract(90, 'day').startOf('day').toISOString()},
    };
    return {today, yesterday, week, month, quarter};
}

export function getSelectedItem<T>(value: string, type: T): { id: number, name: string } {
    const index: number = Object.keys(type).indexOf(value);
    return {id: index, name: value}
}

export function convertEnumToOptions<T>(type: T): Array<{ id: number, name: string }> {
    return Object.keys(type).map((item, index) => {
        return {id: index, name: item}
    })
}

export const getColours = (color: string): Array<Color> => {
    const regex = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/gi;
    let result: any;
    const indices = [];

    while ((result = regex.exec(color))) {
        indices.push(result[0]);
    }

    return indices.map(item => {
        return {
            color: item,
            display: false
        };
    })
}

export const isAColor = (color: string): boolean => {
	const regex = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/gi;
	const result = regex.exec(color);

	return !!result?.length;
}

export const formatColor = (originalString: string, newColor: string, newColorIndex: number): string => {
    const regex = /(?:#|0x)(?:[a-f0-9]{3}|[a-f0-9]{6})\b|(?:rgb|hsl)a?\([^)]*\)/gi;
    let count = 0;
    const counter = (count: number) => count === newColorIndex + 1;
    return originalString.replace(regex, match => counter(++count) ? newColor : match);

}

export const createRgbaString = (rgb: RGBColor) => {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
}

export const getImageUrl = (input: string): string => {
    if (!input) return '';
    if (input.startsWith('./static/media') || input.startsWith('https://storage.google')) return input;
    const regex = /\(([^)]+)\)/gi;
    const match = regex.exec(input);
    if (!match) return '';
    return match[1];
}

export const validateTranslations = (english: { [key: string]: string }, translation: { [key: string]: string }) => {
    const missing = Object.keys(english).find(item => !translation[item]);
    return !!missing;
}

export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export const getReadWritePermission = (scopes: string[]): { read: boolean, write: boolean } => {
    return {
        read: !!scopes.find(element => element.includes("read")),
        write: !!scopes.find(element => element.includes("write"))
    }
}

export const isObject = (item: Record<string, any>): boolean => {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

export function mergeDeepLogistics<T>(target: Record<string, any>, source: Record<string, any>): T {
    const overwriteKeys = ['from', 'to', 'pickup', 'delivery'];
    const output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
        Object.keys(source).forEach((key) => {
            if (isObject(source[key])) {
                if (!(key in target) || overwriteKeys.includes(key))
                    Object.assign(output, {[key]: source[key]});
                else
                    output[key] = mergeDeepLogistics(target[key], source[key]);
            } else {
                Object.assign(output, {[key]: source[key]});
            }
        });
    }
    return output as T;
}

export const getMonthlyIntervals = (start: string, end: string): {
    from_datetime: moment.Moment;
    to_datetime: moment.Moment;
}[] => {
    const intervals = [];
    const current = moment(start);
    while (current.isBefore(end)) {
        const intervalStart = current.clone().startOf('month');
        const intervalEnd = current.clone().endOf('month').isAfter(end) ? moment(end) : current.clone().endOf('month');
        intervals.push({from_datetime: intervalStart, to_datetime: intervalEnd});
        current.add(1, 'months');
    }
    return intervals;
};

export const updateFilters = (filters: BookingFilters, search: string) => {
	return {
		...filters.date && { date: filters.date },
		...filters.from_datetime && {
			...filters.date === 'pickup' ?
				{ pickup_from_datetime: moment(filters.from_datetime).utcOffset(0).startOf('day').toISOString() } :
				{ from_datetime: moment(filters.from_datetime).utcOffset(0).startOf('day').toISOString() }
		},
		...filters.to_datetime && {
			...filters.date === 'pickup' ?
				{ pickup_to_datetime: moment(filters.to_datetime).utcOffset(0).endOf('day').toISOString() } :
				{ to_datetime: moment(filters.to_datetime).utcOffset(0).endOf('day').toISOString() }
		},
		...filters.status && { status: filters.status },
		...filters.service && { service: filters.service },
		...search && { code: search }
	}
}