import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {alpha, makeStyles} from '@material-ui/core/styles';
import {useHistory, withRouter} from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import {collapsedSidebarAction} from '../../Store/app/AppActions';
import {filterBookings, searchContext, setBookingFilters} from "../../Store/bookings/BookingsActions";
import ClearIcon from "@material-ui/icons/Clear";
import SelectComponent from "../Select/Select";
import {setChannel} from "../../Store/channel/ChannelActions";
import {AppBar, Button, Drawer, IconButton, Input, InputAdornment, Toolbar, Tooltip,} from '@material-ui/core';
import IntlMessages from "../../Util/IntlMessages";
import RootState from "../../Store/RootState";
import {ChannelData} from "../../Store/channel/channelTypes";
import {IAppState} from "../../Store/app/appTypes";
import { BookingFilters, filterInitialState } from 'Store/bookings/bookingsTypes';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        border: "0.5px solid #EEEEEE",
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    iconButton: {
        padding: theme.spacing(0)
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#464D69"
    },
    input: {
        padding: 4,
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    form: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    selectInput: {
        borderRadius: theme.shape.borderRadius,
        padding: theme.spacing(1, 1, 1, 0),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        transition: theme.transitions.create("width"),
        minWidth: "150px",
        [theme.breakpoints.up("md")]: {
            width: "20ch",
        },
    },
}));

const Header = () => {

    const [customizer, setCustomizer] = useState(false);
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();
    const history = useHistory();
    const selected = useSelector<RootState, ChannelData | undefined>(state => state.channel.selected)
    const settings = useSelector<RootState, IAppState>(state => state.app);
    const channels = useSelector<RootState, Array<ChannelData>>(state => state.channel.available);
		const filters = useSelector<RootState, BookingFilters>(state => state.booking.filters);
    const channelSelectIsDisabled = channels.length === 1;
    const classes = useStyles();

    const onToggleNavCollapsed = (): void => {
        dispatch(collapsedSidebarAction(!settings.navCollapsed));
    }

    const resetSearch = () => {
        if (!selected) return;
				dispatch(setBookingFilters(filterInitialState, true))
        dispatch(filterBookings({channelId: selected.id, offset: 0, filters: filterInitialState}))
        setSearch(() => '')
    }

    const onChannelSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const channel = channels.find(item => item.id === parseInt(event.target.value))
        if (!channel) return;
        dispatch(setChannel(channel));
    }

    const searchInBookings = (): void => {
			const updatedFilters = {
				...filters.status && { status: filters.status },
				...filters.service && { service: filters.service },
				...search && { code: search },
			}

			if (!selected) return;
			dispatch(filterBookings({channelId: selected.id, filters: updatedFilters, offset: 0}))
			dispatch(setBookingFilters({...updatedFilters, ...filterInitialState}))
    }

    useEffect(() => {
        dispatch(searchContext(search));
        search && history.push('/app/bookings');
    }, [search])

    return (
        <AppBar position="static" className="rct-header">
            <Toolbar className="d-flex justify-content-between w-100 pl-0">
                <div className="d-inline-flex align-items-center">
                    <ul className="list-inline mb-0 navbar-left d-inline-flex align-items-center">
                        <li className="list-inline-item" onClick={onToggleNavCollapsed}>
                            <Tooltip title="Sidebar Toggle" placement="bottom">
                                <IconButton color="inherit" aria-label="Menu" id="Menu" className="humburger p-0">
                                    <MenuIcon/>
                                </IconButton>
                            </Tooltip>
                        </li>
                        <li className="list-inline-item d-flex">
                            <div className="list-inline-item d-flex">
                                <div className={classes.search}>
                                    <div className={classes.searchIcon}>
                                        <SearchIcon/>
                                    </div>
                                    <Input
                                        placeholder="7fdb9b"
                                        classes={{root: classes.input,}}
                                        value={search}
                                        onChange={(e) => setSearch(e.target.value)}
                                        endAdornment={
                                            (search &&
                                                <InputAdornment position="end">
                                                    <IconButton className={classes.iconButton}
                                                                onClick={resetSearch}>
                                                        <ClearIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    />
                                </div>
                                <Button color="primary" variant="contained" onClick={searchInBookings}
                                ><IntlMessages id={"header:search"}/></Button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="d-inline-flex align-items-right">
                    <ul className="list-inline mb-0 navbar-right d-inline-flex align-items-right">
                        <li className="w-100 list-inline-item d-flex">
                            <div className="w-100 list-inline-item d-flex">
                                <SelectComponent disabled={channelSelectIsDisabled} className={classes.selectInput}
                                                 items={channels} handleChange={onChannelSelect} selected={selected}/>
                            </div>
                        </li>
                    </ul>
                </div>
                <Drawer
                    anchor={'right'}
                    open={customizer}
                    onClose={() => setCustomizer(false)}
                >
                </Drawer>
            </Toolbar>
        </AppBar>
    );
}

export default withRouter(Header);
