import React from 'react';
import {Redirect, Route, RouteComponentProps} from 'react-router-dom';
import RctThemeProvider from './RctThemeProvider';
import RctDefaultLayout from './DefaultLayout';
import Callback from "../Components/Callback/Callback";
import Login from "./Login";
import Authentication from "../Auth/Auth";
import {useSelector} from "react-redux";
import Channel from "./Channel";
import PasswordReset from "./PasswordReset";
import PasswordChange from "./PasswordChange";
import RootState from "../Store/RootState";
import MissingPermissionPage from "./MissingPermissionPage";
import {
    BOOKING,
    CALLBACK,
    CHANNEL, HOME,
    LOGIN,
    MISSING_PERMISSION_PAGE,
    PASSWORD_CHANGE,
    RESET_PASSWORD
} from "../Constants/paths";

interface IPathProps {
    component: any;
    isLoggedIn: boolean;
    channelIsSelected: boolean;
    path: string;
}

const InitialPath = ({component, isLoggedIn, channelIsSelected, ...rest}: IPathProps) => {
    const Component = component;
    return <Route
        {...rest}
        render={props =>
            isLoggedIn
                ? (channelIsSelected
                    ? <Component {...props} />
                    : <Redirect to={CHANNEL}/>)
                : <Redirect
                    to={{
                        pathname: LOGIN,
                        state: {from: props.location}
                    }}
                />}
    />
};

function App({...props}: RouteComponentProps) {
    const {match} = props;
    const isLoggedIn = Authentication.isLoggedIn();
    const channelIsSelected = useSelector<RootState, boolean>(state => !!state.channel.selected);

    if (location && location.pathname === HOME) {
        if (!isLoggedIn) {
            return (<Redirect to={LOGIN}/>);
        } else {
            return (<Redirect to={BOOKING}/>);
        }
    }
    return (
        <RctThemeProvider>
            <InitialPath
                path={`${match.url}app`}
                isLoggedIn={isLoggedIn}
                component={RctDefaultLayout}
                channelIsSelected={channelIsSelected}
            />
            <Route path={LOGIN} component={Login}/>
            <Route path={RESET_PASSWORD} component={PasswordReset}/>
            <Route path={PASSWORD_CHANGE} component={PasswordChange}/>
            <Route path={MISSING_PERMISSION_PAGE} component={MissingPermissionPage}/>
            <Route path={CHANNEL} component={Channel}/>
            <Route path={CALLBACK} render={(props) => {
                return <Callback {...props} />
            }}/>
        </RctThemeProvider>
    );
}

export default App;
