import {
	CLEAR_BOOKINGS,
	SET_BOOKING_SEARCH,
	GET_TENANT,
	GET_BOOKING_DETAIL,
	SET_BOOKINGS,
	SimpleBooking,
	SET_FILTERS,
	SET_BOOKING_CHANNEL,
	SET_BOOKING_STATUS,
	BookingFilters,
	filterInitialState,
	CancelBookingParams
} from './bookingsTypes';
import Fetcher from "../../Util/Fetcher";
import { Dispatch } from "redux";

export const filterBookings = ({channelId, filters, offset, limit = 10}: {channelId: number, offset: number, filters?: any, limit?: number}) => async (dispatch: Dispatch): Promise<void>  => {
	if (offset === 0) {
			dispatch({
					type: CLEAR_BOOKINGS
			})
	}

	const updatedFilters = {
			...filters,
			...filters.date && {date: filters.date},
			...filters.from_datetime && {from_datetime: filters.from_datetime},
			...filters.to_datetime && {to_datetime: filters.to_datetime},
			...filters.service && {service: filters.service},
			status: filters?.status || [
					"STATUS.PROGRESS.005",
					"STATUS.PROGRESS.002",
					"STATUS.PROGRESS.007",
					"STATUS.ERROR.001"
			]
	}

	try {
			const {data} = await Fetcher({path: `channels/${channelId}/bookings/`, params: {offset, limit, ...updatedFilters}});
			dispatch({
					type: SET_BOOKINGS,
					payload: {bookings: data.results, offset: data.offset, count: data.count, previous: data.previous, channel: channelId},
			});
	} catch (error) {
			console.error('Error while getting filtered bookings ', error);
	}
}

export const setBookings = (data: SimpleBooking[]) => async (dispatch: Dispatch): Promise<void> => { 
	dispatch({
		type: SET_BOOKINGS,
		payload: {bookings: data},
	});
}

export const searchContext = (context: string) => async (dispatch: Dispatch): Promise<void> => {
	dispatch({
		type: SET_BOOKING_SEARCH,
		payload: context
	})
};

export const getTenant = (tenantId: number) => async (dispatch: Dispatch): Promise<void> => {
	const { data } = await Fetcher({ path: `tenants/${tenantId}/`, cache: true });
	dispatch({
		type: GET_TENANT,
		payload: data,
	});
};

export const getBookingDetails = (channelId: number, bookingCode: string) => async (dispatch: Dispatch): Promise<void> => {
    try {
        const {data: bookingData} = await Fetcher({
            path: `channels/${channelId}/bookings/${bookingCode}/`,
            cache: false
        });

        let data = {...bookingData};

        if (bookingData.assignments && bookingData.assignments.length > 0) {
            const assignmentId = bookingData.assignments[0].id;

            try {
                const {data: assignmentData} = await Fetcher({
                    path: `transport/assignment/${assignmentId}/`,
                    cache: false,
                });

                const mergedAssignment = {
                    ...bookingData.assignments[0],
                    ...assignmentData,
                };

                data = {
                    ...bookingData,
                    assignments: [
                        mergedAssignment,
                        ...bookingData.assignments.slice(1),
                    ],
                };
            } catch (error) {
                console.error(error)
            }
        }

        dispatch({
            type: GET_BOOKING_DETAIL,
            payload: data,
        });
    } catch (error) {
        console.error(error);
    }
};

export const setBookingFilters = (filters: BookingFilters, reset?: boolean)  => async (dispatch: Dispatch): Promise<void> =>  {
	if (reset) {
			dispatch({
					type: CLEAR_BOOKINGS
			})
			dispatch({
				type: SET_FILTERS,
				payload: filterInitialState
			})
	}

	dispatch({
			type: SET_FILTERS,
			payload: filters,
	});
};

export const setCurrentChannel = (channel: number)  => async (dispatch: Dispatch): Promise<void> =>  {
	dispatch({
			type: SET_BOOKING_CHANNEL,
			payload: channel,
	});
};

export const cancelBooking = (params: CancelBookingParams) => async (dispatch: Dispatch): Promise<void> => {
    const cancelStatus = "STATUS.ERROR.001";
    const {bookingCode, message, ...rest} = params;

    try {
        const {data} = await Fetcher({
            path: `bookings/${bookingCode}/status/`, method: "POST", cache: true, body: {
                name: cancelStatus,
                message,
                fields: {
                    ...rest
                }
            }
        });
        dispatch({
            type: SET_BOOKING_STATUS,
            payload: {
                status: data.name,
                code: bookingCode
            }
        })
        return data;
    } catch (error) {
        console.error(error);
    }
};