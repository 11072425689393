import React, {useEffect, useState} from 'react';
import {
    CompanyDetails,
    ChannelData,
    IChannelColorsState,
    Image,
    Permission,
    WhitelabelImages,
    IChannelVariablesState
} from "../../../Store/channel/channelTypes";
import {ColorResult, SketchPicker} from "react-color";
import {makeStyles} from "@material-ui/core/styles";
import {getImageUrl, isAColor} from "../../../Util/utils";
import {Button, FormControl, FormControlLabel, Radio, RadioGroup, TextField, Typography} from "@material-ui/core";
import {updateChannelDetails, uploadImage} from "../../../Store/channel/ChannelActions";
import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "../../../Util/IntlMessages";
import DesktopPreview from "../Components/Previews/Desktop";
import TabletPreview from "../Components/Previews/Tablet";
import MobilePreview from "../Components/Previews/Mobile";
import RootState from "../../../Store/RootState";
import {DateTime} from "luxon";
import InfoCard from '../Components/Previews/PreviewComponents/InfoCard/InfoCard';
import PriceOverview from '../Components/Previews/PreviewComponents/PriceOverview/PriceOverview';
import FlowSelector from '../Components/Previews/PreviewComponents/FlowSelector/FlowSelector';
import Spinner from '../Components/Previews/PreviewComponents/Spinner/Spinner';
import ProgressBar from '../Components/Previews/PreviewComponents/ProgressBar/ProgressBar';

interface IStylingProps {
    showWhitelabel: Permission;
}

const useStyles = makeStyles((theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("sm")]: {
            flexWrap: 'wrap'
        },
    },
    column: {
        width: "50%",
        display: 'flex',
        flexFlow: "column",
        [theme.breakpoints.down("sm")]: {
            padding: theme.spacing(2),
            width: "100%",
        },
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(4),
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center'
        },
    },
    color: {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: '#fff',
    },
    swatch: {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    },
    popover: {
        display: 'flex',
        position: 'absolute',
        zIndex: 2,
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    picker: {
        margin: '38px 5px 5px',
    },
    disabled: {
        pointerEvents: 'none',
        opacity: .5
    }
}));

export interface EditColor {
    [key: string]: Color[]
}

export interface EditImage {
    header: Image,
    desktopLogo: Image,
    tabletLogo: Image,
    mobileLogo: Image,
    footerLogo: Image
}

export interface Color {
    color: string,
    display: boolean
}

const Styling = ({showWhitelabel}: IStylingProps): JSX.Element => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const reader = new FileReader();
    const channel = useSelector<RootState, Required<ChannelData>>(state => state.channel.selected as Required<ChannelData>);
    const {
        variables,
        images,
    }: { variables: IChannelVariablesState, images: Required<WhitelabelImages>, company: Required<CompanyDetails> } = channel.properties.whitelabel;

    const editVariablesInitialState = Object.fromEntries(
        Object.entries(variables)
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(([key, value]) => {
                return [key, [{variable: value, display: false, isAColor: isAColor(value)}]];
            })
    );

    const editImagesInitialState = {
        header: {display: getImageUrl(variables["header::background"])},
        desktopLogo: {display: getImageUrl(images.desktopLogo)},
        tabletLogo: {display: getImageUrl(images.tabletLogo)},
        mobileLogo: {display: getImageUrl(images.mobileLogo)},
        footerLogo: {display: './static/media/bagpoint-logo.svg'}
    }

    const [editVariables, setEditVariables] = useState<{
        [k: string]: {
            variable: string;
            display: boolean;
            isAColor: boolean;
        }[];
    }>(editVariablesInitialState)
    const [variablesPreview, setVariablesPreview] = useState(variables);
    const [editImages, setEditImages] = useState<EditImage>(editImagesInitialState);
    const [view, setView] = useState<string>('desktop');

    const handleClick = (name: string, column: number) => {
        const updatedVariable = {...editVariables[name]};

        updatedVariable[column].display = !updatedVariable[column].display;
        setEditVariables((prev) => ({...prev, [name]: updatedVariable}));
    };

    const handleColorChange = (colorResult: ColorResult, name: string, column: number) => {
        // update edit colour
        const updatedVariable = editVariables[name];
        updatedVariable[column].variable = colorResult.hex;

        setEditVariables((prev) => ({...prev, [name]: updatedVariable}));
    };

    const handleTextChange = (value: string, name: string, column: number) => {
        const updatedVariable = editVariables[name];
        updatedVariable[column].variable = value;
        setEditVariables((prev) => ({...prev, [name]: updatedVariable}));
    }

    const onImageChange = (input: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const file = input.target.files && input.target.files[0];
        if (!file) return;
        reader.readAsDataURL(file);
        reader.onload = function () {
            const result = reader.result;
            if (typeof result !== 'string') return;
            setEditImages((prev) => (
                {...prev, [type]: {display: result, file}}
            ));
        };
    };

    const resetColors = () => {
        setEditVariables(() => editVariablesInitialState);
        setEditImages(() => editImagesInitialState);
    }

    const updateProperties = async () => {
        const dt = DateTime.local();
        try {
            const imagesArray = await Promise.all(
                Object.entries(editImages).map(async ([key, value]) => {
                    if (value.file) {
                        const formData = new FormData();
                        formData.append('image', value.file);
                        formData.append('type', key);
                        return await uploadImage(channel, formData)
                            .then((response) => {
                                return {[response.data.type]: {display: response.data.url.concat(`?refreshed=${dt.toMillis()}`)}}
                            })
                            .catch(() => {
                                return {[key]: value}
                            })
                    } else return {[key]: value}
                })
            )

            const updatedEditImages = Object.assign({}, ...imagesArray)
            const images = {
                mobileLogo: updatedEditImages.mobileLogo.display,
                desktopLogo: updatedEditImages.desktopLogo.display,
                tabletLogo: updatedEditImages.tabletLogo.display,
            }


            // Format the variables back to it's original object with the edited values
            const variables = Object.fromEntries(
                Object.entries(editVariables).map(([key, valueArray]) => {
                    return [key, valueArray[0].variable];
                })
            );

            dispatch(updateChannelDetails(channel, {whitelabel: {variables, images}}))
        } catch (error) {
            console.log('error while uploading image')
        }
    }

    useEffect(() => {
        const variables = Object.fromEntries(
            Object.entries(editVariables).map(([key, valueArray]) => {
                return [key, valueArray[0].variable];
            })
        );
        setVariablesPreview(variables)
    }, [editVariables])

    return (
        <div className={classes.page}>
            <div className={classes.row}>
                {showWhitelabel.read &&
                    <div className={classes.column}>
                        <Typography variant={'h5'}><IntlMessages id={"channel:styling:main_colours"}/></Typography>
                        <div className="row w-100 d-flex pt-10">
                            {Object.entries(editVariables).map(([name, values], row) => (
                                <div className="row w-100" key={row}>
                                    <div className="w-100 d-flex align-items-center p-2">
                                        {Object.entries(values).map(([, item], column) => {
                                            const key = `${row}-${column}`;
                                            const isDisabled = !showWhitelabel.write;
                                            const commonClasses = isDisabled ? `${classes.disabled} ${classes.swatch} col-` : `${classes.swatch} col-`;
                                            const onClick = () => handleClick(name, column);

                                            return (
                                                <React.Fragment key={key}>
                                                    {
                                                        item.isAColor ? (
                                                            <div className={commonClasses} onClick={onClick}>
                                                                <div className={classes.color}
                                                                     style={{background: item.variable}}/>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {item.display ? (
                                                                    <div className="col-3 p-0">
                                                                        <div className={classes.cover}
                                                                             onClick={onClick}/>
                                                                        <TextField value={item.variable}
                                                                                   onChange={(e) => handleTextChange(e.target.value, name, column)}/>
                                                                    </div>
                                                                ) : (
                                                                    <div className={`${commonClasses}`}
                                                                         onClick={onClick}>
                                                                        <div
                                                                            className={`${classes.color} d-flex align-items-center justify-content-center`}>...
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )
                                                    }
                                                    {item.display && item.isAColor && (
                                                        <div className={classes.popover}>
                                                            <div className={classes.cover} onClick={onClick}/>
                                                            <SketchPicker
                                                                className={classes.picker}
                                                                color={item.variable}
                                                                onChange={(result) => handleColorChange(result, name, column)}
                                                            />
                                                        </div>
                                                    )}
                                                </React.Fragment>
                                            );
                                        })}

                                        <div className="col-6">{name}</div>
                                    </div>
                                </div>
                            ))}
                            <div className="row w-100">
                                <div className="w-100 d-flex align-items-center p-2">
                                    <div className="col-6"><IntlMessages id={"channel:styling:background_image"}/></div>
                                    <input disabled={!showWhitelabel.write} type="file" accept="image/jpeg"
                                           multiple={false} onChange={(e) => onImageChange(e, 'header')}/>
                                </div>
                            </div>
                            <div className="row w-100">
                                <div className="w-100 d-flex align-items-center p-2">
                                    <div className="col-6"><IntlMessages id={"channel:styling:logo:desktop"}/></div>
                                    <input disabled={!showWhitelabel.write} type="file" accept="image/svg+xml"
                                           multiple={false} onChange={(e) => onImageChange(e, 'desktopLogo')}/>
                                </div>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="w-100 d-flex align-items-center p-2">
                                <div className="col-6"><IntlMessages id={"channel:styling:logo:tablet"}/></div>
                                <input disabled={!showWhitelabel.write} type="file" accept="image/svg+xml"
                                       multiple={false}
                                       onChange={(e) => onImageChange(e, 'tabletLogo')}/>
                            </div>
                        </div>
                        <div className="row w-100">
                            <div className="w-100 d-flex align-items-center p-2">
                                <div className="col-6"><IntlMessages id={"channel:styling:logo:mobile"}/></div>
                                <input disabled={!showWhitelabel.write} type="file" accept="image/svg+xml"
                                       multiple={false}
                                       onChange={(e) => onImageChange(e, 'mobileLogo')}/>
                            </div>
                        </div>
                    </div>}
                <div className={classes.column}>
                    <div>
                        <FormControl>
                            <Typography variant={'h5'} className="mr-15"><IntlMessages
                                id={"channel:styling:viewAs"}/></Typography>
                            <RadioGroup onChange={(e) => setView(() => e.target.value)} value={view} row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group">
                                <FormControlLabel value="desktop" className="mb-0" control={<Radio/>}
                                                  label={<IntlMessages id={"channel:styling:view:desktop"}/>}/>
                                <FormControlLabel value="tablet" className="mb-0" control={<Radio/>}
                                                  label={<IntlMessages id={"channel:styling:view:tablet"}/>}/>
                                <FormControlLabel value="mobile" className="mb-0" control={<Radio/>}
                                                  label={<IntlMessages id={"channel:styling:view:mobile"}/>}/>
                            </RadioGroup>
                        </FormControl>
                        <div className="row  w-100 pt-10">
                            <div className="w-100">
                                {view === 'desktop' &&
                                    <DesktopPreview variables={variablesPreview} images={editImages}/>}
                                {view === 'tablet' && <TabletPreview variables={variablesPreview} images={editImages}/>}
                                {view === 'mobile' && <MobilePreview variables={variablesPreview} images={editImages}/>}
                            </div>

                            <div className="w-100 ml-10 my-10">
                                <Typography variant="h6">Progress Bar</Typography>
                                <div className="d-flex">
                                    <ProgressBar variables={variablesPreview}/>
                                </div>
                            </div>

                            <div className="w-100 ml-10 my-10">
                                <Typography variant="h6">Info Card</Typography>
                                <div className="d-flex">
                                    <InfoCard type="info" variables={variablesPreview}/>
                                    <InfoCard type="error" variables={variablesPreview}/>
                                </div>
                            </div>

                            <div className="w-100 ml-10 my-10">
                                <Typography variant="h6">Price Overview</Typography>
                                <PriceOverview variables={variablesPreview}/>
                            </div>

                            <div className="w-100 ml-10 my-10">
                                <Typography className="" variant="h6">Flow Selector</Typography>
                                <div className="d-flex">
                                    <FlowSelector active variables={variablesPreview}/>
                                    <FlowSelector active={false} variables={variablesPreview}/>
                                </div>
                            </div>

                            <div className="w-100 ml-10 my-10">
                                <Typography className="" variant="h6">Spinner</Typography>
                                <div className="d-flex">
                                    <Spinner color={variablesPreview['spinner::background']}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showWhitelabel.write && <div className={classes.row}>
                <Button variant="contained" color={'primary'} className="m-5" onClick={updateProperties}><IntlMessages
                    id={"channel:styling:save"}/></Button>
                <Button variant="contained" color={'secondary'} className="m-5" onClick={resetColors}><IntlMessages
                    id={"channel:styling:discard"}/></Button>
            </div>}
        </div>
    )
}

export default Styling;