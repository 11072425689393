import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppBar, Toolbar } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppConfig from 'Constants/AppConfig';
import { getChannels, setChannel } from "../Store/channel/ChannelActions";
import ChannelSelection from "../Components/ChannelSelection/ChannelSelection";
import { toast } from "react-toastify";
import IntlMessages from "../Util/IntlMessages";
import RootState from "../Store/RootState";
import { IUser } from "../Store/auth/authTypes";
import { ChannelData } from "../Store/channel/channelTypes";
import { setCurrentChannel } from 'Store/bookings/BookingsActions';

function Channel() {

	const loading = useSelector<RootState, boolean>(state => state.app.loading);
	const user = useSelector<RootState, IUser>(state => state.auth.user);
	const channels = useSelector<RootState, Array<ChannelData>>(state => state.channel.available);
	const selected = useSelector<RootState, ChannelData | undefined>(state => state.channel.selected);
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (!user) history.push('/login');
		dispatch(getChannels());
		return () => { };
	}, [])

	useEffect(() => {
		if (selected) history.push('/app/bookings')
	}, [selected])

	const onChannelSelect = (channel: ChannelData) => {
		if (channel.name !== '') {
			Promise.all([
				dispatch(setChannel(channel)),
				dispatch(setCurrentChannel(channel.id))
			])
			history.push('/app/bookings')
		} else {
			toast.error(<IntlMessages id={"channel_selection:no_selected_channel_error"} />);
		}
	}

	return (
		<div className="rct-session-wrapper">
			{loading &&
				<LinearProgress />
			}
			<AppBar position="static" className="session-header">
				<Toolbar>
					<div className="container">
						<div className="d-flex justify-content-between">
							<div className="session-logo">
								<Link to="/">
									<img src={AppConfig.appLogo} alt="session-logo" className="img-fluid"
										width="110" height="35" />
								</Link>
							</div>
						</div>
					</div>
				</Toolbar>
			</AppBar>
			<div className="session-inner-wrapper">
				<div className="container">
					<div className="row row-eq-height">
						<div className="col-sm-7 col-md-6 col-lg-6 mx-auto">
							<div className="session-body text-center">
								<div className="session-head mb-30">
									<h2 className="font-weight-bold"><IntlMessages id={"channel_selection:title"} /></h2>
									<h5 className="font-weight-bold"><IntlMessages id={"channel_selection:subtitle"} /></h5>
									<p className="mb-0"><IntlMessages id={"channel_selection:content"} /></p>
								</div>
								<ChannelSelection channels={channels} onChannelSelect={onChannelSelect} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Channel;
