import Sidebar from "react-sidebar";
import React, {useEffect, useState} from "react";
import Header from "../Header/Header";
import SidebarContent from "../Sidebar";
import {Scrollbars} from "react-custom-scrollbars-2";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router-dom";
import {collapsedSidebarAction} from "../../Store/app/AppActions";
import RootState from "../../Store/RootState";
import {IAppState} from "../../Store/app/appTypes";
import {RouteProps} from "react-router";

const Dashboard = ({...props}: RouteProps) => {

    const {children} = props
    const settings = useSelector<RootState, IAppState>(state => state.app);
    const windowsWidth = window.innerWidth;
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 114);
    }, [])

    return (
        <div className="app">
            <div className="app-main-container">
                <Sidebar
                    sidebar={<SidebarContent/>}
                    open={windowsWidth <= 1199 ? settings.navCollapsed : false}
                    docked={windowsWidth > 1199 ? !settings.navCollapsed : false}
                    pullRight={settings.rtlLayout}
                    onSetOpen={() => dispatch(collapsedSidebarAction(!settings.navCollapsed))}
                    styles={{content: {overflowY: ''}}}
                    contentClassName={'app-conrainer-wrapper'}
                >
                    <div className="app-container">
                        <div className="rct-app-content">
                            <div className="app-header">
                                {<Header/>}
                            </div>
                            <div className="rct-page">
                                <Scrollbars
                                    className="rct-scroll"
                                    autoHide
                                    autoHideDuration={100}
                                    style={{height: 'calc(100vh - 50px)'}}
                                >
                                    <div className="rct-page-content">
                                        {children}
                                    </div>
                                </Scrollbars>
                            </div>
                        </div>
                    </div>
                </Sidebar>
            </div>
        </div>
    );
}

export default withRouter(Dashboard);
