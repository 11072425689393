import {applyMiddleware, createStore} from "redux";
import {persistReducer, persistStore} from "redux-persist";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import Reducers from "./rootReducer";

const middleware = [thunk];
const persistConfig = {
    key: "root",
    storage,
};
const persistedReducer = persistReducer(persistConfig, Reducers);
export const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(...middleware)));
export const persistor = persistStore(store);
export default {store, persistor};