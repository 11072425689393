import React from "react";
import styles from "./Spinner.module.scss";

interface Props {
	color: string;
}

const Spinner: React.FC<Props> = ({color}) => {

    return (
        <div className={styles.spinner}>
            {Array.from({length: 12}).map((_, i) => (
                <div key={i} style={{background: color}}/>
            ))}
        </div>
    );
}

export default Spinner;
