import React, {Fragment} from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {useSelector} from 'react-redux';
import {IntlProvider} from 'react-intl';
import AppLocale from '../lang';
import primaryTheme from "./themes/primaryTheme";
import RootState from "../Store/RootState";
import {IAppState} from "../Store/app/appTypes";
import {RouteProps} from "react-router";

function RctThemeProvider({...props}: RouteProps) {
    const settings = useSelector<RootState, IAppState>(state => state.app);
    const {locale, rtlLayout} = settings;
    const {children} = props;
    const currentAppLocale = AppLocale[locale.locale];
    const theme = primaryTheme;

    if (rtlLayout) {
        theme.direction = 'rtl'
    } else {
        theme.direction = 'ltr'
    }

    return (
        <MuiThemeProvider theme={theme}>
            <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
                <Fragment>
                    {children}
                </Fragment>
            </IntlProvider>
        </MuiThemeProvider>
    );
}

export default RctThemeProvider;
