import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
export const GET_FINANCES = "GET_FINANCES"
export const CLEAR_FINANCE_FILTER = "CLEAR_FINANCE_FILTER"
export const GET_ANALYTICS = "GET_ANALYTICS"

export interface IDateFilters {
    from_datetime?: string;
    to_datetime?: string;
}

export interface FinanceState {
    finance?: Finance;
    analytics?: Analytics;
}

export interface Finance {
    currency?: Currency;
    filter?: IFinanceDetail;
    today?: IFinanceOverview;
    yesterday?: IFinanceOverview;
    week?: IFinanceOverview;
    month?: IFinanceOverview;
    quarter?: IFinanceDetail;
}

export interface Analytics {
    total_visitors: number;
    total_purchasers: number;
    conversion: number;
}

export interface Currency {
    code: string;
    exponent: number;
    name: string;
    symbol: string;
}

export interface IFinanceDetail {
    commission: number;
    count: number;
    currency: Currency;
    invoices: Array<IInvoice>;
    sales: ISales;
}

export interface IFinanceOverview {
    commission: number;
    invoices: Array<IInvoice>;
}

export interface IInvoice {
    booking: string;
    channel: number;
    commission: number;
    gross: number;
    net: number;
    reference: string;
    timestamp: string;
}

export interface ISales {
    net: number;
    gross: number;
}

export interface FinanceFilters {
    from_datetime: MaterialUiPickersDate|null,
    to_datetime: MaterialUiPickersDate|null
}

export interface IChartData {
    [key: string]: {
        total: number;
        values: Array<number>;
    }
}

export interface AnalyticsFilters {
    channel: string;
    host: string;
    start_date: string;
    end_date: string;
}