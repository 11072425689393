import React, {useEffect, useLayoutEffect, useState} from 'react';
import {ChannelData, ILanguage, ILanguageMeta, Translation} from "../../../Store/channel/channelTypes";
import {Button, IconButton, Typography} from "@material-ui/core";
import IChannelLanguageToggleProps from "../Components/IChannelLanguageToggle";
import Edit from '@material-ui/icons/Edit';
import {validateTranslations} from "../../../Util/utils";
import {makeStyles} from "@material-ui/core/styles";
import StyledSwitch from "../../Switch/Switch";
import TranslationsInputField from "../Components/TranslationInputField";
import {updateChannelDetails} from "../../../Store/channel/ChannelActions";
import {useDispatch, useSelector} from "react-redux";
import IntlMessages from "../../../Util/IntlMessages";
import RootState from "../../../Store/RootState";
import {TranslationKeys} from "../../../Store/channel/translationKeys";
import CustomSpinner from "../../CustomSpinner/CustomSpinner";

interface ITranslationsProps {
    showTranslations: { read: boolean, write: boolean },

}

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.palette.error.dark,
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(1),
            textAlign: 'center',
        },
    },
    page: {
        display: 'flex',
        [theme.breakpoints.down("sm")]: {
            flexWrap: 'wrap'
        }
    },
    languagesEdit: {
        width: "30%",
        display: 'flex',
        flexFlow: "column",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            justifyContent: 'center'
        },
    },
    languages: {
        width: "100%",
        display: 'flex',
        flexFlow: "column",
        [theme.breakpoints.down("sm")]: {
            justifyContent: 'center'
        },
    },
    translations: {
        width: "70%",
        display: 'flex',
        flexFlow: "column",
        [theme.breakpoints.down("sm")]: {
            paddingTop: theme.spacing(5),
            width: "100%",
        },
    },
    buttons: {
        display: 'flex',
        width: '100%',
        justifyContent: "space-between",
        alignItems: 'center',
        [theme.breakpoints.down("xs")]: {
            flexWrap: 'wrap',
            justifyContent: "center",
        },
    }
}));

const Translations = ({showTranslations}: ITranslationsProps): JSX.Element => {

    const classes = useStyles();
    const dispatch = useDispatch();

    const channel = useSelector<RootState, Required<ChannelData>>(state => state.channel.selected as Required<ChannelData>);
    const {language}: { language: Required<ILanguage> } = channel.properties;
    const [editLanguage, setEditLanguage] = useState<string>();
    const [newTranslations, setNewTranslations] = useState<{ [key: string]: string }>({});
    const [missingLanguages, setMissingLangages] = useState<string[]>();
    const [translationKeys, setTranslationKeys] = useState<Array<string>>();
    const [showOnlyMissing, setShowOnlyMissing] = useState<boolean>(false);
    const [languages, setLanguages] = useState<{ [key: string]: { name: string, missing: boolean } }>();


    useEffect(() => {
        if (!language) return;
        setLanguages(() => ({
            ar: {name: 'Arabic', missing: validateTranslations(TranslationKeys, language.translations['ar'])},
            de: {name: 'German', missing: validateTranslations(TranslationKeys, language.translations['de'])},
            nl: {name: 'Dutch', missing: validateTranslations(TranslationKeys, language.translations['nl'])},
            en: {name: 'English', missing: validateTranslations(TranslationKeys, language.translations['en'])},
            sr: {name: 'Serbian', missing: validateTranslations(TranslationKeys, language.translations['sr'])}
        }))
        setTranslationKeys(() => Object.keys(TranslationKeys));
    }, [language])

    const openTranslations = (lang: string) => {
        setEditLanguage(() => lang);
    }

    useLayoutEffect(() => {
        if (!languages) return;
        const missing: Array<string> = [];
        Object.values(languages).map((value) => {
            if (value.missing) missing.push(value.name);
        });

        setMissingLangages(() => missing);
    }, [languages])

    useEffect(() => {
        if (!editLanguage || !translationKeys || !language) return;
        if (showOnlyMissing) {
            setTranslationKeys(() => Object.keys(TranslationKeys).filter((item: string) => !language.translations[editLanguage][item]))
        } else {
            setTranslationKeys(() => Object.keys(TranslationKeys))
        }
    }, [showOnlyMissing, editLanguage])

    const updateTranslationField = (value: { [key: string]: string }) => {
        setNewTranslations((prev) => ({...prev, ...value}))
    }

    const updateTranslation = () => {
        dispatch(updateChannelDetails(channel, {language: {translations: {...editLanguage && {[editLanguage]: {...newTranslations}}} as Translation}}))
    }

    const updateDefaultLanguage = (lang: string) => {
        dispatch(updateChannelDetails(channel, {language: {meta: {default: lang} as Partial<ILanguageMeta>}}))
    }

    const updateAvailableLanguages = (lang: string) => {
        /*
            Add language if it's not available, remove if it's available
         */
        const availableLanguages = language?.meta?.available;
        if (!availableLanguages) return;
        const updatedAvailable = availableLanguages.some(item => lang === item) ? // Checking if language is in array
            availableLanguages.filter(item => lang !== item) // Removing language from available languages
            : [...availableLanguages, lang] // Adding language to available languages
        dispatch(updateChannelDetails(channel, {language: {meta: {available: updatedAvailable} as Partial<ILanguageMeta>}}))
    }

    return (
        <div>
            {language && languages && showTranslations.read &&
                <React.Fragment>
                    <Typography variant={'h5'}><IntlMessages
                        id={"channel:translation:languages"}/></Typography>
                    {missingLanguages &&
                        <Typography key={`missing-${missingLanguages.length}`} className="pb-20 pt-0 align-self-start"
                                    variant={'subtitle1'}>
                            <div className={`${classes.error}`}>
                                <IntlMessages id={"channel:translation:missing"}
                                              values={{languages: missingLanguages.join(", ")}}/>
                            </div>
                        </Typography>}
                    <div key={`${editLanguage}-${language.meta.default}`} className={classes.page}>
                        <div className={editLanguage ? classes.languagesEdit : classes.languages}>
                            {Object.keys(language.translations).map((lang) => {
                                if (!languages[lang]) return <></>

                                return <IChannelLanguageToggleProps
                                    key={lang} name={lang}
                                    title={<div className="d-flex justify-content-start, align-items-center">
                                        <IconButton disabled={!showTranslations.write} color="primary"
                                                    onClick={() => openTranslations(lang)}
                                                    component="span">
                                            <Edit/>
                                        </IconButton>
                                        <span>{languages[lang].name}</span></div>}
                                    toggleDisabled={!showTranslations.write || (language.meta.available && !language.meta.available.includes(lang) && languages[lang].missing) || lang === language.meta.default}
                                    checkBoxDisabled={!showTranslations.write || languages[lang].missing || lang === language.meta.default}
                                    checked={language.meta.available && language.meta.available.includes(lang) || false}
                                    updateDefault={() => updateDefaultLanguage(lang)}
                                    updateAvailable={() => updateAvailableLanguages(lang)}
                                    checkmark
                                    isDefault={lang === language.meta.default}
                                    className={editLanguage ? 'row-6' : 'row-12'}
                                />
                            })}
                        </div>
                        {translationKeys && editLanguage && <div key={editLanguage} className={classes.translations}>
                            <div className={classes.buttons}>
                                <Typography component="div" className="row d-flex align-items-center">
                                    <div className="col-4 p-0"><StyledSwitch
                                        onChange={() => setShowOnlyMissing((prev) => !prev)} checked={showOnlyMissing}/>
                                    </div>
                                    <div className="col-8"><IntlMessages id={"channel:translation:toggle_missing"}/>
                                    </div>
                                </Typography>
                                <div>
                                    <Button className="mr-3" variant="contained" color="primary"
                                            onClick={updateTranslation}><IntlMessages
                                        id={"channel:translation:update"}/></Button>
                                    <Button variant="contained" color="secondary"
                                            onClick={() => setNewTranslations({})}><IntlMessages
                                        id={"channel:translation:discard"}/></Button>
                                </div>
                            </div>
                            {translationKeys.map((key: string, i: number) => (
                                <TranslationsInputField
                                    key={`${i}-${key}-${newTranslations[key]}`}
                                    translationKey={key}
                                    selectedLanguage={language.translations[editLanguage]}
                                    english={TranslationKeys}
                                    value={newTranslations[key]}
                                    updateTranslationField={updateTranslationField}
                                />
                            ))}
                        </div>}
                    </div>
                </React.Fragment>
            }
        </div>
    )
}

export default Translations