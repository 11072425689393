import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {IChannelVariablesState} from 'Store/channel/channelTypes';

interface Props {
    variables: IChannelVariablesState;
}

const useStyles = makeStyles(() => ({
    wrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        height: "80px",
        width: "50%",
    },
    priceOverview: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "15px",
        padding: "0 30px",
        height: "100%",
        fontWeight: "bold",
    },
    flexContainer: {
        display: "flex",
        justifyContent: "center"
    },
}));


const PriceOverview: React.FC<Props> = ({variables}) => {
    const styles = useStyles();

    return (
        <div className={styles.wrapper} style={{
            borderRadius: variables['price:overview::border:radius'],
            background: variables["price:overview::background"],
            color: variables["price:overview::color"]
        }}>
            <div className={styles.priceOverview}>
                <div>Airport Delivery</div>
                <div>Total: 45$</div>
            </div>
        </div>
    )
}

export default PriceOverview