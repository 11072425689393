import Bookings from './bookings';
import Finances from './finances';
import AsyncUserProfile1Component from './users'
import Settings from "./settings";
import BookingDetails from "../Components/BookingDetails";

const routes = [
    {
        path: 'bookings',
        component: Bookings
    },
    {
        path: 'bookings/:id',
        component: BookingDetails
    },
		// temporarily disable finances tab
    // {
    //     path: 'finances',
    //     component: Finances
    // },
    {
        path: 'settings',
        component: Settings
    },
    {
        path: 'users/profile',
        component: AsyncUserProfile1Component
    }
]

export default routes;