import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import 'Assets/scss/reactifyCss';
import App from 'Container/App';
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";

function MainApp() {
    return (
        <React.Fragment>
            <ToastContainer/>
            <Router>
                <Switch>
                    <Route path="/" component={App}/>
                </Switch>
            </Router>
        </React.Fragment>
    )
}

export default MainApp;
