import React, {useState} from 'react';
import {Checkbox, Typography} from "@material-ui/core";
import StyledSwitch from "../../Switch/Switch";
import {makeStyles} from "@material-ui/core/styles";

interface IChannelLanguageToggleProps {
    title: string|JSX.Element;
    name: string;
    checked: boolean;
    updateAvailable: (data: IChannelLanguageToggleState) => void;
    updateDefault: (data: IChannelLanguageToggleState) => void;
    className: string;
    checkmark?: boolean;
    isDefault?: boolean;
    toggleDisabled?: boolean;
    checkBoxDisabled?: boolean;
}

export interface IChannelLanguageToggleState {
    default: boolean;
    [key: string]: boolean;
}

const useStyles = makeStyles((theme) => ({
    title: {
        width: '120px',
    }
}));

export default function IChannelLanguageToggleProps({title, name, checked, updateAvailable, updateDefault, checkmark, isDefault, toggleDisabled, checkBoxDisabled, className}: IChannelLanguageToggleProps) {

    const classes = useStyles();
    const initialFormState: IChannelLanguageToggleState = {[name]: checked, default: isDefault||false};

    const [data, setData] = useState<{ [key: string]: boolean }>(initialFormState);

    const updateSwitch = (event: React.ChangeEvent, data: any) => {
        event.stopPropagation();
        setData(data);
        updateAvailable(data)
    }

    const updateCheckbox = (event: React.ChangeEvent, data: any) => {
        event.stopPropagation();
        setData(data);
        updateDefault(data)
    }

    return (
        <Typography key={name} component="div" className={`row d-flex align-items-center ${className}`}>
            <div className={classes.title}>{title}</div>
            <div>
                <StyledSwitch onChange={updateSwitch} disabled={toggleDisabled||false} checked={checked||false}/>
            </div>
            {checkmark && <div className="col-4 d-flex align-items-center justify-content-start">
                <Checkbox
                    disabled={checkBoxDisabled}
                    checked={data.default||false}
                    onChange={updateCheckbox}
                />
                {isDefault && 'Default'}
            </div>}
        </Typography>
    )
}