import axios, {AxiosRequestConfig, AxiosResponse, Method} from 'axios';
import Authentication from "../Auth/Auth";
import {SET_LOADING} from "../Store/app/appTypes";
import {store} from "../Store/store";
import {PURGE} from "redux-persist";
import {Token} from 'client-oauth2';
import ENV from './environments';
import { ApiCache } from './ApiCache';

const updateLoadingState = (showLoader: boolean) => {
    return store.dispatch({
        type: SET_LOADING,
        payload: showLoader
    });
}

export const requestConfig = (client?: "authenticated" | "passwordRecover") => {
    /**
     * Generate the authentication headers required to communicate with the API
     */

    const auth = client ? Authentication.auth(client) : Authentication.auth()

    return auth.then((token: Token) => ({
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
        }
    }))
}

interface FetchParams {
    path: string,
    method?: Method,
    body?: {[key:string]: any},
    params?: {[key:string]: string},
    headers?: AxiosRequestConfig,
    cache?: boolean
}

const Fetcher = async ({path, method = 'GET', body, params, headers, cache = false}: FetchParams): Promise<AxiosResponse> => {

    const apiCache = new ApiCache();

    if (cache && apiCache.recordExists(`${path}`)) {
        const response= apiCache.get(path);
        return new Promise(resolve => resolve(response));
    }

    axios.interceptors.request.use(
        function (config) {
            updateLoadingState(true);
            return config;
        },
        function (error) {
            updateLoadingState(false);
            return Promise.reject(error);
        })

    axios.interceptors.response.use(
        async (res) => {
            const response = {...res};
            if (response.data.next) {
                const urlParams = new URLSearchParams(response.data.next);
                response.data.offset = parseInt(urlParams.get('offset') || '', 10);
                response.data.previous = parseInt(urlParams.get('previous') || '', 10);
            }

            updateLoadingState(false);
            return response;
        },
        async (error) => {

            if (error.response.data.code === 'permission_denied') {
                window.location.href = '/permission';
                store.dispatch({
                    type: PURGE,
                    key: "root",
                    result: () => {
                        console.log('Store purged!')
                    },
                });
            }
            const status = error.response ? error.response.status : null;

            if (status === 401) {
                // Refreshing token
                return Authentication.refreshToken()
                    .then((token) => {
                        // Retrying request
                        error.config.headers['Authorization'] = 'Bearer ' + token.accessToken;
                        error.config.baseURL = undefined;
                        return axios.request(error.config);
                    })
                    .catch((error) => console.log('Error', error));
            }

            if (error.response.data.code === 'E_PAYMENT_07') {
                return Promise.reject(error);
            }
            updateLoadingState(false);
            return Promise.reject(error);
        });

    const searchParams = new URLSearchParams();
    if (params) {
        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value)) {
                value.map(item => {
                    searchParams.append(key, item);
                })
            } else {
                searchParams.append(key, value);
            }
        }
    }

    const config = headers || await requestConfig();

    return axios(`${ENV.API_URL}/${path}`, {method: method, headers: config.headers, ...(body && {data: body}), params: searchParams})
        .then(async (response) => {
            if (cache) apiCache.set(path, JSON.stringify(response));
            return response;
        })
        .catch(async (error) => Promise.reject(error));
}

export default Fetcher;