import React from 'react';
import {ListItem, ListItemIcon} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import IntlMessages from '../../Util/IntlMessages';
import {IMenuItem} from "../../Store/app/appTypes";

function NavMenuItem({...menu}: IMenuItem) {

    return (
        <ListItem button component="li">
            <NavLink activeClassName="item-active" to={menu.path}>
                <ListItemIcon className="menu-icon">
                    <i id={menu.menu_title} className={menu.menu_icon}/>
                </ListItemIcon>
                <span className="menu">
                    <IntlMessages id={menu.menu_title}/>
                </span>
            </NavLink>
        </ListItem>
    );
}

export default NavMenuItem;
