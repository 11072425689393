import { createReducer } from "@reduxjs/toolkit";
import {
	BookingState,
	CLEAR_BOOKINGS,
	filterInitialState,
	GET_BOOKING_DETAIL,
	GET_TENANT,
	SET_BOOKING_CHANNEL,
	SET_BOOKING_SEARCH,
	SET_BOOKING_STATUS,
	SET_BOOKINGS,
	SET_FILTERS,
	SimpleBooking
} from './bookingsTypes';
import { PURGE } from "redux-persist";

const initialState: BookingState = {
	bookings: [],
	offset: 0,
	count: 0,
	previous: undefined,
	search: '',
	tenants: [],
	detail: undefined,
	filters: filterInitialState,
	bookingChannel: null

}

const booking = createReducer(initialState, {
    [SET_BOOKINGS]: (state, action) => {
        const results = action.payload.bookings;
        let bookings = state.bookings;
        results.forEach((booking: SimpleBooking) => {
            if (!bookings.length || !bookings.find(item => item.code === booking.code)) {
                bookings = bookings.concat(booking)
            }
        })

        return {
            ...state,
            bookings: bookings,
            offset: action.payload.offset || '',
            count: action.payload.count,
            previous: action.payload.previous,
            loading: false
        };
    },
    [SET_BOOKING_SEARCH]: (state, action) => {
        return {
            ...state,
            search: action.payload,
            loading: false
        };
    },
    [SET_BOOKING_STATUS]: (state, action) => {
        const updatedBookings = state.bookings.map(booking =>
            booking.code === action.payload.code
                ? {...booking, status: action.payload.status}
                : booking
        );

        const updatedDetail = state.detail && state.detail.code === action.payload.code
            ? {
                ...state.detail,
                status: action.payload.status,
                assignments: state.detail.assignments.map(assignment =>
                    assignment.status === state.detail?.status
                        ? {...assignment, status: action.payload.status}
                        : assignment
                )
            }
            : state.detail;

        return {
            ...state,
            bookings: updatedBookings,
            detail: updatedDetail
        };
    },
    [GET_TENANT]: (state, action) => {
        const tenant = action.payload;
        let tenants = state.tenants;
        if (!tenants.length || !tenants.find(item => item.id === tenant.id)) {
            tenants = tenants.concat(tenant)
        }
        return {
            ...state,
            tenants,
            loading: false
        };
    },
    [GET_BOOKING_DETAIL]: (state, action) => {
        return {
            ...state,
            detail: action.payload
        };
    },
		[SET_FILTERS]: (state, action) => {
			return {
				...state,
				filters: action.payload
			}
		},
		[SET_BOOKING_CHANNEL]: (state, action) => {
			return {
				...state,
				bookingChannel: action.payload
			}
		},
    [CLEAR_BOOKINGS]: (state) => {
        return {
					...state,
					bookings: [],
				}
    },
    [PURGE]: () => initialState
});

export default booking;