import {createReducer} from "@reduxjs/toolkit";
import {
    CLEAR_FINANCE_FILTER,
    GET_FINANCES,
    GET_ANALYTICS,
    FinanceState
} from "./financeTypes";
import {PURGE} from "redux-persist";

const initialState: FinanceState = {
  finance: undefined,
  analytics: undefined
}

const finances = createReducer(initialState, {
  [GET_FINANCES]: (state, action) => {
    return {
      ...state,
      finance: action.payload
    }
  },
  [CLEAR_FINANCE_FILTER]: (state) => {
    return {
      ...state,
      finance: {
        ...state.finance,
        filter: state.finance?.quarter
      }
    }
  },
  [GET_ANALYTICS]: (state, action) => {
    return {
      ...state,
      analytics: action.payload
    }
  },
  [PURGE]: () => initialState
});

export default finances;