import {Typography} from "@material-ui/core";
import IntlMessages from "../../../Util/IntlMessages";
import React from "react";
import Table, {Data} from "../../Table/Table";
import {Cell, Column} from "react-table";
import {PassengersData} from "../../../Store/bookings/bookingsTypes";

const Passengers = ({passengers}: {passengers: PassengersData[]}): JSX.Element => {

    const data = React.useMemo<Data[]>(() => {
        return passengers.map((passenger) => [
            {
                col1: passenger.first_name,
                col2: passenger.last_name,
                col3: passenger.items,
            }
        ]).flat() as Data[];
    }, [passengers])

    const columns = React.useMemo<Column<Data>[]>(() => ([
        {
            Header: <IntlMessages id={'booking:detail:passenger:first_name'}/>,
            accessor: 'col1',
            Cell: (cell: Cell<[{value: string}]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:passenger:last_name'}/>,
            accessor: 'col2',
            Cell: (cell: Cell<[{value: string}]>) => <div>{cell.value}</div>
        },
        {
            Header: <IntlMessages id={'booking:detail:passenger:luggage_items'}/>,
            accessor: 'col3',
            Cell: (cell: Cell<[{value: string}]>) => <div>{cell.value}</div>
        }
    ]), [])

    return (
        <div>
            <Typography variant={'h6'} className="mb-3"><IntlMessages id={"booking:detail:passenger:passengers"}/></Typography>
            <Table data={data} columns={columns}/>
        </div>
    )
}

export default Passengers;