import Svg from "../../../Svg";
import {Typography} from "@material-ui/core";
import IntlMessages from "../../../../Util/IntlMessages";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {IChannelVariablesState} from "../../../../Store/channel/channelTypes";
import {EditImage} from "../../Tabs/Styling";

interface Props {
    variables: IChannelVariablesState
    images: EditImage
}

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        margin: '0 auto',
        maxWidth: '300px',
        height: 'auto',
        border: '1px solid grey',
        overflow: 'hidden'
    },
    header: {
        height: '30px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover'
    },
    footer: {
        height: '70px'
    },
    headerContent: {
        width: '90%',
        height: '30px',
        display: 'flex',
        margin: '0 auto',
        alignItems: 'center'
    },
    footerContent: {
        width: '90%',
        height: '30px',
        display: 'flex',
        margin: '0 auto',
        textAlign: 'center'
    },
    poweredBy: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '10px'
    },
    footerLogo: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 0 0 5px',
        '& svg': {
            minWidth: '40px',
            maxWidth: '70px',
            minHeight: '15px',
            maxHeight: '35px',
        }
    },
    headerLogo: {
        display: 'flex',
        justifyContent: 'flex-start',
        '& svg': {
            minWidth: '25px',
            maxWidth: '50px',
            minHeight: '10px',
            maxHeight: '25px',
        }
    },
    stepCard: {
        width: '90%',
        boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
        margin: '20px auto',
        padding: '10px'
    },
    inputContainer: {
        paddingTop: '5px',
        width: '200px',
        margin: '0 auto'
    },
    input: {
        height: '25px',
        borderRadius: '3px',
    },
    button: {
        width: '85px',
        borderRadius: '50px',
        margin: '20px auto 0',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const MobilePreview: React.FC<Props> = ({variables, images}) => {

    const classes = useStyles();

    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.header}`} style={{
                ...images.header ?
                    {backgroundImage: `${variables["header:navigation::overlay"]},url(${images.header.display || ''})`} :
                    {background: variables["header:navigation::overlay"]}
            }}>
                <div className={classes.headerContent}>
                    <Svg url={images.mobileLogo.display} className={classes.headerLogo}
                         style={{fill: variables['header:navigation:logo::fill']}}/>
                </div>
            </div>
            <div className={`${classes.stepCard} d-flex flex-column justify-content-between`}
                 style={{background: variables['flow:step::background']}}>
                <div className="column w-100">
                    <Typography style={{fontSize: '16px', paddingBottom: '10px', textAlign: 'center'}}><IntlMessages
                        id={"channel:styling:active_colour"}/></Typography>
                    <div className={classes.inputContainer}>
                        <Typography style={{fontSize: '12px', color: variables['input:label::color']}}><IntlMessages
                            id={"channel:styling:input_example"}/></Typography>
                        <div className={classes.input}
                             style={{border: variables['input::border'], background: variables["input::background"]}}>
                        </div>
                        <Typography style={{fontSize: "8px", color: variables['flow:error::color']}}><IntlMessages
                            id={"channel:styling:error_example"}/></Typography>
                    </div>
                </div>
                <div className={`${classes.button} column`}
                     style={{background: variables['flow:step:submit:button::background']}}>
                    <Typography style={{
                        fontSize: '12px',
                        color: variables['flow:step:submit:button::color'],
                        textAlign: 'center'
                    }}><IntlMessages id={"channel:styling:light_text"}/></Typography>
                </div>
            </div>
            <div className={`${classes.stepCard} d-flex flex-column justify-content-between`}
                 style={{background: variables['flow:step::background']}}>
                <div className="column w-100">
                    <Typography style={{opacity: 0.5, fontSize: '16px', textAlign: 'center'}}><IntlMessages
                        id={"channel:styling:inactive_colour"}/></Typography>
                </div>
                <div className={`${classes.button} column`}
                     style={{background: variables['flow:step:submit:button::background'], opacity: 0.5}}>
                    <Typography style={{
                        fontSize: '12px',
                        color: variables['flow:step:submit:button::color'],
                        textAlign: 'center'
                    }}><IntlMessages id={"channel:styling:light_text"}/></Typography>
                </div>
            </div>
            <div className={`${classes.footer}`}
                 style={{background: variables["footer::background"], color: variables['footer:text::color']}}>
                <div className={classes.footerContent}>
                    <div className={classes.poweredBy}>
                        Powered by
                        <Svg url={images.footerLogo.display} className={classes.footerLogo}
                             style={{fill: variables["footer:logo::color"]}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MobilePreview;