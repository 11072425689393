import {IMenuItem} from "../../Store/app/appTypes";

const sidebarMenu: Array<IMenuItem> = [
		// temporarily disable finances tab
    // {
    //     "menu_title": "sidebar.finances",
    //     "menu_icon": "zmdi zmdi-money",
    //     "path": "/app/finances"
    // },
    {
        "menu_title": "sidebar.bookings",
        "menu_icon": "zmdi zmdi-shopping-cart",
        "path": "/app/bookings"
    },
    {
        "menu_title": "sidebar.settings",
        "menu_icon": "zmdi zmdi-settings",
        "path": "/app/settings"
    }
]

export default sidebarMenu