import React, {useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {Link, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {logoutUser} from "../../Store/auth/AuthActions";
import IntlMessages from "../../Util/IntlMessages";
import RootState from "../../Store/RootState";
import {IUser} from "../../Store/auth/authTypes";

function UserBlock() {

    const [userDropdownMenu, setUserDropdownMenu] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = useSelector<RootState, IUser>(state => state.auth.user || {})

    const onUserLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        dispatch(logoutUser(history));
    }

    const toggleUserDropdownMenu = () => {
        setUserDropdownMenu(!userDropdownMenu);
    }

    return (
        <div className="top-sidebar">
            <div className="sidebar-user-block">
                <Dropdown
                    isOpen={userDropdownMenu}
                    toggle={() => toggleUserDropdownMenu()}
                    className="rct-dropdown"
                >
                    <DropdownToggle
                        tag="div"
                        className="d-flex align-items-center"
                    >
                        <div className="user-profile">
                            <img
                                src={`${process.env.PUBLIC_URL}/assets/svg/user-circle.svg`}
                                alt="user profile"
                                className="img-fluid rounded-circle avatar"
                                width="50"
                                height="100"
                            />
                        </div>
                        <div className="user-info">
                            <span className="user-name ml-4">{user.first_name} {user.last_name}</span>
                            <i className="zmdi zmdi-chevron-down dropdown-icon mx-4"/>
                        </div>
                    </DropdownToggle>
                    <DropdownMenu>
                        <ul className="list-unstyled mb-0">
                            <li className="p-15 border-bottom user-profile-top bg-primary rounded-top">
                                <p className="text-white mb-0 fs-14">{user.first_name} {user.last_name}</p>
                                <span className="text-white fs-14">{user.email}</span>
                            </li>
                            <li>
                                <Link to={{
                                    pathname: '/app/users/profile',
                                    state: {activeTab: 0}
                                }}>
                                    <i className="zmdi zmdi-account text-primary mr-3"/>
                                    <span><IntlMessages id="widgets.profile"/></span>
                                </Link>
                            </li>
                            <li className="border-top">
                                <a href="!#" onClick={(event: React.MouseEvent<HTMLAnchorElement>) => onUserLogout(event)}>
                                    <i id="user-logout" className="zmdi zmdi-power text-danger mr-3"/>
                                    <span><IntlMessages id="widgets.logOut"/></span>
                                </a>
                            </li>
                        </ul>
                    </DropdownMenu>
                </Dropdown>
            </div>
        </div>
    );
}


export default UserBlock;
