import React from "react";
import IntlMessages from "../../Util/IntlMessages"
import PageTitleBar from "../../Components/PageTitleBar/PageTitleBar";
import {RctCard} from "../../Components/RctCard";
import {RouteComponentProps} from "react-router-dom";
import ChannelTabs from "../../Components/ChannelTabs";

export default function Settings({...props}: RouteComponentProps) {
    return (
        <div>
            <PageTitleBar
                icon="ti-shopping-cart"
                title={<IntlMessages id="sidebar.settings"/>}
                match={props.match}
            />
            <RctCard children={<ChannelTabs/>}/>
        </div>
    );
}
