import {combineReducers} from 'redux';
import app from './app/AppReducer';
import auth from './auth/AuthReducer';
import channel from './channel/ChannelReducer';
import booking from './bookings/BookingsReducer';
import finances from './finance/FinancesReducer';

const Reducers = combineReducers({
    app: app,
    auth: auth,
    channel: channel,
    booking: booking,
    finances: finances
});

export default Reducers;
