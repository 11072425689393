import React from 'react'
import styles from './ProgressBar.module.scss'
import { IChannelVariablesState } from 'Store/channel/channelTypes'

interface Props {
	variables: IChannelVariablesState
}

const ProgressBar: React.FC<Props> = ({variables}) => {
	return (
		<div className="d-flex w-50 justify-content-between position-relative">
			<div style={{background: variables['flow:progress:bar:line[submitted]::border']}} className={styles.completedLine} />
			<div style={{background: variables['flow:progress:bar:icon[submitted]::background'], color: "#fff"}} className={styles.circle}>1</div>
			<div style={{background: variables['flow:progress:bar:icon[active]::background'], color: "#fff"}} className={styles.circle}>2</div>

			<div style={{background: variables['flow:progress:bar:line[inactive]::border']}} className={styles.inactiveLine} />
			<div style={{background: variables['flow:progress:bar:icon[inactive]::background']}} className={styles.circle}>3</div>
		</div>
	)
}

export default ProgressBar