import React from 'react';
import {Route, RouteComponentProps, withRouter} from 'react-router-dom';
import routerService from "Routes";
import Dashboard from "../Components/Dashboard/Dashboard";

function DefaultLayout({...props}: RouteComponentProps) {
    return (
        <Dashboard>
            {routerService && routerService.map((route, key) =>
                <Route key={key} path={`${props.match.url}/${route.path}`} component={route.component}/>
            )}
        </Dashboard>
    );
}

export default withRouter(DefaultLayout);
