import {initializeApp} from "firebase/app";
import {getMessaging, getToken, MessagePayload, onMessage, isSupported} from "firebase/messaging";
import ENV from "Util/environments";
import Fetcher from "Util/Fetcher";

export const firebaseConfig = {
    apiKey: ENV.FIREBASE_API_KEY,
    authDomain: ENV.FIREBASE_AUTH_DOMAIN,
    projectId: ENV.FIREBASE_PROJECT_ID,
    storageBucket: ENV.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ENV.FIREBASE_MESSAGING_SENDER_ID,
    appId: ENV.FIREBASE_APP_ID,
    measurementId: ENV.FIREBASE_MEASUREMENT_ID
};

export const vapidKey = ENV.FIREBASE_VAPID_KEY;

const app = initializeApp(firebaseConfig);

export const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(app);
        }
        console.log('Firebase Messaging is not supported on this browser.');
        return null;
    } catch (err) {
        console.error('Error during Firebase Messaging initialization:', err);
        return null;
    }
})();

export const onForegroundMessage = async (): Promise<MessagePayload> => {
    const msg = await messaging;
    return new Promise<MessagePayload>((resolve, reject) => {
        if (msg) {
            onMessage(msg, (payload: MessagePayload) => resolve(payload));
        } else {
            reject("Messaging is not supported in this environment.");
        }
    });
};
export const generateToken = async (): Promise<string | undefined> => {
    try {
        const permission = await Notification.requestPermission();
        if (permission === "denied") return;

        const msg = await messaging;
        if (msg) {
            const token = await getToken(msg, {vapidKey});

            if (!token) return;

            await Fetcher({
                path: 'notification/devices/',
                method: "POST",
                body: {
                    token,
                    source: "web"
                }
            });
        } else {
            console.warn("Messaging is not supported, no token generated.");
            return undefined;
        }
    } catch (error) {
        console.error(error);
    }
};
