import {AppBar, Button, Toolbar} from "@material-ui/core";
import {Link, useHistory} from "react-router-dom";
import AppConfig from "../Constants/AppConfig";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import IntlMessages from "../Util/IntlMessages";

const useStyles = makeStyles((theme) => ({
    content: {
        margin: `${theme.spacing(2)}px 0`,
    }
}));

function MissingPermissionPage() {

    const classes = useStyles();
    const history = useHistory();

    return (
        <div className="rct-session-wrapper">
            <AppBar position="static" className="session-header">
                <Toolbar>
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="session-logo">
                                <Link to="/">
                                    <img src={AppConfig.appLogo} alt="session-logo" className="img-fluid"
                                         width="110" height="35"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="session-inner-wrapper">
                <div className="container">
                    <div className="row row-eq-height">
                        <div className="col-sm-7 col-md-6 col-lg-6 mx-auto">
                            <div className="session-body text-center">
                                <div className="session-head mb-30">
                                    <h2 className="font-weight-bold"><IntlMessages id={"permission:title"}/></h2>
                                </div>
                                <div className={classes.content}>
                                    <IntlMessages id={"permission:message"} values={{link: <a href='mailto:info@bagpoint.com?subject=Missing permission'><IntlMessages id={"permission:text:here"}/></a>}}/>
                                </div>
                                <Button
                                    color="primary"
                                    className="btn-block text-white w-100"
                                    variant="contained"
                                    size="large"
                                    onClick={() => history.push('/login')}
                                >
                                    <IntlMessages id={"permission:button"}/>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MissingPermissionPage;