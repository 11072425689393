import React, {useState} from 'react';
import {Col, Form, FormGroup, Input, Label} from 'reactstrap';
import Button from '@material-ui/core/Button';
import IntlMessages from 'Util/IntlMessages';
import {useDispatch} from "react-redux";
import {toast} from "react-toastify";
import {updateUser} from "../../../Store/auth/AuthActions";
import {IUser} from "../../../Store/auth/authTypes";

interface IProfileProps {
    user: IUser
}

export default function Profile({user}: IProfileProps): JSX.Element {
    const dispatch = useDispatch();
    const [userData, setUserData] = useState(user);

    const onUpdateProfile = async () => {
        if (
            userData.first_name === ''
            || userData.last_name === ''
            || !userData.phone_number
        ) {
            toast.error(<IntlMessages id={"profile:missing_fields"}/>);
            return;
        }
        await dispatch(updateUser(userData));
    }

    return (
        <div className="profile-wrapper w-60">
            <h2 className="heading"><IntlMessages id="profile:personal_details"/></h2>
            {userData && <Form>
                <FormGroup row>
                    <Label for="firstName" sm={3}><IntlMessages id="profile:first_name"/></Label>
                    <Col sm={9}>
                        <Input type="text" onChange={(e => setUserData((prev) => ({...prev, first_name: e.target.value})))} value={userData.first_name} name="firstName" id="firstName"
                               className="input-lg"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="lastName" sm={3}><IntlMessages id="profile:last_name"/></Label>
                    <Col sm={9}>
                        <Input type="text" onChange={e => setUserData((prev) => ({...prev, last_name: e.target.value}))} value={userData.last_name} name="lastName" id="lastName" className="input-lg"/>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="company" sm={3}><IntlMessages id="profile:company_name"/></Label>
                    <Col sm={9}>
                        <Input type="text" disabled={true} name="company" id="company" className="input-lg mb-20"/>
                        <div className="help-text d-flex p-10">
                            <i className="ti-info-alt mr-15 pt-5"/>
                            {/*<IntlMessages id={"profile:company_explanation"}/>*/}
                        </div>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Label for="telephone" sm={3}><IntlMessages id="profile:phone_nr"/></Label>
                    <Col sm={9}>
                        <Input type="tel" onChange={e => setUserData((prev) => ({...prev, phone_number: e.target.value}))} value={userData.phone_number} name="telephone" id="telephone" className="input-lg"/>
                    </Col>
                </FormGroup>
            </Form>}
            {/*<hr/>*/}
            {/*<h2 className="heading"><IntlMessages id="profile:address"/></h2>*/}
            {/*{userData && <Form>*/}
            {/*    <FormGroup row>*/}
            {/*        <Label for="address" sm={3}><IntlMessages id="profile:address"/></Label>*/}
            {/*        <Col sm={9}>*/}
            {/*            <Input type="text" name="address" id="address" className="input-lg"/>*/}
            {/*        </Col>*/}
            {/*    </FormGroup>*/}
            {/*    <FormGroup row>*/}
            {/*        <Label for="city" sm={3}><IntlMessages id="profile:city"/></Label>*/}
            {/*        <Col sm={9}>*/}
            {/*            <Input type="text" name="city" id="city" className="input-lg"/>*/}
            {/*        </Col>*/}
            {/*    </FormGroup>*/}
            {/*    <FormGroup row>*/}
            {/*        <Label for="state" sm={3}><IntlMessages id="profile:state"/></Label>*/}
            {/*        <Col sm={9}>*/}
            {/*            <Input type="text" name="state" id="state" className="input-lg"/>*/}
            {/*        </Col>*/}
            {/*    </FormGroup>*/}
            {/*    <FormGroup row>*/}
            {/*        <Label for="zip" sm={3}><IntlMessages id="profile:zip_code"/></Label>*/}
            {/*        <Col sm={9}>*/}
            {/*            <Input type="text" name="zip" id="zip" className="input-lg"/>*/}
            {/*        </Col>*/}
            {/*    </FormGroup>*/}
            {/*</Form>}*/}
            <Button variant="contained" color="primary" className="text-white"
                    onClick={() => onUpdateProfile()}><IntlMessages id="profile:update_profile"/></Button>
        </div>
    );
}
