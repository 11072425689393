import {createReducer} from "@reduxjs/toolkit";
import {
    GET_USER,
    LOGIN_USER,
    LOGOUT_USER,
    UPDATE_USER,
    SET_SCOPES
} from './authTypes';
import {PURGE} from "redux-persist";

const initialState = {
    user: null,
    loading: false,
    scopes: []
};

const auth = createReducer(initialState, {
    [LOGIN_USER]: (state, action) => {
        return {...state, loading: true, user: action.payload}
    },
    [GET_USER]: (state, action) => {
        return {...state, loading: true, user: action.payload};
    },
    [UPDATE_USER]: (state, action) => {
        return {...state, loading: true, user: action.payload};
    },
    [LOGOUT_USER]: (state, action) => {
        return {...state, user: null};
    },
    [SET_SCOPES]: (state, action) => {
        return {...state, scopes: action.payload};
    },
    [PURGE]: () => initialState
});

export default auth;