import {COLLAPSED_SIDEBAR, SET_LOADING} from './appTypes';
import {Dispatch} from "redux";

export const collapsedSidebarAction = (isCollapsed: boolean) => (dispatch: Dispatch): void => {
    dispatch({
        type: COLLAPSED_SIDEBAR,
        payload: isCollapsed
    })
};

export const setLoading = (loading: boolean) => (dispatch: Dispatch): void => {
    dispatch({
        type: SET_LOADING,
        payload: loading
    })
};