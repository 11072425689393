import React, {useState} from 'react';
import {Typography} from "@material-ui/core";
import {
    BoundariesData,
    BoundaryOptions,
    defaultLogistics, ChannelData,
    LogisticsData,
    OffsetOptions, OffsetsData, Permission, Service,
    TimeFrame, WindowsData,
} from "../../../Store/channel/channelTypes";
import Button from "@material-ui/core/Button";
import IntlMessages from "../../../Util/IntlMessages";
import {makeStyles} from "@material-ui/core/styles";
import Boundary from "../Components/Boundary";
import Offset from "../Components/Offset";
import Windows from "../Components/Windows";
import {updateChannelDetails} from "../../../Store/channel/ChannelActions";
import {useDispatch, useSelector} from "react-redux";
import {mergeDeepLogistics} from "../../../Util/utils";
import RootState from "../../../Store/RootState";

interface ILogisticsProps {
    showLogistics: Permission
}

const useStyles = makeStyles((theme) => ({
    page: {
        display: 'flex',
        flexDirection: 'column'
    },
    container: {
        display: 'flex',
        [theme.breakpoints.down("xs")]: {
            flexWrap: 'wrap'
        },
    },
    column: {
        width: "50%",
        display: 'flex',
        justifyContent: "space-between",
        flexFlow: "column",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        },
    },
    buttons: {
        width: '100%',
        textAlign: 'end',
        paddingTop: theme.spacing(3),
        [theme.breakpoints.down("xs")]: {
            textAlign: 'center'
        }
    }
}));

const Logistics = ({showLogistics}: ILogisticsProps) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const channel = useSelector<RootState, Required<ChannelData>>(state => state.channel.selected as Required<ChannelData>);
    const {logistics}: {logistics: Required<LogisticsData>} = channel.properties;

    const initialState = {
        boundaries: mergeDeepLogistics<BoundariesData>(defaultLogistics.boundaries, logistics?.boundaries),
        offsets: mergeDeepLogistics<OffsetsData>(defaultLogistics.offsets, logistics?.offsets),
        windows: mergeDeepLogistics<WindowsData>(defaultLogistics.windows, logistics?.windows)
    };

    const [data, setData] = useState<LogisticsData>(initialState);

    const updateData = (key: string, nestedKey: string, updatedData: Partial<BoundaryOptions|OffsetOptions|TimeFrame[]>, isArray = false) => {
        const updated = {
            ...data,
            [key]: {
                ...data[key],
                ...isArray ? {[nestedKey]: updatedData} : {
                    [nestedKey]: {
                        ...data[key][nestedKey],
                        ...updatedData
                    }
                }
            }
        } as LogisticsData;
        setData(updated);
    }

    const saveProperties = () => {
        dispatch(updateChannelDetails(channel, {logistics: data}))
    }
    
    return (
        <div className={classes.page}>
            <div className={classes.container}>
                {showLogistics.read && <React.Fragment>
                    <div className={classes.column}>
                    <Typography variant={'h5'}><IntlMessages id={"channel:logistics:city"}/></Typography>
                    <div className="p-3">
                        <Typography variant={'h6'}><IntlMessages id={"channel:logistics:boundaries"}/></Typography>
                        <Boundary disabled={!showLogistics.write} boundary={data.boundaries.city.today} onChange={(data: TimeFrame) => updateData('boundaries', Service.CITY, {today: data})}/>
                    </div>
                    <div className="p-3">
                        <Typography variant={'h6'}><IntlMessages id={"channel:logistics:offsets"}/></Typography>
                        <Offset disabled={!showLogistics.write} offsets={data.offsets.city.pickup} service={Service.CITY}
                                onChange={(data: { minutes: number }) => updateData('offsets', Service.CITY, {pickup: data})}/>
                    </div>
                    <div className="p-3">
                        <Typography variant={'h6'}><IntlMessages id={"channel:logistics:windows"}/></Typography>
                        <Windows disabled={!showLogistics.write} service={Service.CITY} windows={data.windows.city} onChange={(data: TimeFrame[]) => updateData('windows', Service.CITY, data, true)}/>
                    </div>
                </div>
                    <div className={`${classes.column} justify-space-between`}>
                    <Typography variant={'h5'}><IntlMessages id={"channel:logistics:airport"}/></Typography>
                    <div className="p-3">
                        <Typography variant={'h6'}><IntlMessages id={"channel:logistics:boundaries"}/></Typography>
                        <Boundary disabled={!showLogistics.write} boundary={data.boundaries.airport.today} onChange={(data: TimeFrame) => updateData('boundaries', Service.AIRPORT, {today: data})}/>
                    </div>
                    <div className="p-3">
                        <Typography variant={'h6'}><IntlMessages id={"channel:logistics:offsets"}/></Typography>
                        <Offset disabled={!showLogistics.write} offsets={data.offsets.airport.delivery}
                                service={Service.AIRPORT}
                                onChange={(data: { minutes: number }) => updateData('offsets', Service.AIRPORT, {delivery: data})}/>
                    </div>
                    <div className="p-3">
                        <Typography variant={'h6'}><IntlMessages id={"channel:logistics:windows"}/></Typography>
                        <Windows disabled={!showLogistics.write} service={Service.AIRPORT} windows={data.windows.airport} onChange={(data: TimeFrame[]) => updateData('windows', Service.AIRPORT, data, true)}/>
                    </div>
                </div>
                </React.Fragment>}
            </div>
            <div className={classes.buttons}>
                <Button color="primary" variant="contained" onClick={saveProperties}><IntlMessages
                    id={"channel:general:update"}/></Button>
            </div>
        </div>

    )
}

export default Logistics;