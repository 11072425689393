import React from 'react';
import {RctCardHeading} from './RctCardHeading'

interface IRctCardProps {
    children: JSX.Element;
    customClasses?: string;
    heading?: string;
    headingCustomClasses?: string;
    colClasses?: string;
}

const RctCard = ({children, customClasses, heading, headingCustomClasses, colClasses}: IRctCardProps) => (
    <div className={colClasses && colClasses}>
        <div className={`rct-block ${customClasses ? customClasses : ''}`}>
            {heading &&
            <RctCardHeading
                title={heading}
                customClasses={headingCustomClasses || ''}
            />
            }
            {children}
        </div>
    </div>
);

export {RctCard};
