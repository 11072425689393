import {createReducer} from "@reduxjs/toolkit";
import {IChannelState, SET_CHANNEL, SET_CHANNELS, UPDATE_CHANNEL} from './channelTypes';
import {PURGE} from "redux-persist";

const initialState: IChannelState = {
  selected: undefined,
  available: []
}

const channel = createReducer(initialState, {
  [SET_CHANNEL]: (state, action) => {
    return {
      ...state,
      selected: action.payload,
      loading: false
    };
  },
  [SET_CHANNELS]: (state, action) => {
    return {
      ...state,
      available: action.payload,
      ...(action.payload.length === 1 && {selected: action.payload[0]}),
      loading: false
    };
  },
  [UPDATE_CHANNEL]: (state, action) => {
    return {
      ...state,
      selected: action.payload
    };
  },
  [PURGE]: () => initialState
});

export default channel;