import {isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input'

export default function useValidation() {

  function validateEmail(email: string): string | undefined {
    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return;
    if (email.trim() === "") return "validation:input:email_required";

    return "validation:input:email_invalid";
  }

  function validateFirstName(name: string): string | undefined {
    if (name.trim() === "") return "validation:input:firstname_required";
    if (/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-\s]*$/.test(name)) return;

    return "validation:input:firstname_invalid";
  }

  function validateLastName(name: string): string | undefined {
    if (name.trim() === "") return "validation:input:lastname_required";
    if (/^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ\-\s]*$/.test(name)) return;

    return "validation:input:lastname_invalid";
  }

  function validatePhoneNumber(phoneNumber: string, required: boolean): string | undefined {
    if (required && !phoneNumber) return "validation:input:phone_required";
    if (phoneNumber && (!isPossiblePhoneNumber(phoneNumber) || !isValidPhoneNumber(phoneNumber))) return "validation:input:phone_invalid";
  }

  function validatePassword(password: string, confirmPassword?: string): string | undefined {
    if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password)) return;
    if (password.trim() === "") return "validation:input:password_required";
    if (confirmPassword && password !== confirmPassword) return "validation:input:password_no_match";
    if (password.length < 8) return "validation:input:password_short";
    if (!/[a-zA-Z]/.test(password)) return "validation:input:password_no_letters";
    if (!/\d/.test(password)) return "validation:input:password_no_digit";
  }

  function validateStreet(street: string): string | undefined {
    if (street.length > 300) return "validation:input:street_too_long";
  }

  function validateNumber(number: string): string | undefined {
    if (number.length > 20) return "validation:input:number_too_long";
  }

  function validateAddition(addition: string | number): string | undefined {
    if (typeof addition === 'string' && addition.length > 20) return "validation:input:addition_too_long";
    if (typeof addition === 'number' && addition.toString(10).length > 20) return "validation:input:addition_too_long";
  }

  function validatePostalCode(postalCode: string): string | undefined {
    if (postalCode.length > 20) return "validation:input:postalCode_too_long";
  }

  function validateCity(city: string): string | undefined {
    if (city.length > 200) return "validation:input:city_too_long";
    if (city.trim() === "") return "validation:input:city_required";
  }

  function validateCountry(country: string): string | undefined {
    if (country.length > 100) return "validation:input:country_too_long";
    if (country.trim() === "") return "validation:input:country_required";
  }

  return {
    validateEmail,
    validateFirstName,
    validateLastName,
    validatePhoneNumber,
    validatePassword,
    validateStreet,
    validateNumber,
    validateAddition,
    validatePostalCode,
    validateCity,
    validateCountry
  };
}