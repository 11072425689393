import Svg from "../../../Svg";
import {Typography} from "@material-ui/core";
import IntlMessages from "../../../../Util/IntlMessages";
import {makeStyles} from "@material-ui/core/styles";
import {IChannelVariablesState} from "../../../../Store/channel/channelTypes";
import {EditImage} from "../../Tabs/Styling";

interface PreviewProps {
    variables: IChannelVariablesState
    images: EditImage
}

const useStyles = makeStyles(() => ({
    container: {
        width: '100%',
        margin: '0 auto',
        maxWidth: '500px',
        height: 'auto',
        border: '1px solid grey',
        overflow: 'hidden'
    },
    header: {
        position: 'relative',
        height: '100px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundSize: 'cover',
        padding: '5px 10% 0'
    },
    heading: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },
    footer: {
        height: '100px'
    },
    headerContent: {
        height: '20px',
        display: 'flex',
        margin: '0 auto'
    },
    footerContent: {
        width: '80%',
        height: '30px',
        display: 'flex',
        margin: '0 auto'
    },
    poweredBy: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '8px'
    },
    footerLogo: {
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 0 0 5px',
        '& svg': {
            minWidth: '40px',
            maxWidth: '70px',
            minHeight: '15px',
            maxHeight: '35px',
        }
    },
    headerLogo: {
        display: 'flex',
        justifyContent: 'flex-start',
        '& svg': {
            maxWidth: '70px',
        }
    },
    stepCard: {
        width: '80%',
        height: '100px',
        boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.05)',
        borderRadius: '10px',
        position: 'relative',
        margin: '10px auto',
        padding: '10px'
    },
    inputContainer: {
        paddingTop: '5px'
    },
    input: {
        height: '15px',
        borderRadius: '3px',
        width: '100px'
    },
    button: {
        width: '55px',
        borderRadius: '50px',
        margin: '0 0 0 auto',
        padding: '2px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

const DesktopPreview: React.FC<PreviewProps> = ({variables, images}) => {

    const classes = useStyles();

    const headerHeightPixels = Math.abs(Number(variables['header::height'].replace(/[^0-9]/g, '')) / 4);
    const headingInPixels = ((Math.abs(Number(variables['h1::size'].replace(/[^0-9]/g, ''))) / 100) * window.innerWidth) / 4;

    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.header}`} style={{
                height: `${headerHeightPixels}px`,
                ...images.header ?
                    {backgroundImage: `${variables["header:navigation::overlay"]},url(${images.header.display || ''})`} :
                    {background: variables["header:navigation::overlay"]}
            }}>
                <div className={classes.headerContent}>
                    <Svg
                        url={images.desktopLogo.display}
                        className={classes.headerLogo}
                        style={{fill: variables['header:navigation:logo::fill']}}
                    />
                </div>
                <Typography
                    className={classes.heading}
                    style={{fontSize: `${headingInPixels}px`, color: variables['h1::color']}}
                >
                    <IntlMessages id={"channel:styling:heading"}/>
                </Typography>
            </div>
            <div className={`${classes.stepCard} d-flex flex-column justify-content-between`}
                 style={{background: variables['flow:step::background']}}>
                <div className="column w-100">
                    <Typography style={{fontSize: '12px', color: variables['flow:step:heading::color']}}><IntlMessages
                        id={"channel:styling:active_colour"}/></Typography>
                    <div className={classes.inputContainer}>
                        <Typography style={{fontSize: '8px', color: variables['input:label::color']}}><IntlMessages
                            id={"channel:styling:input_example"}/></Typography>
                        <div className={classes.input}
                             style={{border: variables['input::border'], background: variables["input:background"]}}/>
                        <Typography style={{fontSize: "6px", color: variables['flow:error::color']}}><IntlMessages
                            id={"channel:styling:error_example"}/></Typography>
                    </div>
                </div>
                <div className={`${classes.button} column`}
                     style={{background: variables['flow:step:submit:button::background']}}>
                    <Typography style={{
                        fontSize: '8px',
                        color: variables['flow:step:submit:button::color'],
                        background: variables['flow:step:submit:button::background']
                    }}><IntlMessages id={"channel:styling:light_text"}/></Typography>
                </div>
            </div>
            <div className={`${classes.stepCard} d-flex flex-column justify-content-between`}
                 style={{background: variables['flow:step::background']}}>
                <div className="column w-100">
                    <Typography style={{opacity: 0.5, fontSize: '12px'}}><IntlMessages
                        id={"channel:styling:inactive_colour"}/></Typography>
                </div>
                <div className={`${classes.button} column`}
                     style={{background: variables['flow:step:submit:button::background'], opacity: 0.5}}>
                    <Typography
                        style={{fontSize: '8px', color: variables['flow:step:submit:button::color']}}><IntlMessages
                        id={"channel:styling:light_text"}/></Typography>
                </div>
            </div>
            <div className={`${classes.footer}`}
                 style={{background: variables["footer::background"], color: variables['footer:text::color']}}>
                <div className={classes.footerContent}>
                    <div className={classes.poweredBy}>
                        Powered by
                        <Svg url={images.footerLogo.display} className={classes.footerLogo}
                             style={{fill: variables["footer:logo::color"]}}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DesktopPreview;