import React, {useEffect, useLayoutEffect, useMemo, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IntlMessages from "../../Util/IntlMessages";
import General from "./Tabs/General";
import Styling from "./Tabs/Styling";
import Translations from "./Tabs/Translations";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../Store/RootState";
import {ChannelData} from "../../Store/channel/channelTypes";
import {getChannelDetails} from "../../Store/channel/ChannelActions";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import Logistics from "./Tabs/Logistics";
import {makeStyles} from "@material-ui/core/styles";
import {getReadWritePermission} from "../../Util/utils";

interface ITabPanelProps {
    value: number;
    index: number;
    children: JSX.Element;
}

interface TabState {
    [key: string]: {
        label: React.ReactElement,
        icon: React.ReactElement,
        disabled:boolean
    }
}

const useStyles = makeStyles(() => ({
    tabs: {
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.2)',
        },
    },
}));

function TabPanel({value, index, children}: ITabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <React.Fragment>
                    <Box p={3}>
                        {children}
                    </Box>
                </React.Fragment>
            )}
        </div>
    );
}

const ChannelTabs = (): JSX.Element => {

    const classes = useStyles();

    const tabsState: TabState = {
        'general': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-tune"/>,
            label: <IntlMessages id={'channel:tab:general'}/>,
            disabled: true,
        },
        'style': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-format-color-fill"/>,
            label: <IntlMessages id={'channel:tab:style'}/>,
            disabled: true,
        },
        'translations': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-translate"/>,
            label: <IntlMessages id={'channel:tab:translations'}/>,
            disabled: true,
        },
        'logistics': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-car"/>,
            label: <IntlMessages id={'channel:tab:logistics'}/>,
            disabled: true,
        }
    }

    const dispatch = useDispatch();

    const channel = useSelector<RootState, ChannelData>(state => state.channel.selected as Required<ChannelData>);
    const loading = useSelector<RootState, boolean>(state => state.app.loading);
    const authScopes = useSelector<RootState, Required<string[]>>(state => state.auth.scopes);

    const [activeIndex, setActiveIndex] = useState(0);
    const [tabs, setTabs] = useState<TabState>(tabsState);
    const channelSpecificScopes = useMemo(() => authScopes.filter(item => item.includes(channel.name.toLowerCase())), [channel]);

    const scopes = useMemo(() => {
        const paymentScopes = getReadWritePermission(channelSpecificScopes.filter(item => item.includes('payment')));
        const switchesScopes = getReadWritePermission(channelSpecificScopes.filter(item => item.includes('switches')));
        const whitelabelScopes = getReadWritePermission(channelSpecificScopes.filter(item => item.includes('whitelabel')));
        const translationScopes = getReadWritePermission(channelSpecificScopes.filter(item => item.includes('language')));
        const logisticsScopes = getReadWritePermission(channelSpecificScopes.filter(item => item.includes('logistics')));

        return {
            payment: paymentScopes,
            switches: switchesScopes,
            whitelabel: whitelabelScopes,
            translation: translationScopes,
            logistics: logisticsScopes
        }
    }, [channelSpecificScopes])

    useLayoutEffect(() => {
        if (channel && channel.id) dispatch(getChannelDetails(channel.id))
    }, [channel?.id])

    const handleChange = (value: number) => {
        setActiveIndex(value);
    }

    useLayoutEffect(() => {
        const updatedTabs = {
            general: {
                ...tabsState.general,
                disabled: !scopes.payment.read && !scopes.switches.read
            },
            style: {
                ...tabsState.style,
                disabled: !scopes.whitelabel.read
            },
            translations: {
                ...tabsState.translations,
                disabled: !scopes.translation.read
            },
            logistics: {
                ...tabsState.logistics,
                disabled: !scopes.logistics.read
            }
        };

        setTabs(() => updatedTabs);
    }, [scopes])

    useEffect(() => {
        const index = Object.entries(tabs).findIndex(([key, value]) => !value.disabled);
        if (index >= 0) setActiveIndex(() => index);
    }, [tabs])

    return (
        <React.Fragment>
            <AppBar position="static" color={'transparent'} key={channelSpecificScopes.length}>
                <Tabs
                    value={activeIndex}
                    onChange={(e, value) => handleChange(value)}
                    variant="fullWidth"
                    textColor="primary"
                    indicatorColor="primary">
                    {Object.entries(tabs).map(([key, value], index) => (
                        <Tab key={`${index}-${key}-${value.disabled}`} className={classes.tabs} disabled={value.disabled} icon={value.icon} label={value.label}/>
                    ))}
                </Tabs>
            </AppBar>
            {!loading && channel && channel.properties ? <React.Fragment>
                <TabPanel value={activeIndex} index={0} children={<General showPayments={scopes.payment} showSwitches={scopes.switches}/>}/>
                <TabPanel value={activeIndex} index={1} children={<Styling showWhitelabel={scopes.whitelabel}/>}/>
                <TabPanel value={activeIndex} index={2} children={<Translations showTranslations={scopes.translation} />}/>
                <TabPanel value={activeIndex} index={3} children={<Logistics showLogistics={scopes.logistics}/>}/>
            </React.Fragment> : <CustomSpinner/>}

        </React.Fragment>
    );
}

export default ChannelTabs;