import React, {useEffect, useState} from 'react';
import {Typography} from "@material-ui/core";
import StyledSwitch from "../../Switch/Switch";

interface IChannelPropertyToggleProps {
    title: string|JSX.Element;
    name: string;
    checked: boolean;
    update: (data: ChannelToggleState) => void;
    checkmark?: boolean;
    isDefault?: boolean;
    disabled?: boolean;
}

export interface ChannelToggleState {
    [key: string]: boolean;
}

const ChannelPropertyToggle = ({title, name, checked, update, disabled}: IChannelPropertyToggleProps): JSX.Element => {

    const initialFormState: ChannelToggleState = {[name]: checked};

    const [data, setData] = useState<{[key:string]: boolean}>(initialFormState);

    const updateSwitch = (event: React.ChangeEvent, checked: boolean) => {
        setData((prev) => ({...prev, [name]: checked}));
    }

    useEffect(() => {
        if ((data.default && data.default !== initialFormState.default) || (data[name] !== initialFormState[name])) update(data);
    }, [data[name], data.default])

    return (
        <Typography component="div" className="row d-flex align-items-center">
            <div className={'col-8'}>{title}</div>
            <div className={'col-4'}><StyledSwitch onChange={updateSwitch} disabled={disabled} checked={checked}/></div>
        </Typography>
    )
}

export default ChannelPropertyToggle;