export const COLLAPSED_SIDEBAR = 'COLLAPSED_SIDEBAR';
export const SET_LOADING = "SET_LOADING";

export interface IAppState {
    activeTheme: ITheme;
    languages: Array<ILocale>;
    locale: ILocale;
    navCollapsed: boolean;
    rtlLayout: boolean;
    sidebarMenus: Array<IMenuItem>;
    loading: boolean;
}

export interface ITheme {
    id: number;
    name: string;
}

export interface ILocale {
    languageId: string;
    locale: string;
    name: string;
    icon: string;
}

export interface IMenuItem {
    menu_title: string;
    menu_icon: string;
    path: string;
}