import {InputLabel, TextField} from "@material-ui/core";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";

interface TranslationsInputFieldProps {
    translationKey: string;
    selectedLanguage: {[key: string]: string};
    english: {[key: string]: string};
    value?: string;
    updateTranslationField: (value: {[key: string]: string}) => void;
}

const useStyles = makeStyles((theme) => ({
    tooltipWrapper: {
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',
        width: '100%'
    },
    tooltipTip: {
        position: 'absolute',
        borderRadius: '4px',
        padding: '6px',
        color: 'white',
        background: theme.palette.primary.main,
        fontSize: '14px',
        fontFamily: 'sans-serif',
        lineHeight: 1.1,
        zIndex: 100,
        bottom: '-10px',
        width: 'auto',
        maxWidth: '90%',
        '&::before': {
            content: '""',
            left: '50%',
            border: 'solid transparent',
            height: 0,
            width: 0,
            position: 'absolute',
            pointerEvents: 'none',
            borderWidth: '6px',
            marginLeft: '-6px',
            background: 'none'
        }
    },
}));

const TranslationsInputField = ({translationKey, selectedLanguage, english, value, updateTranslationField}: TranslationsInputFieldProps) => {

    const initialFormState = {[translationKey]: value || selectedLanguage[translationKey] || ''};
    const classes = useStyles();

    const [data, setData] = useState<{[key:string]: string}>(initialFormState);
    const [infoWindow, setInfoWindow] = useState<string|null>(null)

    const updateInput = (event: React.ChangeEvent) => {
        const target = event.target as HTMLInputElement;
        setData((prev) => ({...prev, [target.name]: target.value}));
    }

    const onFieldFocus = () => {
        setInfoWindow(() => english[translationKey]);
    }

    const onFieldBlur = () => {
        setInfoWindow(() => null);
        if (data[translationKey].length && data[translationKey] !== selectedLanguage[translationKey]) updateTranslationField(data);
    }

    return (
        <form key={translationKey} className="d-flex justify-content-start, align-items-center">
            <InputLabel className="col-4" style={{wordBreak: 'break-all'}}>{translationKey}</InputLabel>
            <div className="col-8 pr-0">
                {infoWindow && <div className={classes.tooltipWrapper}>
                    <div className={classes.tooltipTip}>
                        {infoWindow}
                    </div>
                </div>}
                <TextField
                    multiline
                    id="translation input"
                    label={""}
                    name={translationKey}
                    value={data[translationKey]}
                    placeholder={english[translationKey]}
                    onChange={updateInput}
                    margin="normal"
                    variant="outlined"
                    error={!selectedLanguage[translationKey]}
                    onBlur={onFieldBlur}
                    onFocus={onFieldFocus}
                />
            </div>
        </form>
    )
}

export default TranslationsInputField;