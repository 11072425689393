import {
    GeneralFormInterface,
    ChannelData,
    SET_CHANNEL,
    SET_CHANNELS,
    UPDATE_CHANNEL
} from './channelTypes';
import Fetcher, {requestConfig} from "../../Util/Fetcher";
import {Dispatch} from "redux";
import {toast} from "react-toastify";
import {AxiosResponse} from "axios";

export const setChannel = (channel: ChannelData) => async (dispatch: Dispatch) => {
    dispatch({
        type: SET_CHANNEL,
        payload: channel,
    });
};

export const getChannels = () => async (dispatch: Dispatch) => {
    try {
        const {data} = await Fetcher({path: `channels/`});
        dispatch({
            type: SET_CHANNELS,
            payload: data.results,
        });
    } catch (error) {
        console.error('Error while getting channels ', error);
    }
};

export const getChannelDetails = (id: number) => async (dispatch: Dispatch) => {
    try {
        const {data} = await Fetcher({path: `channels/${id}/`});
        dispatch({
            type: SET_CHANNEL,
            payload: data,
        });
    } catch (error) {
        console.error('Error while getting channel ', error);
    }
};

export const updateChannelDetails = (channel: ChannelData, properties: Partial<GeneralFormInterface>) => async (dispatch: Dispatch) => {
    try {
        const {data} = await Fetcher({path: `channels/${channel.id}/properties/`, method: 'PATCH', body: {...properties}});
        toast.success('Update successful!');
        dispatch({
            type: UPDATE_CHANNEL,
            payload: {
                ...channel,
                properties: data
            },
        });
    } catch (error) {
        toast.error('Something went wrong while trying to update the channel.');
    }
};

export async function uploadImage(channel: ChannelData, body: FormData): Promise<AxiosResponse<{type: string, url: string}>> {
    const header = await requestConfig();
    const updateHeader = {
        headers: {
            ...header.headers,
            'content-type': "multipart/form-data; charset=UTF-8; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
        }
    }

    return await Fetcher({path: `channels/${channel.id}/images/`, method: 'POST', body, headers: updateHeader})
}