import React from "react";

const CustomSpinner = () => {

    return (
        <div className='spinner-component spinner-container'>
            <div className='spinner'>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
                <div className='spinner-dot'/>
            </div>
        </div>
    )
}

export default CustomSpinner;