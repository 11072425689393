import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {TimeFrame} from "../../../Store/channel/channelTypes";
import IntlMessages from "../../../Util/IntlMessages";

interface BoundaryProps {
    boundary: TimeFrame;
    disabled: boolean;
    onChange: (boundary: TimeFrame) => void;
}

const useStyles = makeStyles(() => ({
    inputField: {
        border: '1px solid #ccc',
        padding: '0 6px',
        margin: '0 3px 0 6px',
        color: '#5D92F4'
    },
    selectField: {
        border: '1px solid #ccc',
        margin: '0 6px 0 3px',
        color: '#5D92F4'
    }
}));

const Boundary = ({boundary, disabled, onChange}: BoundaryProps): JSX.Element => {
    /**
     * Setting how close and far to the flight the user can book the services.
     */

    const classes = useStyles();
    const timeOptions = ['hour', 'day', 'week', 'month', 'year'];
    const [data, setData] = useState(boundary);
    const pickupWidth = useMemo(() => boundary.from[Object.keys(boundary.from)[0]].toString().length + 4, [boundary.from]);
    const deliveryWidth = useMemo(() => boundary.to[Object.keys(boundary.to)[0]].toString().length + 4, [boundary.to]);

    const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, type: string) => {
        const value = e.target.value;
        if (!value) return;
        setData((prev) => ({
            ...prev,
            [type]: {
                [Object.keys(data[type])[0]]: parseInt(value, 10)
            }
        }));
    };

    const handleSelect = (option: React.ChangeEvent<HTMLSelectElement>, type: string) => {
        /**
         * Changing boundary timeframe between
         * 'hour', 'day', 'week', 'month', 'year'
         */
        const value = data[type][Object.keys(data[type])[0]];
        const updated = {
            [type]: {
                [option.target.value]: value
            }
        }
        setData((prev) => ({
            ...prev,
            ...updated
        }))
    }

    useEffect(() => {
        /**
         * Passing the updated boundaries to parent component if modifications happened
         */
        if (JSON.stringify(data) !== JSON.stringify(boundary)) {
            onChange(data);
        }
    }, [data])

    return (
        <p>
            <IntlMessages id={"channel:logistics:boundary:from"}/>
            <input disabled={disabled} min="1" className={classes.inputField} style={{width: pickupWidth + 'ch'}}
                   defaultValue={data.from[Object.keys(data.from)[0]]} type="number" autoFocus
                   onChange={(e) => handleFieldChange(e, 'from')}/>
            <select disabled={disabled} id="timeframe" className={classes.selectField}
                    defaultValue={Object.keys(data.from)[0]} onChange={(opt) => handleSelect(opt, 'from')}>{
                timeOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))
            }
            </select>
            <IntlMessages id={"channel:logistics:boundary:to"}/>
            <input disabled={disabled} min="1" className={classes.inputField} style={{width: deliveryWidth + 'ch'}}
                   defaultValue={data.to[Object.keys(data.to)[0]]} type="number" autoFocus
                   onChange={(e) => handleFieldChange(e, 'to')}/>
            <select disabled={disabled} id="timeframe" className={classes.selectField}
                    defaultValue={Object.keys(data.to)[0]} onChange={(opt) => handleSelect(opt, 'to')}>{
                timeOptions.map((option, index) => (
                    <option key={index} value={option}>{option}</option>
                ))
            }
            </select>
        </p>
    )
}

export default Boundary