import {Typography} from "@material-ui/core";
import IntlMessages from "../../../Util/IntlMessages";
import React from "react";
import {Contact} from "../../../Store/bookings/bookingsTypes";

const Customers = ({contact}: {contact: Contact}) => {

    return (
        <div>
            <Typography variant={'h6'} className="mb-3"><IntlMessages id={"booking:detail:customer:title"}/></Typography>
            <div>
                <div className="row form-group">
                    <label htmlFor="Email-1" className="col-sm-2 col-form-label"><IntlMessages id={"booking:detail:customer:first_name"}/></label>
                    <div className="col-sm-10"><input readOnly name="first_name" type="text" value={contact.first_name} className="form-control" /></div>
                </div>
                <div className="row form-group">
                    <label htmlFor="Password-1" className="col-sm-2 col-form-label"><IntlMessages id={"booking:detail:customer:last_name"}/></label>
                    <div className="col-sm-10"><input readOnly name="last_name" type="text" value={contact.last_name} className="form-control" /></div>
                </div>
                <div className="row form-group">
                    <label htmlFor="Password-1" className="col-sm-2 col-form-label"><IntlMessages id={"booking:detail:customer:phone_number"}/></label>
                    <div className="col-sm-10"><input readOnly name="phone" type="text" value={contact.phone_number} className="form-control" /></div>
                </div>
            </div>
        </div>
    )
}

export default Customers;