import React from 'react';
import {IUser} from "../../../Store/auth/authTypes";

interface IUserBlockProps {
    user: IUser
}

function UserBlock({user}: IUserBlockProps) {

    return (
        <div className="profile-top mb-20">
            <div className="profile-content">
                <div className="media">
                    <img src={`${process.env.PUBLIC_URL}/assets/svg/user-circle-blue.svg`} alt="user profile"
                         className="rounded-circle m-30 bordered profile-image" width="100" height="100"/>
                    <div className="media-body pt-60">
                        <div className="mb-20">
                            <h2>{user.first_name} {user.last_name}</h2>
                            <p>{user.email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UserBlock;
