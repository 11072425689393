import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppBar, Button, Toolbar} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import {Form, FormGroup, Input} from 'reactstrap';
import LinearProgress from '@material-ui/core/LinearProgress';
import AppConfig from "../Constants/AppConfig";
import {loginUser} from '../Store/auth/AuthActions';
import IntlMessages from "../Util/IntlMessages";
import {useValidation} from "../hooks";
import {toast} from "react-toastify";
import RootState from "../Store/RootState";
import {RESET_PASSWORD} from "../Constants/paths";

function Login() {

    const history = useHistory();
    const dispatch = useDispatch();
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const loading = useSelector<RootState, boolean>(state => state.auth.loading);
    const {validateEmail, validatePassword} = useValidation()

    const onUserLogin = () => {
        const error = {
            email: validateEmail(email) || '',
            password: validatePassword(password) || ''
        }
        if (error.email || error.password) {
            error.email && toast.error(<IntlMessages id={error.email}/>)
            error.password && toast.error(<IntlMessages id={error.password}/>)
            return;
        }

        dispatch(loginUser(email, password, history));
    }

    return (
        <div className="rct-session-wrapper">
            {loading &&
                <LinearProgress/>
            }
            <AppBar position="static" className="session-header">
                <Toolbar>
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="session-logo">
                                <Link to="/">
                                    <img src={AppConfig.appLogo} alt="session-logo" className="img-fluid"
                                         width="110" height="35"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="session-inner-wrapper">
                <div className="container">
                    <div className="row row-eq-height">
                        <div className="col-sm-7 col-md-6 col-lg-6 mx-auto">
                            <div className="session-body text-center">
                                <div className="session-head mb-30">
                                    <h2 className="font-weight-bold"><IntlMessages id={"login:title"}/></h2>
                                </div>
                                <Form name="login-form">
                                    <FormGroup className="has-wrapper">
                                        <Input
                                            type="email"
                                            value={email}
                                            name="email"
                                            id="email"
                                            className="has-input input-lg"
                                            placeholder="Enter Email Address"
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                        <span className="has-icon"><i className="ti-email"/></span>
                                    </FormGroup>
                                    <FormGroup className="has-wrapper">
                                        <Input
                                            value={password}
                                            type="password"
                                            name="password"
                                            id="password"
                                            className="has-input input-lg"
                                            placeholder="Password"
                                            onChange={(event) => setPassword(event.target.value)}
                                        />
                                        {/*<a className="d-flex justify-content-end" href={'/password/forgot'}>Forgot*/}
                                        {/*    password?</a>*/}
                                        <span className="has-icon"><i className="ti-lock"/></span>
                                    </FormGroup>
                                    <FormGroup className="mb-15">
                                        <Button
                                            color="primary"
                                            className="btn-block text-white w-100"
                                            variant="contained"
                                            size="large"
                                            onClick={onUserLogin}
                                        >
                                            <IntlMessages id={"login:button"}/>
                                        </Button>
                                    </FormGroup>
                                </Form>
                                <Link to={RESET_PASSWORD}><IntlMessages id={"login:forgot_password"}/></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
