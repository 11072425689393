import {createTheme} from '@material-ui/core/styles';
import AppConfig from '../../Constants/AppConfig';

const theme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            input: {
                padding: 10,
            }
        }
    },
    palette: {
        primary: {
            main: AppConfig.themeColors.primary
        },
        secondary: {
            main: AppConfig.themeColors.secondary
        },
        action: {
            main: AppConfig.themeColors.info
        }
    },
    typography: {
        useNextVariants: true,
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            "Roboto",
            "Helvetica",
            "Arial",
            'Heebo',
            'sans-serif',
        ].join(','),
        htmlFontSize: 16,
        h2: {
            fontSize: 21,
            fontWeight: 400,
        },
        body1: {
            fontWeight: 400,
        },
    },
});

export default theme;