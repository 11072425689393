import React, {useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import IntlMessages from "../../Util/IntlMessages";
import Customer from "./Tabs/Customer";
import Details from "./Tabs/Details";
import Passengers from "./Tabs/Passengers";
import {useSelector} from "react-redux";
import RootState from "../../Store/RootState";
import CustomSpinner from "../CustomSpinner/CustomSpinner";
import {makeStyles} from "@material-ui/core/styles";
import BookingDetailsHeader from "../BookingDetailsHeader";
import {DetailedBooking} from "../../Store/bookings/bookingsTypes";

interface ITabPanelProps {
    value: number;
    index: number;
    children: JSX.Element;
}

interface TabState {
    [key: string]: {
        label: React.ReactElement,
        icon: React.ReactElement
    }
}

const useStyles = makeStyles(() => ({
    tabs: {
        '&.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.2)',
        },
    },
    body: {
        background: 'white',
    }
}));

function TabPanel({value, index, children}: ITabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
        >
            {value === index && (
                <React.Fragment>
                    <Box p={3}>
                        {children}
                    </Box>
                </React.Fragment>
            )}
        </div>
    );
}

const BookingDetailsTabs = (): JSX.Element => {

    const classes = useStyles();

    const tabs: TabState = {
        'customer': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-account"/>,
            label: <IntlMessages id={'booking:tab:customer'}/>,
        },
        'details': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-info"/>,
            label: <IntlMessages id={'booking:tab:details'}/>,
        },
        'passengers': {
            icon: <i className="zmdi-hc-lg zmdi zmdi-accounts-alt"/>,
            label: <IntlMessages id={'booking:tab:passengers'}/>,
        }
    }

    const booking = useSelector<RootState, DetailedBooking>(state => state.booking.detail as Required<DetailedBooking>)

    const [activeIndex, setActiveIndex] = useState(0);

    const handleChange = (value: number) => {
        setActiveIndex(value);
    }

    return (
        <div>
            {booking ?
                <AppBar position="static" color={'transparent'}>
                    <BookingDetailsHeader booking={booking}/>
                    <Tabs
                        value={activeIndex}
                        onChange={(e, value) => handleChange(value)}
                        variant="fullWidth"
                        textColor="primary"
                        indicatorColor="primary">
                        {Object.entries(tabs).map(([key, value]) => (
                            <Tab key={key} className={classes.tabs} icon={value.icon} label={value.label}/>
                        ))}
                    </Tabs>
                    <div className={classes.body}>
                        <TabPanel value={activeIndex} index={0} children={<Customer contact={booking.contact}/>}/>
                        <TabPanel value={activeIndex} index={1} children={<Details booking={booking}/>}/>
                        <TabPanel value={activeIndex} index={2} children={<Passengers passengers={booking.passengers}/>}/>
                    </div>
                </AppBar> : <CustomSpinner/>}
        </div>
    );
}

export default BookingDetailsTabs;