import styles from './BookingDetailsHeader.module.scss';
import {DetailedBooking} from "../../Store/bookings/bookingsTypes";
import {
    Button,
    Modal,
    Box,
    Typography,
    Checkbox,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import {useEffect, useMemo, useState} from 'react';
import {cancelBooking} from 'Store/bookings/BookingsActions';
import IntlMessages from 'Util/IntlMessages';
import {useDispatch, useSelector} from 'react-redux';
import RootState from 'Store/RootState';
import { ChannelData } from 'Store/channel/channelTypes';

interface Props {
    booking: DetailedBooking
}

const BookingDetailsHeader = ({booking}: Props) => {
    const {contact, code, status} = booking;

    const reasons = [
        {value: "CUSTOMER_REQUEST", label: "Customer Request"},
        {value: "AIRLINE_REQUEST", label: "Airline Request"},
        {value: "TENANT_REQUEST", label: "Tenant Request"},
        {value: "BAGPOINT_REQUEST", label: "Bagpoint Request"},
        {value: "FLIGHT_CANCELLED", label: "Flight Cancelled"},
        {value: "FRAUD", label: "Fraud"}
    ];

    const [openModal, setOpenModal] = useState(false);
    const [formState, setFormState] = useState({
        reason: '',
        refund: false,
        notify: false,
        message: ''
    });
    const [confirmed, setConfirmed] = useState(false);
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

		const channel = useSelector<RootState, ChannelData>(state => state.channel.selected as Required<ChannelData>);
    const scopes = useSelector<RootState, Required<string[]>>(state => state.auth.scopes);

    const disableCancelBooking = useMemo(() => {
        const globalScope = scopes.some(scope => scope.includes('booking:admin:write'));
        const channelScope = scopes.some(scope => scope.includes(`channel:${channel.name.toLowerCase()}:booking:status:admin:write`));
        const hasScope = globalScope || channelScope;
        const bookingCancelledOrCompleted = status === "STATUS.ERROR.001" || status === "STATUS.PROGRESS.007";

        return !hasScope || bookingCancelledOrCompleted;
    }, [status, scopes]);

    const open = () => {
        setConfirmed(false);
        setOpenModal(true);
    };
    const close = () => setOpenModal(false);

    const handleInputChange = (event: React.ChangeEvent<{ value: unknown; name?: string }>) => {
        const {name, value} = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name as string]: value
        }));
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, checked} = event.target;
        setFormState(prevState => ({
            ...prevState,
            [name]: checked
        }));
    };

    const handleCancel = () => {
        close();
    };

    const handleConfirm = async () => {
        setLoading(true);
        try {
            const {reason, refund, notify, message} = formState;
            dispatch(cancelBooking({bookingCode: code, refund, notify, reason, message}));
            setConfirmed(true);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (confirmed) {
            const timer = setTimeout(() => {
                close();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [confirmed]);

    return (
        <div className={styles.header}>
            <Modal
                open={openModal}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={styles.modal}>
                    {confirmed ? (
                        <Typography className={styles.subtitle} id="modal-modal-title" variant="h6" component="h2">
                            <IntlMessages id={"booking:detail:header:confirmation_text"}/>
                        </Typography>
                    ) : (
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                <IntlMessages id={"booking:detail:header:title"}/>
                            </Typography>
                            <FormControl variant="outlined" size="small" margin="normal">
                                <InputLabel id="reason-label"><IntlMessages id={"booking:detail:header:reason_label"}/></InputLabel>
                                <Select
                                    labelId="reason-label"
                                    id="reason-select"
                                    name="reason"
                                    value={formState.reason}
                                    onChange={handleInputChange}
                                    label="Reason"
                                >
                                    {reasons.map(reason => <MenuItem value={reason.value}
                                                                     key={reason.value}>{reason.label}</MenuItem>)}
                                </Select>
                            </FormControl>
                            <FormControlLabel
                                className={styles.formControlLabel}
                                control={<Checkbox checked={formState.refund} onChange={handleCheckboxChange}
                                                   name="refund"/>}
                                label="Refund"
                            />
                            <FormControlLabel
                                className={styles.formControlLabel}
                                control={<Checkbox checked={formState.notify} onChange={handleCheckboxChange}
                                                   name="notify"/>}
                                label="Notify Customer"
                            />
                            <FormControl variant="outlined" size="small" margin="normal">
                                <TextField
                                    label={<IntlMessages id={"booking:detail:header:message_label"}/>}
                                    name="message"
                                    multiline
                                    minRows={4}
                                    variant="outlined"
                                    value={formState.message}
                                    onChange={handleInputChange}
                                />
                            </FormControl>
                            <div className={styles.buttons}>
                                <Button disabled={loading} onClick={handleCancel} variant="contained" color="secondary">
                                    <IntlMessages id={"booking:detail:header:cancel"}/>
                                </Button>
                                <Button disabled={loading || formState.reason === ""} onClick={handleConfirm}
                                        variant="contained" color="primary">
                                    <IntlMessages id={"booking:detail:header:confirm"}/>
                                </Button>
                            </div>
                        </>
                    )}
                </Box>
            </Modal>
            <h2>{contact.first_name} {contact.last_name}</h2>
            <p>{contact.email}</p>
            <Button disabled variant="contained" color={'primary'} className="m-5">
                <IntlMessages id={"booking:detail:header:email_button"}/>
            </Button>
            <Button disabled={disableCancelBooking} onClick={open} variant="contained" color={'secondary'} className="m-5">
                <IntlMessages id={"booking:detail:header:cancel_booking"}/>
            </Button>
        </div>
    );
}

export default BookingDetailsHeader;
