import Fetcher from "../../Util/Fetcher";
import {SimpleBooking, Tenant} from "../../Store/bookings/bookingsTypes";
import {calculatePrice, getStatus, updateFilters} from "../../Util/utils";
import {useDispatch, useSelector} from "react-redux";
import RootState from "../../Store/RootState";
import React, {} from "react";
import {Button} from "@material-ui/core";
import {ExportToCsv} from 'export-to-csv';
import moment from "moment";
import {makeStyles} from "@material-ui/core/styles";
import IntlMessages from "../../Util/IntlMessages";
import {setBookings} from "Store/bookings/BookingsActions";
import {toast} from "react-toastify";
import ENV from "Util/environments";

interface Props {
    disabled: boolean,
    changed: boolean,
    className: string,
    channelId: number,
    filters: any,
    offset: number,
    limit: number
}

const useStyles = makeStyles((theme) => ({
    action: {
        backgroundColor: theme.palette.warning.main,
        color: 'white'
    }
}));

const CsvDownload: React.FC<Props> = ({disabled, changed, className, channelId, filters, offset, limit}) => {
	const search = useSelector<RootState, string>(state => state.booking.search);
	
    const options = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: false,
        useTextFile: false,
        useBom: true,
        useKeysAsHeaders: true,
        filename: `bookings${filters.status ? `-${filters.status}` : ''}${filters.service ? `${filters.service}` : ''}-${moment(filters.from_datetime).format('DD_MM_YYYY')}-${moment(filters.to_datetime).format('DD_MM_YYYY')}`
    };

    const csvExporter = new ExportToCsv(options);
    const dispatch = useDispatch();
    const classes = useStyles();
    const tenants = useSelector<RootState, Tenant[]>(state => state.booking.tenants);

    const downloadCsv = async () => {
        const updatedFilters = {
            ...updateFilters(filters, search),
            status: filters?.status || [
                "STATUS.PROGRESS.005",
                "STATUS.PROGRESS.002",
                "STATUS.PROGRESS.007",
								"STATUS.ERROR.001"
            ]
        };
			
        let allBookings: SimpleBooking[] = [];
        const limit = 10;
        let nextUrl = `channels/${channelId}/bookings/?offset=${offset}&limit=${limit}`;

        try {
            let fetchMore = true;

            while (fetchMore) {
								const isNextUrl = nextUrl.startsWith(ENV.API_URL);
                const relativeNextUrl = isNextUrl ? nextUrl.replace(`${ENV.API_URL}/`, '') : nextUrl;

                const response = await Fetcher({
                    path: relativeNextUrl,
                    params: !isNextUrl ? updatedFilters : {}
                });

                const results = response.data.results;
                allBookings = allBookings.concat(results);

                nextUrl = response.data.next;
                fetchMore = !!nextUrl;
            }

            const mapped = allBookings.map((booking: SimpleBooking) => {

                const price = Object.values(booking.commission.total).reduce((acc, contract) => {
                    const fullValue = calculatePrice(contract.commission, contract.currency.exponent);
                    return acc + fullValue;
                }, 0);

                const airline = Object.values(booking.commission.total).find(com => com.role === 'reseller');
                const tenant = Object.values(booking.commission.total).find(contract => contract.role === 'tenant')
                const currency = tenant?.currency.code

                const formattedPrice = price.toFixed(2).replace('.', ',') || "";
                const commission = airline ? calculatePrice(airline.commission, airline.currency.exponent).toFixed(2).replace('.', ',') : "";

                return {
                    order: booking.code,
                    customer_email: booking.contact?.email,
                    customer_first_name: booking.contact?.first_name,
                    customer_last_name: booking.contact?.last_name,
                    luggage: booking.assignments[0].luggage_count,
                    service: booking.assignments[0].service,
                    order_date: booking.created,
                    pickup_date: booking.assignments[0].from_datetime,
                    delivery_date: booking.assignments[0].to_datetime,
                    currency: currency || "",
                    commission,
                    price: formattedPrice,
                    status: getStatus(booking.status),
                    tenant: tenants.find(tenant => tenant.id === booking.assignments[0].tenant)?.name || '',
                }
            });

            dispatch(setBookings(allBookings))

            csvExporter.generateCsv(mapped)
        } catch (error) {
            console.error(error)
            toast.error('Something went wrong while trying to export.')
        }
    }

    return (
        <Button
            variant="contained"
            className={className}
            onClick={downloadCsv}
            classes={{root: classes.action}}
            startIcon={!changed && <i className="zmdi zmdi-download"/>}
            disabled={disabled || changed}
        >
            {changed ?
                <IntlMessages id={'csv_download:button:apply_filters'}/> :
                <IntlMessages id={'csv_download:button:export'} values={{limit}}/>
            }
        </Button>
    )
}

export default CsvDownload;