import React from 'react';
import Typography from '@material-ui/core/Typography';
import Profile from './component/Profile';
import UserBlock from './component/UserBlock';
import {RctCard} from 'Components/RctCard';
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';
import IntlMessages from 'Util/IntlMessages';
import {useSelector} from "react-redux";
import RootState from "../../Store/RootState";
import {IUser} from "../../Store/auth/authTypes";
import {RouteComponentProps} from "react-router-dom";

export default function UserProfile(props: RouteComponentProps) {

    const user = useSelector<RootState, IUser>(state => state.auth.user || {});

    return (
        <div className="userProfile-wrapper">
            <PageTitleBar title={<IntlMessages id="sidebar.userProfile"/>} match={props.match}/>
            {user && <RctCard children={<>
            <UserBlock user={user}/>
                <div className="rct-tabs">
                    <Typography component="div" style={{padding: 8 * 3}}>
                        <Profile user={user}/>
                    </Typography>
                </div>
            </>}/>}
        </div>
    );
}
