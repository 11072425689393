import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {Provider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";
import {persistor, store} from "./Store/store";
import MomentUtils from "@date-io/moment";
import RctThemeProvider from "./Container/RctThemeProvider";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <RctThemeProvider>
                    <App/>
                </RctThemeProvider>
            </MuiPickersUtilsProvider>
        </PersistGate>
    </Provider>,
    document.getElementById('root')
);