export const TranslationKeys = {
        "address:street:name": "Street",
        "address:street:number": "Number",
        "address:street:addition": "Addition",
        "address:postalcode": "Postal code",
        "address:city": "City",
        "address:country": "Country",
        "button:cancel": "Cancel",
        "button:confirm": "Confirm",
        "button:edit": "edit",
        "button:next": "next",
        "header:title": "We've got your bag, at every point",
        "header:slogan": "Book your airport delivery service...",
        "header:subtitle": "To book our service we need some more information",
        "header:menu:home": "Home",
        "header:menu:airport_delivery": "Airport delivery",
        "header:menu:city_delivery": "City delivery",
        "header:menu:become_partner": "Become a partner",
        "payment:heading": "Review and confirm your order",
        "location:heading:airport": "Select the location from where our driver will pick up your luggage",
        "location:heading:city": "Select the location to which our driver will deliver your luggage",
        "location:heading:title": "The service will be handled by our contracted local partner,",
        "location:heading:info:title": "Contact info",
        "payment:security": "All payments are secured by Adyen.",
        "payment:switch_new": "New Customer",
        "location:label_date:airport": "Pickup date",
        "location:label_date:city": "Delivery date",
        "location:label_time": "Time slot",
        "payment:label_email": "Email",
        "payment:label_phone": "Contact number",
        "payment:label_passport": "Passport number",
        "payment:label_passport:info": "Usually your passport number can be found in the top right corner. Sometimes also referred to as Document number.",
        "flight:switch_number": "Flight Number",
        "flight:switch_search": "Flight Search",
        "payment:label_create": "Create account",
        "error:general_error": "Something went wrong. Please try again.",
        "payment:switch_login": "Login",
        "payment:switch_registration": "Register",
        "flight:number:heading": "Please enter your flight information",
        "flight:search:heading": "Which airline are you flying with?",
        "flight:search:label_to": "To",
        "location:label_luggage:airport": "How many bags would you like to check-in for you?",
        "location:label_luggage:city": "How many bags would you like us to delivery for you?",
        "payment:label_password": "Password",
        "payment:result:Cancelled": "Payment cancelled",
        "payment:result:Error": "An error occurred, please try again later.",
        "payment:result:Expired": "Session expired.",
        "payment:result:Refused": "Payment Refused",
        "price_overview:luggage": "luggage item selected",
        "flight:number:label_pnr": "Airline booking code",
        "flight:number:label_pnr:info": "Your airline booking code is a 6 characters long alphanumeric code you received form the airline you're flying with. Sometimes also referred to as booking reference, booking number.",
        "location:switch_address": "Address",
        "payment:label_last_name": "Last name",
        "flight:completed_heading": "You're flying with",
        "flight:search:label_from": "From",
        "location:switch_bagpoint": "Bagpoint",
        "location:zone_error": "Location is outside of serviceable zone.",
        "payment:label_first_name": "First name",
        "header:progress_completed": "Completed",
        "price_overview:no-address": "Location not selected",
        "location:completed_heading": "Bagpoint will pickup your bags",
        "location:confirmation_date": "On",
        "location:confirmation_time": "Between",
        "flight:search:label_airline": "You're flying with",
        "location:address_placeholder": "Your address...",
        "location:bagpoint_placeholder": "Bagpoint postal code",
        "location:confirmation_address": "We will pick up your bags at: ",
        "payment:label_repeat_password": "Repeat password",
        "validation:flight:pnr_invalid": "Please enter a valid airline booking code",
        "location:first_policy_checkbox": "I have read and agree to Bagpoint's <a href=\"{{url}}\" target=\"_blank\">terms and services</a>.",
        "validation:flight:pnr_required": "Airline booking code is required",
        "validation:flight:pnr_too_long": "Booking code is too long, the maximum length is 8",
        "validation:flight:pnr_no_special_char": "No special characters allowed",
        "validation:input:email_invalid": "Please enter a valid email",
        "location:second_policy_checkbox": "I understand my data may be shared with partner airlines in order to provide this service.",
        "price_overview:luggage_multiple": "luggage items selected",
        "validation:flight:date_departed": "Your flight has already departed!",
        "validation:flight:wrong_airline": "Sorry, we don't fly with that airline.",
        "validation:flight:wrong_departure": "Sorry, we don't fly from that airport.",
        "validation:flight:wrong_arrival": "Sorry, we don't service that airport",
        "validation:input:email_required": "Email is required",
        "validation:input:password_short": "Password must be at least 8 characters long.",
        "validation:input:passport_required": "Passport number is required",
        "validation:input:passport_invalid": "Passport number is invalid",
        "validation:input:phone_invalid": "Phone number is invalid",
        "validation:input:phone_required": "Phone number is required",
        "flight:number:label_flight-number": "Flight number",
        "validation:input:lastname_invalid": "Please enter a valid last name",
        "validation:location:empty_address": "Please select an address.",
        "flight:number:label_departure-date": "Departure date",
        "validation:input:firstname_invalid": "Please enter a valid first name",
        "validation:input:lastname_required": "Your last name is required",
        "validation:input:password_no_digit": "Password must contain at least 1 digit.",
        "validation:input:password_no_match": "Passwords don't match.",
        "validation:input:password_required": "Password is required",
        "validation:location:empty_timeslot": "Please select a time slot.",
        "validation:input:firstname_required": "Your first name is required",
        "validation:input:password_no_letters": "Your password must contain lowercase and uppercase letters.",
        "validation:location:empty_checkboxes": "Please agree to our policy.",
        "validation:input:street_too_long": "Maximum 300 characters allowed.",
        "validation:input:number_too_long": "Maximum 20 characters allowed.",
        "validation:input:addition_too_long": "Maximum 20 characters allowed.",
        "validation:input:postalCode_too_long": "Maximum 20 characters allowed.",
        "validation:input:city_too_long": "Maximum 200 characters allowed.",
        "validation:input:city_required": "City is required!",
        "validation:input:country_too_long": "Maximum 100 characters allowed.",
        "validation:input:country_required": "Country is required!",
        "validation:internal_error": "Internal server error.",
        "payment:result_message:button_failure": "Try again",
        "payment:result_message:button_success": "Make a new booking",
        "validation:flight:date_offset_invalid": "We can only pick up luggage for flights departing in less than {{ offset }} hours.",
        "payment:result_message:button_redirect": "Back to website",
        "validation:flight:flight_number_invalid": "Please enter a valid flight number",
        "validation:flight:flight_number_required": "Flight number is required",
        "validation:location:timeslot_placeholder": "Select your pickup time",
        "validation:location:unavailable_timeslot": "There are no pickup slots available for the selected date.",
        "login:checkbox": "Stay logged in",
        "login:invalid_grant": "Incorrect email or password",
        "login:email": "Email",
        "login:password": "Password",
        "login:forgot_password": "I forgot",
        "password_reset:back_to_login": "Go back to <a href=\"{{path}}\">login</a>",
        "password_reset:request": "Send",
        "portal:account:email": "E-mail",
        "portal:account:name": "Name",
        "portal:account:firstName": "First name",
        "portal:account:lastName": "Last name",
        "portal:account:phone": "Phone",
        "portal:account:title": "Your account",
        "portal:booking:code": "Booking code",
        "portal:booking:pickup": "Pickup location",
        "portal:booking:dropoff": "Delivery location",
        "portal:booking:loadMore": "Load more",
        "portal:booking:luggage": "Luggage",
        "portal:booking:title": "Your bookings",
        "portal:booking:partner:tenant": "Tenant",
        "portal:booking:partner:title": "Logistics partner",
        "portal:booking:partner:email": "E-mail",
        "portal:booking:payment:net": "Net",
        "portal:booking:payment:tax": "Tax",
        "portal:booking:payment:total": "Total",
        "portal:booking:payment:title": "Invoice",
        "portal:location:street": "Street",
        "portal:location:number": "Number",
        "portal:location:zip": "Postal code",
        "portal:location:city": "City",
        "portal:location:title": "Your locations",
        "portal:location:loadMore": "Load more",
        "thank_you:booking_number": "Booking #{{bookingNumber}}",
        "thank_you:pickup:warning": "Just remember, we need to see everyone with their passports and they must be checked-in online before we arrive.",
        "thank_you:pickup:title:active": "Your {{luggage}} luggage will be picked up at",
        "thank_you:pickup:title": "Your luggage will be picked up at",
        "thank_you:pickup:tenant": "by our contracted local partner,",
        "thank_you:dropoff:title:active": "Your {{luggage}} luggage will be delivered at",
        "thank_you:dropoff:title": "Your luggage will be delivered at",
        "thank_you:dropoff:subtitle": "And checked in for you.",
        "thank_you:flight:title": "Have a safe flight with",
        "thank_you:message:title:Authorised": "Thank you! You are all set.",
        "thank_you:message:subtitle:Authorised": "Please check your e-mail for your order receipt.",
        "thank_you:message:title:Pending": "Thank you! Your payment is pending.",
        "thank_you:message:subtitle:Pending": "You will receive an order receipt as soon as it is confirmed.",
        "thank_you:message:title:Received": "Thank you! Your payment is received.",
        "thank_you:message:subtitle:Received": "You will receive an order receipt as soon as it is confirmed.",
        "thank_you:message:contact": "Don’t hesitate to <a href=\"mailto:info@bagpoint.com\">contact us</a> if you have any questions.",
        "thank_you:message:myBookings": "My bookings",
        "thank_you:message:bookAgain": "Book again",
        "thank_you:restrictions:prohibited:title": "Prohibited items",
        "thank_you:restrictions:prohibited:dangerousGoods": "Dangerous goods",
        "thank_you:restrictions:prohibited:batteries": "Batteries",
        "thank_you:restrictions:prohibited:magnets": "Magnets",
        "thank_you:restrictions:prohibited:flammableItems": "Flammable items",
        "thank_you:restrictions:cabin:title": "Cabin luggage",
        "thank_you:restrictions:cabin:electronicDevices": "Electornic devices",
        "thank_you:restrictions:cabin:liquidsLowVolume": "Liquids < 100ml",
        "thank_you:restrictions:checkedIn:title": "Checked in luggage",
        "thank_you:restrictions:checkedIn:sharpObjects": "Sharp objects",
        "thank_you:restrictions:checkedIn:firearm": "Firearm",
        "thank_you:restrictions:checkedIn:liquidsHighVolume": "Liquids > 100ml",
        "thank_you:restrictions:onPerson:title": "On person",
        "thank_you:restrictions:onPerson:lighter": "Lighter",
        "thank_you:restrictions:onPerson:esmoke": "Electronic cigarette",
        "error:flights:E_FLIGHT_IMPORT_ERROR_001": "Flight is too far in the past",
        "error:flights:E_FLIGHT_IMPORT_ERROR_002": "Flight is too far in the future",
        "error:flights:E_FLIGHT_IMPORT_ERROR_003": "Could not find flight for given date",
        "error:flights:E_FLIGHT_IMPORT_ERROR_004": "Multiple flight were found",
        "error:flights:E_FLIGHT_IMPORT_ERROR_005": "Multiple airlines were found",
        "error:flights:E_FLIGHT_IMPORT_ERROR_006": "Multiple airports were found",
        "error:flights:E_FLIGHT_IMPORT_ERROR_008": "Flight has been cancelled",
        "error:flights:E_FLIGHT_IMPORT_ERROR_011": "Airline is not serviceable",
        "error:flights:E_FLIGHT_IMPORT_ERROR_012": "Airport is not serviceable",
        "error:flights:E_FLIGHT_IMPORT_ERROR_013": "Airport is not serviceable",
        "error:flights:E_FLIGHT_IMPORT_ERROR_014": "No airline was found",
        "error:flights:no_available_timeslot": "There are no available pickup slots for the selected flight",
        "footer:trademark": "Bagpoint is a registered trademark of Leave Your Luggage B.V.",
        "footer:copyright": "All rights reserved.",
        "footer:about": "About",
        "footer:contact": "Contact",
        "footer:company": "Leave Your Luggage B.V.",
        "footer:slogan": "Travel with love from Amsterdam",
        "footer:powered_by": "Powered by",
        "footer:terms:title": "Terms & Conditions",
        "footer:terms:link": "https://www.bagpoint.com/terms-conditions/",
        "footer:privacy:title": "Privacy Policy",
        "footer:privacy:link": "https://www.bagpoint.com/privacy-policy/",
        "footer:faq": "FAQ",
        "account_selector:account": "Account",
        "account_selector:logout": "Logout",
        "service:airport": "Airport Delivery",
        "service:city": "City Delivery",
        "price_overview:total": "Total:",
        "geo_form:description": "Description",
        "membership:heading": "Please enter Altanfeethi information",
        "membership:field": "Please enter membership number",
        "flight:heading": "Select your flight",
        "flight:select:heading": "You're flying with",
        "flight:select:subHeading:airport": "Please select which is your departing airport",
        "flight:select:subHeading:city": "Please select which is your arriving airport",
        "membership:error": "Membership number is invalid",
        "membership:completed_heading": "Your Altanfeethi membership number is",
        "map:info_window:explanation": "Click somewhere on the map to change the selected location",
        "faq:city_delivery:title": "City Delivery",
        "faq:city_delivery:length": "5",
        "faq:city_delivery:question:1": "What information do I need to book this service?",
        "faq:city_delivery:answer:1": "In order to be able to collect your luggage at the airport we require your Flight Arrival details, such as airline, flight number, flight date. When we have found your flight, we then require your first name, last name and the number of bags to be transported. We do not require the names of your fellow passengers as you are the main booker.",
        "faq:city_delivery:question:2": "How far in advance do I need to book?",
        "faq:city_delivery:answer:2": "You can book one year in advance up to 3 hours before you need your bags to be collected (or dropped-off). We also require a minimum of 4 hours between collection (drop-off) and delivery. Please note that for bookings made between 19:00 hrs and 07:00 hrs the earliest pick-up time is 10:00 hrs.",
        "faq:city_delivery:question:3": "Can I select a collection/drop-off time slot?",
        "faq:city_delivery:answer:3": "When making your booking you’ll be offered a 2-hour collection/drop-off time slot. At the airport our team in the terminal will aim to be available 15 to 30 minutes after your actual flight arrival time.",
        "faq:city_delivery:question:4": "Can I select a delivery time slot?",
        "faq:city_delivery:answer:4": "When making your booking you’ll be offered a 2-hour delivery time slot.",
        "faq:city_delivery:question:5": "Can I make changes to my booking?",
        "faq:city_delivery:answer:5": "If you like to make changes to your booking please call our support team. Bagpoint is working hard to allow you to view and edit your booking up to 2 hours before collection/drop-off and free of charge.",
        "faq:airport_delivery:title": "Airport Delivery with Bag Check-in",
        "faq:airport_delivery:length": "5",
        "faq:airport_delivery:question:1": "What information do I need to book this service?",
        "faq:airport_delivery:answer:1": "In order to be able to check-in your bags in from the comfort of your home or hotel and transport your luggage to the airport we require your Flight Departure details, such as airline, flight number, flight date, for one of our Partner Airlines: TUI, Transavia, KLM. When we have found your flight, we then require first name, last name, number of check-in bags, passport or national ID document number and of all passengers that travel under the same airline booking code and who will be using this service.",
        "faq:airport_delivery:question:2": "How far in advance do I need to book?",
        "faq:airport_delivery:answer:2": "You can book one year in advance up to 3 hours before the required home pick-up time or hub drop-off time. You also need to be aware we require a minimum of 6 hours from the end of the pick-up time slot to the scheduled departure time to ensure secure processing of your bags. Please note that for bookings made between 19:00 hrs and 07:00 hrs the earliest pick-up time is 10:00 hrs.",
        "faq:airport_delivery:question:3": "Can I select a drop-off or pick-up time slot?",
        "faq:airport_delivery:answer:3": "Bagpoint offers Airport Delivery with Bag Check-in from HOME and from HUB locations such as hotels, congress centres and the likes. Our HUB partners have one or more Bagpoint self-service bag drop kiosks placed. When making your booking you’ll be offered a 2-hour HOME pick-up time slot or a 2-hour self-service bag drop time slot when you chose Bag Check-in from a HUB location.",
        "faq:airport_delivery:question:4": "Can I select a delivery time slot?",
        "faq:airport_delivery:answer:4": "When you have booked Airport Delivery with Bag Check-in we ensure all your checked-in bags will be injected in the baggage handling system of your departing airport at least 3 hours before your scheduled flight departure.",
        "faq:airport_delivery:question:5": "Can I make changes to my booking?",
        "faq:airport_delivery:answer:5": "If you like to make changes to your booking please call our support team. Bagpoint is working hard to allow you to view and edit your booking up to 2 hours before collection/drop-off and free of charge.",
        "faq:on_the_day:title": "How it works on the day",
        "faq:on_the_day:length": "5",
        "faq:on_the_day:question:1": "What do I need to do on the day if I’m using Airport Delivery with Bag Check-in service?",
        "faq:on_the_day:answer:1": "1) Make sure that all passengers that have bags to be checked-in, are checked-in online and are in possession of an airline (e)boarding pass and passport or national ID.\n2) Make sure that all passengers that have bags to be checked-in, have their bags ready to go and that each bag is within the baggage allowance & weight limits before a Bagpoint Driver comes to pick it up (Bag Check-in from HOME), or before you use the Bagpoint self-service bag drop (Bag Check-in from HUB).\n3) Make sure that all passengers that have bags to be checked-in, are present as soon as the Bagpoint Driver arrives including their bag(s), boarding pass and passport/ID (Bag Check-in from HOME), or when you use the Bagpoint self-service bag drop (Bag Check-in from HUB)\n4) Bagpoint will take care of your bags from here. In case of Bag Check-in from HOME, the Bagpoint Driver will collect your documents, transport them and perform the bag check-in at a Bagpoint distribution point and inject them into the baggage handling system at your departing airport. In case of Bag Check-in from HUB, all bags are already checked-in with your airline by you. You can hand over your checked-in bags to the local staff present and you are ready to make the most out of your day. A Bagpoint Driver will come to pick-up your checked-in bags, transport them and inject them into the baggage handling system at your departing airport",
        "faq:on_the_day:question:2": "What if I don’t have a boarding pass?",
        "faq:on_the_day:answer:2": "An airline boarding pass is required in order to be able to check-in your bag(s). Without a boarding pass it is not possible to use the Bagpoint self-service bag drop kiosk in case you booked Bag Check-in from HUB. In this case, you will be eligible for a full refund. Without a boarding pass a Bagpoint Driver cannot accept and collect your bag for transport to your departing airport in case you booked Bag Check-in from HOME. In this case you will be eligible for a partial refund, as Bagpoint will charge for the pick-up service",
        "faq:on_the_day:question:3": "What happens if a member of the party isn’t present during home pick-up for an Airport Delivery with Bag Check-in?",
        "faq:on_the_day:answer:3": "As with most airlines, if a member of the party isn’t present upon collection/pick-up then a Bagpoint Driver will not be able to check-in the bag(s) of this person unless another member that is present has enough baggage allowance with the airline to accept the bag(s) under their name.",
        "faq:on_the_day:question:4": "Can I use an electronic boarding pass?",
        "faq:on_the_day:answer:4": "Yes, we accept both printed and electronic airline boarding passes for the Airport Delivery with Bag Check-in service.",
        "faq:on_the_day:question:5": "What do I need to do on the day if I’m using City Delivery service?",
        "faq:on_the_day:answer:5": "1) Make sure to pick-up your bag(s) from the arrival airport baggage carousel.\n2) Pass by Customs\n3) Look for your Bagpoint liaison\n4) Bagpoint will take care of your bags for here and deliver them to the address you booked and within the selected timsecutrityfaq:our_services:title",
        "faq:our_services:title": "Our services",
        "faq:our_services:length": "9",
        "faq:our_services:question:1": "What is the difference between City Delivery and Airport Delivery with Bag Check-in?",
        "faq:our_services:answer:1": "With City Delivery we transport bags from an arrival airport to a certain address in the city in and/or nearby this airport or even further out. With Airport Delivery it is the other way around, we transport bags from your home address, or from your hotel to your departing airport.",
        "faq:our_services:question:2": "How much do the Bagpoint services cost?",
        "faq:our_services:answer:2": "Bagpoint charges are dependent on the area in the world where the luggage transport will take place, but also on the number of bags to be transported and the distance. You can check prices online on your website of the Bagpoint app.",
        "faq:our_services:question:3": "What if my flight is cancelled?",
        "faq:our_services:answer:3": "You can cancel your booking free-of-charge up to 3 hours before we collect your bags. After that refunds are not possible. To cancel your booking call our support team.",
        "faq:our_services:question:4": "Do you offer luggage storage?",
        "faq:our_services:answer:4": "When you have booked the City Delivery service, Bagpoint offers same day delivery only. When you have booked Airport Delivery with Bag Check-in then depending on your flight departure Bagpoint delivers your bags the same day or the next day.",
        "faq:our_services:question:5": "Can you pick-up my luggage from the luggage carousel when I land?",
        "faq:our_services:answer:5": "Not yet. Customs still require passengers of international flights to collect their own bag(s) from the luggage carousel and take it through customs with them. Bagpoint is working with the Dutch Customs and Schiphol airport to find a solution in order to be able to offer City Delivery with Off-airport Bag Reclaim starting with passengers arriving from Schengen countries.",
        "faq:our_services:question:6": "What is your coverage area for Amsterdam Airport Schiphol?",
        "faq:our_services:answer:6": "Bagpoint picks-up and delivers first and fore mostly in the Greater Amsterdam region. We will be extending further out as soon as we can.",
        "faq:our_services:question:7": "What is your coverage area for Abu Dhabi Airports?",
        "faq:our_services:answer:7": "Bagpoint picks-up and delivers in the greater Abu Dhabi region.",
        "faq:our_services:question:8": "What is your coverage area for Dubai Airports?",
        "faq:our_services:answer:8": "Bagpoint picks-up and delivers in the greater Dubai region. ",
        "faq:our_services:question:9": "Why use the Bagpoint services?",
        "faq:our_services:answer:9": "At Bagpoint we love travel, but we hate waiting, stress and being held up by queues. We all know what it is like to travel with hold-luggage/checked bags. With Bagpoint you can pack heavy but travel light and skip the dreaded queue at the airport bag check-in/drop-off. And in a world of COVID-19 we think it is wise to avoid queues at an airport as much as possible. Bagpoint makes that possible.",
        "faq:luggage_allowance:title": "Luggage allowances",
        "faq:luggage_allowance:length": "5",
        "faq:luggage_allowance:question:1": "What is the airline’s hold-luggage baggage allowance and maximum weight?",
        "faq:luggage_allowance:answer:1": "Baggage allowance is sometimes included in the airline ticket price. More and more airlines however are requesting you to add and pay for check-in bags during the booking process. If you have any baggage allowance or you added bags during your airline booking, then the baggage allowance including the maximum weight can be found on your airline e-ticket. If you have no baggage allowance and you do want to check-in bags, you can go to the airline website or app to amend your airline booking.",
        "faq:luggage_allowance:question:2": "Can I add something into my bag before it’s loaded onto the plane?",
        "faq:luggage_allowance:answer:2": "When you are using the Airport Delivery with Bag Check-in service, it is not allowed to add anything to your bag. Bagpoint and our HUB Partners follow strict rules of engagement which are in accordance with the airline safety and security regulations and which are exactly the same as when you check-in and drop-off your luggage at an airport.",
        "faq:luggage_allowance:question:3": "Can I use the Airport Delivery with Bag Check-in service if I have oversized and/or overweight luggage?",
        "faq:luggage_allowance:answer:3": "Bagpoint is working hard to incorporate oversized and/or excess luggage according to airline regulations. Until this time, it is not possible to use the Bag Check-in service if your bag weight exceeds the airline baggage allowance. Please make sure to weigh your bag and check if the weight is within the airline baggage allowance before a Bagpoint Driver arrives at your door or before you use a Bagpoint self-service bag drop at a Bagpoint Partner HUB location.",
        "faq:luggage_allowance:question:4": "Can I check-in buggies, children’s car seats, wheelchairs and electric mobility aids?",
        "faq:luggage_allowance:answer:4": "At this point in time Bagpoint only checks-in regular hold-luggage such as suitcase, trolleys, soft bags as long as they are within the size limits as indicated by your airline.",
        "faq:luggage_allowance:question:5": "What are the Bagpoint baggage weight limits?",
        "faq:luggage_allowance:answer:5": "The maximum weight limit per bag is 32 Kg. Airline baggage allowance is usually much more restricted. Please check your e-ticket for your baggage allowance and bag weight and size limits.",
        "faq:security:title": "Security",
        "faq:security:length": "4",
        "faq:security:question:1": "What happens if something gets damaged?",
        "faq:security:answer:1": "When you have booked Airport Delivery with Bag Check-in, then Bagpoint drivers will check for damage before accepting your bag upon collection/pick-up. When a bag is too damaged, a Bagpoint Driver is allowed to not accept your bag. When a bag is damaged upon pick-up, but ok for transport, then a Bagpoint Driver will make photos of the damaged bag. Before handing over your bag to your airline, a Bagpoint Driver will always make a photo of each bag so we are confident of the condition throughout delivery. In the unlikely event your bag gets damaged in Bagpoint’s care, Bagpoint will contact you. As soon as your bag has been handed over to your airline at your departure airport, the baggage liability remains with our Partner Airlines as governed by the Montreal & Warsaw conventions.",
        "faq:security:question:2": "Can someone add or remove anything from my luggage during transport to the airport?",
        "faq:security:answer:2": "No, all bags are sealed upon collection/pick-up using Tamper Aware Seals. Bagpoint vehicles remain locked at all times when in service. It is easy to spot when someone has been tampering with your bag as the security seal is then either missing or it shows “void”.  Be aware that airport security staff may have opened your bag to check its contents. When this happens, there should be a notification inside your bag and of course the seal shows “void”.",
        "faq:security:question:3": "What if my bag goes missing?",
        "faq:security:answer:3": "Until now Bagpoint has never lost a bag in our care. All bags are tracked from pick-up until delivery. You will receive a notification when your bag is scanned upon pick-up and upon delivery. If your bags are not there at the baggage carousel at your arrival airport, but you have received a delivery notification including the IATA claim tag (bag tag receipt) from Bagpoint then please check with your airline the whereabouts of your bags.",
        "faq:security:question:4": "What happens if I get asked if anyone has tampered with my luggage?",
        "faq:security:answer:4": "Since you have already checked-in and dropped-off your bags remotely (outside of the airport), you can rest assured this question will not be asked. Bagpoint ensures all bags are sealed with a Tamper Aware Seal and transported securely to the airport and injected into the airport’s baggage handling system. The Bagpoint Airport Delivery with Bag Check-in process has been audited and approved by the Dutch Ministry of Defence and the Dutch Anti-Terrorism institute.",
        "faq:troubleshooting:title": "Troubleshooting",
        "faq:troubleshooting:length": "3",
        "faq:troubleshooting:question:1": "Trouble making a booking for Airport Delivery with Bag Check-in?",
        "faq:troubleshooting:answer:1": "Give us a call. We’ll get you sorted. The Bagpoint support team is available on {{contact}} from 06:00 - 22:00, 7 days a week",
        "faq:troubleshooting:question:2": "How do I change or reset my password?",
        "faq:troubleshooting:answer:2": "To change your password, open the Bagpoint app, click on “Profile” and you will find an option there to change your password. If for some reason you have logged-out of your account and have forgotten your password, open the Bagpoint app and in the log-in section please click the “forgot password” button and fill-in the e-mail address you used.",
        "faq:troubleshooting:question:3": "I can’t find my booking in my account",
        "faq:troubleshooting:answer:3": "You can find your bookings in the Bagpoint app. Open the Bagpoint app and please click on “My Bookings” in the menu on the bottom of your screen. If you have made a booking and your booking isn’t there, please give us a call on {{contact}}",
        "cookies:modal:title": "Privacy notice",
        "cookies:title": "This website uses cookies to provide necessary website functionality, improve your experience and analyze our traffic.",
        "cookies:necessary:title": "Functional cookies",
        "cookies:necessary:content": "These cookies are necessary for the site to function properly.",
        "cookies:statistic:title": "Analytics cookies",
        "cookies:statistic:content": "These cookies are for analytics purposes. No personal data is being stored.",
        "cookies:button:openSettings": "Cookie settings",
        "cookies:button:back": "Back",
        "cookies:button:rejectAll": "Reject all",
        "cookies:button:acceptAll": "Accept all",
        "cookies:button:saveSetting": "Save settings",
        "announcement.title.001": "Apologies",
        "announcement.message.001": "This service is currently unavailable.",
        "announcement.title.002": "New feature",
        "announcement.message.002": "Check out our super-duper new feature now!",
        "flow:input:label": "Delivery to",
        "flow:heading": "Please select your service",
        "flow:completed_heading": "Your selected service is",
        "flow:subtitle:city": "We collect and deliver your bags from the airport to your doorstep.",
        "flow:subtitle:airport": "We collect and check-in your bags from your doorstep and deliver them to the airport.",
        "city:luggage:pickup:title": "How will we get your luggage?",
        "city:luggage:pickup:subtitle": "Our Driver will contact you shortly after you've landed to meet with him for the Baggage collection.",
        "email:verified:success": "Thank you for verifying your email!",
        "email:verified:error": "Something went wrong. Please contact our customer support <a href=\"mailto:info@bagpoint.com\">here</a>.",
        "email:verified:button": "Go back to main page",
        "address:short_address": "Short address",
        "validation:input:short_address_incorrect": "Short address is incorrect. It must contain 4 letters and 4 numbers.",
        "location:favourite": "Set as favourite",
        "location:address:notfound": "No address is found. Please use the map to find your address.",
        "location:selectAddress": "Please select the correct address",
        "registration:submitButton": "Sign up",
        "registration:loginLink": "Already have an account? <a href=\"{{path}}\">Sign in</a>",
        "login:submitButton": "Sign in",
        "login:registrationLink": "Need an account? <a href=\"{{path}}\">Sign up</a>",
        "registration:success": "Welcome! A verification email was sent to your email address.",
        "password_reset:request_sent": "If you have an account with us, and it is verified, you'll get an email to change your password. If you did not get an email after 10 minutes, please contact us on info@bagpoint.com.",
        "password_reset:success": "Successful password change! Now you can login with your new password.",
        "password_change:new_password": "New password",
        "password_change:repeat_password": "Repeat password",
        "password_change:error": "Something went wrong!"
}