import Svg from "../../../Svg";
import { Typography } from "@material-ui/core";
import IntlMessages from "../../../../Util/IntlMessages";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {  IChannelVariablesState } from "../../../../Store/channel/channelTypes";
import { EditImage } from "../../Tabs/Styling";

interface PreviewProps {
	variables: IChannelVariablesState
	images: EditImage
}

const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
		margin: '0 auto',
		maxWidth: '400px',
		height: 'auto',
		border: '1px solid grey',
		overflow: 'hidden'
	},
	header: {
		height: '50px',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundSize: 'cover'
	},
	footer: {
		height: '100px'
	},
	headerContent: {
		width: '90%',
		height: '50px',
		display: 'flex',
		margin: '0 auto',
		alignItems: 'center'
	},
	footerContent: {
		width: '80%',
		height: '30px',
		display: 'flex',
		margin: '0 auto'
	},
	poweredBy: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '10px'
	},
	footerLogo: {
		display: 'flex',
		justifyContent: 'flex-start',
		padding: '0 0 0 5px',
		'& svg': {
			minWidth: '40px',
			maxWidth: '70px',
			minHeight: '15px',
			maxHeight: '35px',
		}
	},
	headerLogo: {
		display: 'flex',
		justifyContent: 'flex-start',
		'& svg': {
			minWidth: '50px',
			maxWidth: '100px',
			minHeight: '20px',
			maxHeight: '50px',
		}
	},
	stepCard: {
		width: '90%',
		boxShadow: '0 0 0.5rem 0 rgba(0, 0, 0, 0.05)',
		borderRadius: '10px',
		marginBottom: '20px',
		position: 'relative',
		top: '10px',
		margin: '0 auto',
		padding: '10px'
	},
	inputContainer: {
		paddingTop: '5px'
	},
	input: {
		height: '20px',
		borderRadius: '3px',
		width: '120px'
	},
	button: {
		width: '65px',
		borderRadius: '50px',
		margin: '10px 0 0 auto',
		padding: '2px',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	}
}));

const TabletPreview: React.FC<PreviewProps> = ({ variables, images }) => {

	const classes = useStyles();

	return (
		<div className={`${classes.container}`}>
			<div className={`${classes.header}`} style={{
				...images.header ?
					{ backgroundImage: `${variables["header:navigation::overlay"]},url(${images.header.display || ''})` } :
					{ background: variables["header:navigation::overlay"] }
			}}>
				<div className={classes.headerContent}>
					<Svg url={images.desktopLogo.display} className={classes.headerLogo} style={{ fill: variables['header:navigation:logo::fill'] }} />
				</div>
			</div>
			<div className={`${classes.stepCard} d-flex flex-column justify-content-between`} style={{ background: variables['flow:step::background'] }}>
				<div className="column w-100">
					<Typography style={{ fontSize: '14px' }}><IntlMessages id={"channel:styling:active_colour"} /></Typography>
					<div className={classes.inputContainer}>
						<Typography style={{ fontSize: '10px', color: variables['input:label::color'] }}><IntlMessages id={"channel:styling:input_example"} /></Typography>
						<div className={classes.input} style={{ border: variables['input::border'], background: variables["input:background"] }}>
						</div>
						<Typography style={{fontSize: "8px", color: variables['flow:error::color']}}><IntlMessages id={"channel:styling:error_example"} /></Typography>
					</div>
				</div>
				<div className={`${classes.button} column`} style={{ background: variables['flow:step:submit:button::background'] }}>
					<Typography style={{ fontSize: '10px', color: variables['flow:step:submit:button::color'], background: variables['flow:step:submit:button::background'] }}><IntlMessages id={"channel:styling:light_text"} /></Typography>
				</div>
			</div>
			<div className={`${classes.stepCard} d-flex flex-column justify-content-between`} style={{ background: variables['flow:step::background'] }}>
				<div className="column w-100">
					<Typography style={{ opacity: 0.5, fontSize: '14px' }}><IntlMessages id={"channel:styling:inactive_colour"} /></Typography>
				</div>
				<div className={`${classes.button} column`} style={{ background: variables['flow:step:submit:button::background'], opacity: 0.5 }}>
					<Typography style={{ fontSize: '10px', color: variables['flow:step:submit:button::color'] }}><IntlMessages id={"channel:styling:light_text"} /></Typography>
				</div>
			</div>
			<div className={`${classes.footer}`} style={{ background: variables["footer::background"], color: variables['footer:text::color'] }}>
				<div className={classes.footerContent}>
					<div className={classes.poweredBy}>
						Powered by
						<Svg url={images.footerLogo.display} className={classes.footerLogo} style={{ fill: variables["footer:logo::color"] }} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default TabletPreview;