import {useCallback, useEffect, useRef} from 'react'
import {Column, HeaderGroup, useSortBy, useTable} from "react-table";
import {makeStyles} from "@material-ui/core/styles";
import {Commission, Contact, Location} from "../../Store/bookings/bookingsTypes";

const useStyles = makeStyles((theme) => ({
    bookingList: {
        maxHeight: "calc(100vh - 440px)",
        overflow: "auto",
    },
    table: {
        padding: theme.spacing(1),
        transition: theme.transitions.create("width"),
        width: "100%",
    },
    item: () => ({
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        "&:nth-child(even)": {
            background: "rgba(244, 247, 250, .45)"
        },
        cursor: 'pointer'
    }),
    row: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
    },
    button: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        height: '3em'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        padding: theme.spacing(1)
    },
    sticky: {
        position: 'sticky',
        top: 0,
        backgroundColor: "#F4F7FA"
    },
}));

export interface PassengersTableData {
    col1: string;
    col2: string;
    col3: string;
}

export interface DetailsTableData {
    col1: number;
    col2: string;
    col3: string;
    col4: Location;
    col5: Location;
}

export interface BookingTableData {
    col1: string;
    col2: Contact;
    col3: number;
    col4: string;
    col5: string;
    col6: string;
    col7: string;
    col8: Commission;
    col9?: Commission;
    col10: string;
    col11: string;
}

export interface CommissionTableData {
    col1: string;
    col2: string;
    col3: string;
    col4: string;
}

export interface ImageTableData {
    col1: string;
    col2: Image;
}

export interface Image {
    src: string;
    alt: string;
    key: string;
}

export type Data = BookingTableData & DetailsTableData & PassengersTableData & CommissionTableData & ImageTableData;

interface TableProps {
    columns: Column<Data>[];
    data: Data[];
    hasMore?: boolean;
		hiddenColumns?: string[];
    update?: () => void;
    open?: (id: string) => void;
}

function Table({columns, data, update, open, hasMore, hiddenColumns}: TableProps): JSX.Element {

	const initialState = hiddenColumns ? { hiddenColumns } : undefined;

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        autoResetSortBy: false,
				initialState
    }, useSortBy);

    const styles = useStyles();

    const scrollContainerRef = useRef<HTMLTableElement | null>(null);
    const isUpdating = useRef<boolean>(false);

    const checkScrollEnd = useCallback(() => {
        if (scrollContainerRef.current && hasMore && !isUpdating.current) {
            const {scrollTop, clientHeight, scrollHeight} = scrollContainerRef.current;
            const isNearTableEnd = scrollTop + clientHeight >= scrollHeight * 0.9; // Percentage of the table scrolled
            const isContainerNotFull = scrollHeight <= clientHeight;

            if (isNearTableEnd || isContainerNotFull) {
                isUpdating.current = true;
                update && update();
            }
        }
    }, [update, hasMore]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const onScroll = () => {
                checkScrollEnd();
            };

            scrollContainer.addEventListener('scroll', onScroll);

            return () => scrollContainer.removeEventListener('scroll', onScroll);
        }
    }, [checkScrollEnd]);

    useEffect(() => {
        isUpdating.current = false;
        checkScrollEnd();
    }, [data]);

    return (
        <div ref={scrollContainerRef} className={styles.bookingList} id="booking-list">
            <table className={styles.table} {...getTableProps()}>
                <thead>
                {headerGroups.map((headerGroup: HeaderGroup<Data>) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: HeaderGroup<Data>) => {
                            return (
                                <th className={`${styles.row} ${styles.sticky}`} {...column.getHeaderProps(column.getSortByToggleProps())}>
                                    {column.render("Header")}
                                    <span>
											{column.isSorted ? column.isSortedDesc ? " 🔽" : " 🔼" : ""}
                                    </span>
                                </th>)
                        })}
                    </tr>
                ))}
                </thead>
                <tbody  {...getTableBodyProps()}>
                {rows.map((row, rowIndex) => {
                    prepareRow(row);
                    return (
                        <tr id={`row-${rowIndex}`} onClick={() => open && open(row.values.col1)}
                            className={styles.item} {...row.getRowProps()}>
                            {row.cells.map((cell, cellIndex) => {
                                return (
                                    <td id={`row-${rowIndex}-cell-${cellIndex}`}
                                        className={styles.row} {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
