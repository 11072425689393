import React, {useEffect, useMemo, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import IntlMessages from "../../../Util/IntlMessages";
import {Service} from "../../../Store/channel/channelTypes";

interface OffsetProps {
    service: Service,
    offsets: {minutes: number},
    disabled: boolean,
    onChange: (offsets: {minutes: number}) => void;
}

const useStyles = makeStyles(() => ({
    inputField: {
        border: '1px solid #ccc',
        padding: '0 6px',
        margin: '0 3px 0 6px',
        color: '#5D92F4'
    }
}));

const Offset = ({service, offsets, disabled, onChange}: OffsetProps): JSX.Element => {
    /**
     * Setting when the driver needs to arrive at the airport for pickup or delivery.
     */

    const classes = useStyles();
    const [data, setData] = useState(offsets);
    const pickupWidth = useMemo(() => Math.abs(offsets.minutes).toString().length + 4, [offsets.minutes]);

    const changePickupHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!value) return;
        const parsedNumber = service === Service.AIRPORT ? -Math.abs(parseInt(value, 10)) : parseInt(value, 10);
        setData(() => ({minutes: parsedNumber}))
    };

    useEffect(() => {
        /**
         * Passing the updated offsets to parent component if modifications happened
         */
        if (JSON.stringify(data) !== JSON.stringify(offsets)) {
            onChange(data);
        }
    }, [data])

    return (
        <p><IntlMessages id={"channel:logistics:offset:driver"}/>
            <input disabled={disabled} min="0" className={classes.inputField} style={{ width: pickupWidth +'ch'}} defaultValue={Math.abs(data.minutes)} type="number" autoFocus onChange={changePickupHandler}/>
            <IntlMessages id={"channel:logistics:offset:minutes"}/><IntlMessages id={`channel:logistics:offset:${service}`}/>
        </p>
    )
}

export default Offset