import React from 'react';
import {makeStyles, MenuItem, TextField} from "@material-ui/core";
import theme from "../../Container/themes/primaryTheme";

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 150,
        width: '100%'
    },
    textField: {
        textAlign: theme.direction === 'ltr' ? 'left' : 'right',
        marginTop: theme.spacing(1)
    },
    menu: {
        minWidth: 150,
        width: '100%',
    },
});

interface ISelectComponentProps {
    selected: { id: number, name: string } | undefined;
    disabled?: boolean;
    items: Array<{ id: number, name: string }>
    handleChange: (event: any) => void
    className?: string;
}

const SelectComponent = ({selected, disabled = false, className, items, handleChange}: ISelectComponentProps) => {

    const classes = useStyles();

    return (
        <div className={classes.container}>
            <TextField
                disabled={disabled}
                id="outlined-select-gender"
                select
                label={""}
                className={`${classes.textField} ${className}`}
                value={selected?.id}
                onChange={handleChange}
                SelectProps={{
                    MenuProps: {
                        className: classes.menu,
                    },
                }}
                margin="normal"
                variant="outlined"
            >
                {items.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </div>
    )
};

export default SelectComponent;