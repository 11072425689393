import {addLocaleData} from 'react-intl';
import enLang from './entries/en-US';

interface AppLocale {
    [key: string]: {
        messages: {[key:string]: string};
        locale: string;
        data: Array<{
            locale: string;
            parentLocale?: string;
            fields?: {
                [key: string]: {
                    displayName: string;
                    relative: {0: string; 1?: string; '-1'?: string;}
                    relativeTime: {
                        future: { one: string, other: string }
                        past: { one: string, other: string }
                    }
                }
            };
            pluralRuleFunction?: () => void
        }>
    }
}

const AppLocale: AppLocale = {en: enLang};

addLocaleData(AppLocale.en.data);

export default AppLocale;
