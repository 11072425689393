import {
    LOGIN_USER,
    LOGOUT_USER,
    UPDATE_USER,
    IUser
} from "./authTypes";
import Authentication from "../../Auth/Auth";
import Fetcher, { requestConfig } from "../../Util/Fetcher";
import {toast} from "react-toastify";
import { History } from "history";
import {Dispatch} from "redux";

export const loginUser = (email: string, password: string, history: History) => (dispatch: Dispatch): Promise<void> => {
    return Authentication.auth(email, password)
        .then(async () => {
            const authUser = await getUser(email);
            dispatch({type: LOGIN_USER, payload: authUser});
            history.push('/channel')
        })
        .catch((error) => {
            toast.error(error.body.error_description || error.body.error);
        });
}

export const requestPasswordReset = (email: string, history: History) => async (): Promise<void> => {
    return Fetcher({path: `auth/users/${email}/password/`, params: {origin: window.location.origin}, headers: await requestConfig('passwordRecover')})
        .then(async () => {
            toast.success("If you have an account with us, and it is verified, you'll get an email to change your password. If you did not get an email after 10 minutes, please contact us on info@bagpoint.com.");
            history.push('/login')
        })
        .catch((error) => {
            toast.error(error?.body?.error_description || error?.body?.error);
        });
}

export const resetPassword = (password: string, token: string, email: string, history: History) => (): Promise<void> => {
    return Fetcher({path: `auth/users/${email}/change_password/`, method: 'PUT', body: {password}, headers: {headers: {Authorization: `Bearer ${token}`}}
})
        .then(async () => {
            toast.success('Success!');
            history.push('/login')
        })
        .catch((error) => {
            toast.error(error?.body?.error_description || error?.body?.error);
        });
}

export const getUser = (email: string): Promise<IUser> => {
    return Fetcher({path: `auth/users/`, params: {email}})
        .then((response) =>  response && response?.data && response.data.results && response.data.results[0])
        .catch((error) => new Error(error));
};

export const updateUser = (user: IUser) => (dispatch: Dispatch): Promise<IUser> => {
    return Fetcher({path: `auth/users/${user.id}/`, method: 'PUT', body: user})
        .then((response) => {
            dispatch({type: UPDATE_USER, payload: response.data});
            toast.success('Profile Updated Successfully!');
            return response.data
        })
        .catch((error) => {
            toast.error(error.message);
            new Error(error)
        });
};

export const logoutUser = (history: History) => (dispatch: Dispatch): void => {
    Authentication.logout()
        .then(() => {
            dispatch({type: LOGOUT_USER});
            toast.success('User Logout Successfully');
        })
        .catch((error) => {
            toast.error(error.message);
        })
    history.push("/login");
}