import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppBar, Button, Toolbar} from '@material-ui/core';
import {Link, useHistory} from 'react-router-dom';
import {Form, FormGroup, Input} from 'reactstrap';
import AppConfig from 'Constants/AppConfig';
import {requestPasswordReset} from "../Store/auth/AuthActions";
import IntlMessages from "../Util/IntlMessages";
import {useValidation} from "../hooks";
import {toast} from "react-toastify";

function PasswordReset() {

    const [email, setEmail] = useState<string>('');
    const history = useHistory();
    const dispatch = useDispatch();
    const {validateEmail} = useValidation()

    const onUserLogin = () => {
        const emailError = validateEmail(email) || ''
        if (emailError) {
            emailError && toast.error(<IntlMessages id={emailError}/>)
            return;
        }

        dispatch(requestPasswordReset(email, history));
    }

    return (
        <div className="rct-session-wrapper">
            <AppBar position="static" className="session-header">
                <Toolbar>
                    <div className="container">
                        <div className="d-flex justify-content-between">
                            <div className="session-logo">
                                <Link to="/">
                                    <img src={AppConfig.appLogo} alt="session-logo" className="img-fluid"
                                         width="110" height="35"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="session-inner-wrapper">
                <div className="container">
                    <div className="row row-eq-height">
                        <div className="col-sm-7 col-md-6 col-lg-6 mx-auto">
                            <div className="session-body text-center">
                                <div className="session-head mb-30">
                                    <h2 className="font-weight-bold"><IntlMessages id={"password_reset:title"}/></h2>
                                </div>
                                <Form name="login-form">
                                    <FormGroup className="has-wrapper">
                                        <Input
                                            type="email"
                                            value={email}
                                            name="email"
                                            id="email"
                                            className="has-input input-lg"
                                            placeholder="Enter Email Address"
                                            onChange={(event) => setEmail(event.target.value)}
                                        />
                                        <a className="d-flex justify-content-end" href={'/login'}><IntlMessages
                                            id={"password_reset:back_to_login"}/></a>
                                        <span className="has-icon"><i className="ti-email"></i></span>
                                    </FormGroup>
                                    <FormGroup className="mb-15">
                                        <Button
                                            color="primary"
                                            className="btn-block text-white w-100"
                                            variant="contained"
                                            size="large"
                                            onClick={onUserLogin}
                                        >
                                            <IntlMessages id={"password_reset:button"}/>
                                        </Button>
                                    </FormGroup>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PasswordReset;
