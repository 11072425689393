import {createReducer} from "@reduxjs/toolkit";
import {COLLAPSED_SIDEBAR, IAppState, SET_LOADING} from './appTypes';
import AppConfig from 'Constants/AppConfig';
import {PURGE} from "redux-persist";
import sidebarMenu from "../../Components/Sidebar/NavLinks";

const initialState: IAppState = {
    navCollapsed: AppConfig.navCollapsed,
    rtlLayout: AppConfig.rtlLayout,
    activeTheme: {
        id: 1,
        name: 'primary'
    },
    locale: AppConfig.locale,
    languages: [
        {
            languageId: 'english',
            locale: 'en',
            name: 'English',
            icon: 'en',
        }
    ],
    sidebarMenus: sidebarMenu,
    loading: false
};

const settings = createReducer(initialState, {
    [COLLAPSED_SIDEBAR]: (state, action) => {
        return {
            ...state,
            navCollapsed: action.payload
        };
    },
    [SET_LOADING]: (state, action) => {
        return {
            ...state,
            loading: action.payload
        }
    },
    [PURGE]: () => initialState
})

export default settings;