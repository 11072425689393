import React from 'react';
import { useSelector } from 'react-redux';
import { List, ListSubheader } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import NavMenuItem from './NavMenuItem';
import RootState from "../../Store/RootState";
import { IAppState, IMenuItem } from "../../Store/app/appTypes";
import { ChannelData } from "../../Store/channel/channelTypes";

function SidebarContent() {
	const { sidebarMenus } = useSelector<RootState, IAppState>(state => state.app);
	const channel = useSelector<RootState, ChannelData | undefined>(state => state.channel.selected);

	return (
		<div className="rct-sidebar-nav">
			<nav className="navigation">
				<List
					className="rct-mainMenu p-0 m-0 list-unstyled"
					subheader={
						<ListSubheader className="side-title" component="li">
							{channel && channel.name}
						</ListSubheader>}
				>
					{/* Disable finances tab temporarily */}
					{sidebarMenus.length && sidebarMenus.filter((menu: IMenuItem) => menu.menu_title !== 'sidebar.finances').map((menu: IMenuItem, key: number) => (
						<NavMenuItem menu_title={menu.menu_title} menu_icon={menu.menu_icon} path={menu.path} key={key} />
					))}
				</List>
			</nav>
		</div>
	);
}

export default withRouter(SidebarContent);
