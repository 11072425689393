import {IChannelVariablesState} from 'Store/channel/channelTypes';
import InfoCircle from './InfoCircle.svg'
import WarningCircle from './WarningCircle.svg'
import {makeStyles} from '@material-ui/core/styles';
import Svg from 'Components/Svg';

interface Props {
    variables: IChannelVariablesState;
    type: string;
}

const useStyles = makeStyles(() => ({
    info: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignContent: "center",
        justifyContent: "flex-start",
        boxShadow: "0 0 0.5rem 0 rgba(0, 0, 0, 0.05)",
        maxWidth: "350px",
        marginRight: "0 10px",
        maxHeight: "200px",
        width: "50%",
        height: "100%",
        padding: "20px",
        borderRadius: "20px",
        fontSize: "15px",
        fontWeight: 300

    },
    header: {
        display: "flex",
        position: "relative",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
    },
    h2: {
        display: "inline-block",
        height: "fit-content",
        padding: "0",
        margin: "0",
        fontSize: "18px",
        fontWeight: 300,
    },
    icon: {
        display: "flex",
        flexDirection: "row",
        width: "28px",
        svg: {
            height: "auto",
        }
    },
}));

const InfoCard: React.FC<Props> = ({variables, type}) => {
    const styles = useStyles()
    const isError = type === "error"

    const title = isError ? "Error" : "How will we get your luggage?"
    const content = isError ? "Unfortunately, due to logistical limitations, we do not have any pickup slots available for this address." : "Our Driver will contact you shortly after you've landed to meet with him for the Baggage collection."

    return (
        <section style={{
            color: isError ? variables['info:card:error::color'] : variables['info:card::color'],
            background: isError ? variables['info:card:error::background'] : variables['info:card::background']
        }} className={`${styles.info}`}>
            <header className={styles.header}>
                {type === "info" ?
                    <Svg style={{fill: variables["info:card:icon::fill"]}} className={styles.icon} url={InfoCircle}/> :
                    <Svg style={{fill: variables["info:card:error:icon::fill"]}} className={styles.icon}
                         url={WarningCircle}/>}
                <h2 className={styles.h2}
                    style={{color: isError ? variables['info:card:error:heading::color'] : variables['info:card:heading::color']}}>{title}</h2>
            </header>
            {content}

        </section>
    )
}

export default InfoCard